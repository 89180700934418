<h1 mat-dialog-title>Edit</h1>
<div mat-dialog-content>
  <h3>{{name == 'Image0' ? 'Value' : name}}</h3>
  <p>{{data.value}}</p>
  <ng-container *ngIf="!(name == 'Status' || name == 'Corrosion' || name == 'Completed' || name == 'StructureType' || name == 'CanradVariation' || name == 'Image' || name=='Image0' || name == 'role' || eqLeg == true)">
    <mat-form-field>
      <mat-label>Update Value</mat-label>
      <input matInput [(ngModel)]="value">
    </mat-form-field>
  </ng-container>
  <mat-form-field *ngIf="name == 'Value' && eqLeg == true">
    <mat-label>Select a Leg</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'Leg 1'">Leg 1</mat-option>
      <mat-option [value]="'Leg 2'">Leg 2</mat-option>
      <mat-option [value]="'Leg 3'">Leg 3</mat-option>
      <mat-option [value]="'Leg 4'">Leg 4</mat-option>
      <mat-option [value]="'Leg 5'">Leg 5</mat-option>
      <mat-option [value]="'Leg 6'">Leg 6</mat-option>
      <mat-option [value]="'Face A'">Face A</mat-option>
      <mat-option [value]="'Face B'">Face B</mat-option>
      <mat-option [value]="'Face C'">Face C</mat-option>
      <mat-option [value]="'Face D'">Face D</mat-option>
      <mat-option [value]="'Face E'">Face E</mat-option>
      <mat-option [value]="'Face F'">Face F</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="name == 'Value'">
    <mat-label>Select a Status</mat-label>
    <mat-select [(ngModel)]="option" required>
      <mat-option [value]="4">Emergency</mat-option>
      <mat-option [value]="3">Urgent</mat-option>
      <mat-option [value]="2">Planned</mat-option>
      <mat-option [value]="1">Monitor</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="name == 'Image' && eqLeg == false">
    <mat-form-field appearance="fill">
      <mat-label>Number of Images</mat-label>
      <mat-select [(ngModel)]="imageCount" required>
        <mat-option [value]="0">0</mat-option>
        <mat-option [value]="1">1</mat-option>
        <mat-option [value]="2">2</mat-option>
        <mat-option [value]="3">3</mat-option>
        <mat-option [value]="4">4</mat-option>
        <mat-option [value]="5">5</mat-option>
        <mat-option [value]="6">6</mat-option>
        <mat-option [value]="7">7</mat-option>
        <mat-option [value]="8">8</mat-option>
        <mat-option [value]="9">9</mat-option>
        <mat-option [value]="10">10</mat-option>
      </mat-select>
    </mat-form-field>
<mat-form-field *ngFor="let item of numSequence(imageCount);let i = index">
  <mat-label>Image {{i+1}}</mat-label>
  <input matInput [(ngModel)]="valueArray[i]">
  </mat-form-field>
  </ng-container>
  <ng-container *ngIf="name == 'Image0' && eqLeg == false">
    <mat-form-field appearance="fill">
      <mat-label>Update Value</mat-label>
      <input matInput [(ngModel)]="valueArray[0]">
    </mat-form-field>
  </ng-container>
  <mat-form-field appearance="fill" *ngIf="name == 'Status'">
    <mat-label>Select a Status</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'Uninspected'">Uninspected</mat-option>
      <mat-option [value]="'Inspected'">Inspected</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="name == 'Completed'">
    <mat-label>Select a Option</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'Yes'">Yes</mat-option>
      <mat-option [value]="'No'">No</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="name == 'Corrosion'">
    <mat-label>Select a Option</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'N/A'">N/A</mat-option>
      <mat-option [value]="'Heavy'">Heavy</mat-option>
      <mat-option [value]="'Medium'">Medium</mat-option>
      <mat-option [value]="'Light'">Light</mat-option>
      <mat-option [value]="'Surface'">Surface</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="name == 'StructureType'">
    <mat-label>Select a Structure Type</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'N/A'">N/A</mat-option>
      <mat-option [value]="'Pole'">Pole</mat-option>
      <mat-option [value]="'Mast'">Mast</mat-option>
      <mat-option [value]="'Tower'">Tower</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="name == 'CanradVariation'">
    <mat-label>Select a Option</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'N/A'">N/A</mat-option>
      <mat-option [value]="'Yes'">Yes</mat-option>
      <mat-option [value]="'No'">No</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="name == 'role'">
    <mat-label>Select a Role</mat-label>
    <mat-select [(ngModel)]="value" required>
      <mat-option [value]="'user'">User</mat-option>
      <mat-option [value]="'telstra-processing'">Telstra Processing</mat-option>
      <mat-option [value]="'aero-Processing'">Aero Processing</mat-option>
      <mat-option [value]="'pilot'">Pilot</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="editFn()" cdkFocusInitial class="submit">Ok</button>
</div>
