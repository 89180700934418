<mat-chip-list aria-label="Issues selection">
    <mat-chip class="chipOne" value="All" (click)="select($event)">All</mat-chip>
    <mat-chip class="chipTwo" value="Monitor" (click)="select($event)">Monitor</mat-chip>
    <mat-chip class="chipThree" value="Planned" (click)="select($event)">Planned</mat-chip>
    <mat-chip class="chipFour" value="Urgent" (click)="select($event)">Urgent</mat-chip>
    <mat-chip class="chipFive" value="Emergency" (click)="select($event)">Emergency</mat-chip>
    <ng-container *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">
      <mat-chip class="addIcon" (click)="addSection()"><mat-icon>add</mat-icon></mat-chip>
      <mat-chip class="deleteIcon" (click)="deleteSection()"><mat-icon>delete</mat-icon></mat-chip>
    </ng-container>
</mat-chip-list>
<div *ngIf="data" class="content">
  <div *ngIf="selected === 'All'">
    <div *ngIf="data.General" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf=" i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('Equipment')">
        <p>No</p>
        <p>Equipment</p>
        <p>Details</p>
      </div>
      <div *ngIf="data.Equipment.filter(leg1).length > 0" class="contentSubHeading">Leg 1</div>
      <div *ngFor="let items of data.Equipment.filter(leg1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg2).length > 0" class="contentSubHeading" >Leg 2</div>
      <div *ngFor="let items of data.Equipment.filter(leg2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg3).length > 0" class="contentSubHeading">Leg 3</div>
      <div *ngFor="let items of data.Equipment.filter(leg3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg4).length > 0" class="contentSubHeading">Leg 4</div>
      <div *ngFor="let items of data.Equipment.filter(leg4);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg5).length > 0" class="contentSubHeading">Leg 5</div>
      <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg6).length > 0" class="contentSubHeading">Leg 6</div>
      <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceA).length > 0" class="contentSubHeading">Face A</div>
      <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceB).length > 0" class="contentSubHeading" >Face B</div>
      <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceC).length > 0" class="contentSubHeading">Face C</div>
      <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceD).length > 0" class="contentSubHeading">Face D</div>
      <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceE).length > 0" class="contentSubHeading">Face E</div>
      <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceF).length > 0" class="contentSubHeading">Face F</div>
      <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceAB).length > 0" class="contentSubHeading">Face AB</div>
      <div *ngFor="let items of data.Equipment.filter(faceAB);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceBC).length > 0" class="contentSubHeading">Face BC</div>
      <div *ngFor="let items of data.Equipment.filter(faceBC);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceCD).length > 0" class="contentSubHeading">Face CD</div>
      <div *ngFor="let items of data.Equipment.filter(faceCD);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceCA).length > 0" class="contentSubHeading">Face CA</div>
      <div *ngFor="let items of data.Equipment.filter(faceCA);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceDA).length > 0" class="contentSubHeading">Face DA</div>
      <div *ngFor="let items of data.Equipment.filter(faceDA);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.TowerFoundationCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerFoundationCheck')">
        <p>No</p>
        <p>Tower Foundation Check</p>
        <p>Details</p>
      </div>
      <!-- <div>{{data.TowerFoundationCheck | json}}</div> -->
      <div *ngIf="data.TowerFoundationCheck.filter(tower).length > 0" class="contentSubHeading">Tower</div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(towerSizeandTowerFoundationBaseLevel).length > 0">Size and Tower Foundation Base Level</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(towerSizeandTowerFoundationBaseLevel);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck', 'pole_'+items.Pole)"></span></p>
        </div>
      </div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(towerTowerDistance).length > 0">Tower Distance</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(towerTowerDistance);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerFoundationCheck.filter(pole1).length > 0" class="contentSubHeading">Pole 1</div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel1).length > 0">Size and Tower Foundation Base Level</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck', 'pole_'+items.Pole)"></span></p>
        </div>
      </div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleTowerDistance1).length > 0">Tower Distance</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerFoundationCheck.filter(pole2).length > 0" class="contentSubHeading">Pole 2</div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel2).length > 0">Size and Tower Foundation Base Level</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck', 'pole_'+items.Pole)"></span></p>
        </div>
      </div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleTowerDistance2).length > 0">Tower Distance</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerFoundationCheck.filter(pole3).length > 0" class="contentSubHeading">Pole 3</div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel3).length > 0">Size and Tower Foundation Base Level</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck', 'pole_'+items.Pole)"></span></p>
        </div>
      </div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleTowerDistance3).length > 0">Tower Distance</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerFoundationCheck.filter(pole4).length > 0" class="contentSubHeading">Pole 4</div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel4).length > 0">Size and Tower Foundation Base Level</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel4);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck', 'pole_'+items.Pole)"></span></p>
        </div>
      </div>
      <div class="contentSubHeading" *ngIf="data.TowerFoundationCheck.filter(poleTowerDistance4).length > 0">Tower Distance</div>
      <div *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance4);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerVerticalityCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerVerticalityCheck')">
        <p>No</p>
        <p>Tower Verticality Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="data.TowerVerticalityCheck.filter(structure1).length > 0" class="contentSubHeading">Self Supporting Tower</div>
      <div *ngFor="let items of data.TowerVerticalityCheck.filter(structure1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck', 'structure_'+items.Structure)"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerVerticalityCheck.filter(structure2).length > 0" class="contentSubHeading">Guyed</div>
      <div *ngFor="let items of data.TowerVerticalityCheck.filter(structure2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck', 'structure_'+items.Structure)"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerVerticalityCheck.filter(structure3).length > 0" class="contentSubHeading">Steel Pipe</div>
      <div *ngFor="let items of data.TowerVerticalityCheck.filter(structure3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck', 'structure_'+items.Structure)"></span></p>
        </div>
      </div>

      <div *ngIf="data.TowerVerticalityCheck.filter(structure4).length > 0" class="contentSubHeading">Monopole</div>
      <div *ngFor="let items of data.TowerVerticalityCheck.filter(structure4);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck', 'structure_'+items.Structure)"></span></p>
        </div>
      </div>

    </div>
    <div *ngIf="data.TensionCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TensionCheck')">
        <p>No</p>
        <p>Tension Check</p>
        <p>Details</p>
      </div>
      <div class="contentSubHeading">Tension/Guy Wire 3/8"</div>

      <div *ngIf="data.TensionCheck.filter(level1).length > 0" class="contentSubHeading">Level 1</div>
      <!-- <div>{{data.TensionCheck.filter(level1) | json}}</div> -->

      <div *ngFor="let items of data.TensionCheck.filter(level1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name, 'TensionCheck', 'level_'+items.Level)"></span></p>
        </div>
      </div>
      <div *ngIf="data.TensionCheck.filter(level2).length > 0" class="contentSubHeading">Level 2</div>
      <div *ngFor="let items of data.TensionCheck.filter(level2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name, 'TensionCheck', 'level_'+items.Level)"></span></p>
        </div>
      </div>
      <div *ngIf="data.TensionCheck.filter(level3).length > 0" class="contentSubHeading">Level 3</div>
      <div *ngFor="let items of data.TensionCheck.filter(level3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name, 'TensionCheck', 'level_'+items.Level)"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.LoadingonTower.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('LoadingonTower')">
        <p>No</p>
        <p>Loading On Tower</p>
        <p>Details</p>
      </div>
      <!-- <div>{{data.LoadingonTower | json}}</div> -->
      <div *ngIf="data.LoadingonTower.filter(antennaL).length > 0" class="contentSubHeading">Antenna</div>
      <div *ngFor="let items of data.LoadingonTower.filter(antennaL);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower', 'equipmentLoading_'+items.EquipmentLoading)"></span></p>
        </div>
      </div>
      <div *ngIf="data.LoadingonTower.filter(rruL).length > 0" class="contentSubHeading">RRU</div>
      <div *ngFor="let items of data.LoadingonTower.filter(rruL);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower', 'equipmentLoading_'+items.EquipmentLoading)"></span></p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Monitor'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf="items.Details.filter(monitor).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>
        <div *ngIf="hasLeg(leg1,monitor)" class="contentSubHeading">Leg 1</div>
        <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
          <div *ngIf="items.Details.filter(monitor).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
            <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
              <p>{{i+1}}</p>
              <p>{{ items.Name }}</p>
              <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
              <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
              <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
              <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
              <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
            </div>
          </div>
        </div>
        <div *ngIf="hasLeg(leg2,monitor)" class="contentSubHeading">Leg 2</div>
        <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg3,monitor)" class="contentSubHeading">Leg 3</div>
      <div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
      <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg4,monitor)" class="contentSubHeading">Leg 4</div>
      <div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
      <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg5,monitor)" class="contentSubHeading">Leg 5</div>
      <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasLeg(leg6,monitor)" class="contentSubHeading">Leg 6</div>
      <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceA,monitor)" class="contentSubHeading">Face A</div>
      <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceB,monitor)" class="contentSubHeading">Face B</div>
      <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceC,monitor)" class="contentSubHeading">Face C</div>
      <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceD,monitor)" class="contentSubHeading">Face D</div>
      <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceE,monitor)" class="contentSubHeading">Face E</div>
      <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceF,monitor)" class="contentSubHeading">Face F</div>
      <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerFoundationCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerFoundationCheck')">
        <p>No</p>
        <p>Tower Foundation Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasPole(tower,monitor)" class="contentSubHeading">Tower</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(tower,monitor)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerSizeandTowerFoundationBaseLevel);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(tower,monitor)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerTowerDistance);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole1,monitor)" class="contentSubHeading">Pole 1</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole1,monitor)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel1);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole1,monitor)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance1);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole2,monitor)" class="contentSubHeading">Pole 2</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole2,monitor)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole2,monitor)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole3,monitor)" class="contentSubHeading">Pole 3</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole3,monitor)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel3);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole3,monitor)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance3);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole4,monitor)" class="contentSubHeading">Pole 4</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole4,monitor)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel4);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole4,monitor)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance4);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerVerticalityCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerVerticalityCheck')">
        <p>No</p>
        <p>Tower Verticality Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasStructure(structure1,monitor)" class="contentSubHeading">Self Supporting Tower</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure1);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure2,monitor)" class="contentSubHeading">Guyed</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure3,monitor)" class="contentSubHeading">Steel Pole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure3);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure4,monitor)" class="contentSubHeading">Monopole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure4);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TensionCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TensionCheck')">
        <p>No</p>
        <p>Tension Check</p>
        <p>Details</p>
      </div>

      <div class="contentSubHeading">Tension/Guy Wire 3/8"</div>

      <div *ngIf="hasLevel(level1,monitor)" class="contentSubHeading">Level 1</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level1);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level2,monitor)" class="contentSubHeading">Level 2</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level3,monitor)" class="contentSubHeading">Level 3</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level3);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.LoadingonTower.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('LoadingonTower')">
        <p>No</p>
        <p>Loading on Tower</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasEquipment(antennaL,monitor)" class="contentSubHeading">Antenna</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(antennaL);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasEquipment(rruL,monitor)" class="contentSubHeading">RRU</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(rruL);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Planned'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf=" items.Details.filter(planned).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading"(click)="getDetails('Equipment')">
          <p>No</p>
        <p>Equipment</p>
        <p>Details</p>
      </div>
      <div *ngIf="hasLeg(leg1,planned)" class="contentSubHeading">Leg 1</div>
      <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>


      <div *ngIf="hasLeg(leg2,planned)" class="contentSubHeading">Leg 2</div>
      <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
</div>

<div *ngIf="hasLeg(leg3,planned)" class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
</div>


<div *ngIf="hasLeg(leg4,planned)" class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg5,planned)" class="contentSubHeading">Leg 5</div>
      <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasLeg(leg6,planned)" class="contentSubHeading">Leg 6</div>
      <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceA,planned)" class="contentSubHeading">Face A</div>
      <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceB,planned)" class="contentSubHeading">Face B</div>
      <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceC,planned)" class="contentSubHeading">Face C</div>
      <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceD,planned)" class="contentSubHeading">Face D</div>
      <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceE,planned)" class="contentSubHeading">Face E</div>
      <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceF,planned)" class="contentSubHeading">Face F</div>
      <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerFoundationCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerFoundationCheck')">
        <p>No</p>
        <p>Tower Foundation Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasPole(tower,planned)" class="contentSubHeading">Tower</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(tower,planned)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerSizeandTowerFoundationBaseLevel);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(tower,planned)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerTowerDistance);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole1,planned)" class="contentSubHeading">Pole 1</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole1,planned)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel1);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole1,planned)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance1);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole2,planned)" class="contentSubHeading">Pole 2</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole2,planned)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel2);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole2,planned)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance2);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole3,planned)" class="contentSubHeading">Pole 3</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole3,planned)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel3);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole3,planned)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance3);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole4,planned)" class="contentSubHeading">Pole 4</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole4,planned)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel4);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole4,planned)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance4);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerVerticalityCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerVerticalityCheck')">
        <p>No</p>
        <p>Tower Verticality Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasStructure(structure1,planned)" class="contentSubHeading">Self Supporting Tower</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(pole1);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure2,planned)" class="contentSubHeading">Guyed</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure2);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure3,planned)" class="contentSubHeading">Steel Pole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure3);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure4,planned)" class="contentSubHeading">Monopole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure4);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TensionCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TensionCheck')">
        <p>No</p>
        <p>Tension Check</p>
        <p>Details</p>
      </div>

      <div class="contentSubHeading">Tension/Guy Wire 3/8"</div>

      <div *ngIf="hasLevel(level1,planned)" class="contentSubHeading">Level 1</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level1);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level2,planned)" class="contentSubHeading">Level 2</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level2);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level3,planned)" class="contentSubHeading">Level 3</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level3);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.LoadingonTower.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('LoadingonTower')">
        <p>No</p>
        <p>Loading on Tower</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasEquipment(antennaL,planned)" class="contentSubHeading">Antenna</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(antennaL);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasEquipment(rruL,planned)" class="contentSubHeading">RRU</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(rruL);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Urgent'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow"  *ngIf=" items.Details.filter(urgent).length > 0 " (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>

        <div *ngIf="hasLeg(leg1,urgent)" class="contentSubHeading">Leg 1</div>
      <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
      <div *ngIf="items.Details.filter(urgent).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>


      <div *ngIf="hasLeg(leg2,urgent)" class="contentSubHeading">Leg 2</div>
      <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
      <div *ngIf="items.Details.filter(urgent).length > 0"
      [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
      <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
        <p>{{i+1}}</p>
        <p>{{ items.Name }}</p>
        <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
        <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
        <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
        <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
        <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
      </div>
    </div>
    </div>


    <div *ngIf="hasLeg(leg3,urgent)" class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
    <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
    <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
      <p>{{i+1}}</p>
      <p>{{ items.Name }}</p>
      <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
      <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
      <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
      <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
      <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
    </div>
  </div>
  </div>


  <div *ngIf="hasLeg(leg4,urgent)" class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
  [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
</div>
</div>
<div *ngIf="hasLeg(leg5,urgent)" class="contentSubHeading">Leg 5</div>
<div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasLeg(leg6,urgent)" class="contentSubHeading">Leg 6</div>
<div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceA,urgent)" class="contentSubHeading">Face A</div>
<div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceB,urgent)" class="contentSubHeading">Face B</div>
<div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceC,urgent)" class="contentSubHeading">Face C</div>
<div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceD,urgent)" class="contentSubHeading">Face D</div>
<div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceE,urgent)" class="contentSubHeading">Face E</div>
<div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceF,urgent)" class="contentSubHeading">Face F</div>
<div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
    </div>
    <div *ngIf="data.TowerFoundationCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerFoundationCheck')">
        <p>No</p>
        <p>Tower Foundation Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasPole(tower,urgent)" class="contentSubHeading">Tower</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(tower,urgent)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerSizeandTowerFoundationBaseLevel);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(tower,urgent)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerTowerDistance);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole1,urgent)" class="contentSubHeading">Pole 1</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole1,urgent)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel1);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole1,urgent)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance1);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole2,urgent)" class="contentSubHeading">Pole 2</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole2,urgent)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel2);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole2,urgent)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance2);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole3,urgent)" class="contentSubHeading">Pole 3</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole3,urgent)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel3);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole3,urgent)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance3);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole4,urgent)" class="contentSubHeading">Pole 4</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole4,urgent)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel4);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole4,urgent)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance4);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerVerticalityCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerVerticalityCheck')">
        <p>No</p>
        <p>Tower Verticality Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasStructure(structure1,urgent)" class="contentSubHeading">Self Supporting Tower</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(pole1);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure2,urgent)" class="contentSubHeading">Guyed</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure2);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure3,urgent)" class="contentSubHeading">Steel Pole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure3);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure4,urgent)" class="contentSubHeading">Monopole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure4);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TensionCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TensionCheck')">
        <p>No</p>
        <p>Tension Check</p>
        <p>Details</p>
      </div>
      <div class="contentSubHeading">Tension/Guy Wire 3/8"</div>

      <div *ngIf="hasLevel(level1,urgent)" class="contentSubHeading">Level 1</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level1);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level2,urgent)" class="contentSubHeading">Level 2</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level2);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level3,urgent)" class="contentSubHeading">Level 3</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level3);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.LoadingonTower.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('LoadingonTower')">
        <p>No</p>
        <p>Loading on Tower</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasEquipment(antennaL,urgent)" class="contentSubHeading">Antenna</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(antennaL);let i = index;">
        <div *ngIf="items.Details.filter(urgent).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasEquipment(rruL,urgent)" class="contentSubHeading">RRU</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(rruL);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Emergency'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf=" items.Details.filter(emergency).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>


        <div *ngIf="hasLeg(leg1,emergency)" class="contentSubHeading">Leg 1</div>
<div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div *ngIf="hasLeg(leg2,emergency)" class="contentSubHeading">Leg 2</div>
<div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div *ngIf="hasLeg(leg3,emergency)" class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div *ngIf="hasLeg(leg4,emergency)" class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"  [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
        </div>
        <div *ngIf="hasLeg(leg5,emergency)" class="contentSubHeading">Leg 5</div>
        <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasLeg(leg6,emergency)" class="contentSubHeading">Leg 6</div>
        <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceA,emergency)" class="contentSubHeading">Face A</div>
        <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceB,emergency)" class="contentSubHeading">Face B</div>
        <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceC,emergency)" class="contentSubHeading">Face C</div>
        <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceD,emergency)" class="contentSubHeading">Face D</div>
        <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceE,emergency)" class="contentSubHeading">Face E</div>
        <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceF,emergency)" class="contentSubHeading">Face F</div>
        <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
            <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
              <p>{{i+1}}</p>
              <p>{{ items.Name }}</p>
              <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
              <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
              <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
              <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
              <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
            </div>
          </div>
        </div>
    </div>
    <div *ngIf="data.TowerFoundationCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerFoundationCheck')">
        <p>No</p>
        <p>Tower Foundation Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasPole(tower,emergency)" class="contentSubHeading">Tower</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(tower,emergency)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerSizeandTowerFoundationBaseLevel);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(tower,emergency)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(towerTowerDistance);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole1,emergency)" class="contentSubHeading">Pole 1</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole1,emergency)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole1,emergency)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole2,emergency)" class="contentSubHeading">Pole 2</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole2,emergency)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole2,emergency)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole3,emergency)" class="contentSubHeading">Pole 3</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole3,emergency)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole3,emergency)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasPole(pole4,emergency)" class="contentSubHeading">Pole 4</div>
      <div *ngIf="hasSizeandTowerFoundationBaseLevelPole(pole4,emergency)" class="contentSubHeading">Size and Tower Foundation Base Level</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleSizeandTowerFoundationBaseLevel4);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasTowerDistancePole(pole4,emergency)" class="contentSubHeading">Tower Distance</div>
      <div  *ngFor="let items of data.TowerFoundationCheck.filter(poleTowerDistance4);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerFoundationCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerFoundationCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TowerVerticalityCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TowerVerticalityCheck')">
        <p>No</p>
        <p>Tower Verticality Check</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasStructure(structure1,emergency)" class="contentSubHeading">Self Supporting Tower</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(pole1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure2,emergency)" class="contentSubHeading">Guyed</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure3,emergency)" class="contentSubHeading">Steel Pole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

      <div *ngIf="hasStructure(structure4,emergency)" class="contentSubHeading">Monopole</div>
      <div  *ngFor="let items of data.TowerVerticalityCheck.filter(structure4);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TowerVerticalityCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TowerVerticalityCheck')"></span></p>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="data.TensionCheck.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('TensionCheck')">
        <p>No</p>
        <p>Tension Check</p>
        <p>Details</p>
      </div>
      <div class="contentSubHeading">Tension/Guy Wire 3/8"</div>

      <div *ngIf="hasLevel(level1,emergency)" class="contentSubHeading">Level 1</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level2,emergency)" class="contentSubHeading">Level 2</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasLevel(level3,emergency)" class="contentSubHeading">Level 3</div>
      <div  *ngFor="let items of data.TensionCheck.filter(level3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'TensionCheck',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'TensionCheck')"></span></p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="data.LoadingonTower.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('LoadingonTower')">
        <p>No</p>
        <p>Loading on Tower</p>
        <p>Details</p>
      </div>

      <div *ngIf="hasEquipment(antennaL,emergency)" class="contentSubHeading">Antenna</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(antennaL);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
      <div *ngIf="hasEquipment(rruL,emergency)" class="contentSubHeading">RRU</div>
      <div  *ngFor="let items of data.LoadingonTower.filter(rruL);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'LoadingonTower',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'LoadingonTower')"></span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>