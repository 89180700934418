<mat-dialog-content>
  <iframe [src]="link">
  </iframe>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="close">
      <button mat-raised-button (click)="close()" color="primary">Close</button>
    </div>
  </mat-dialog-actions>
