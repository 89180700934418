<mat-chip-list aria-label="Issues selection">
    <mat-chip class="chips" value="General" [ngClass]="{'chipsSelected': item == 'General'}" (click)="selectDetails($event)">General</mat-chip>
    <mat-chip class="chips" value="Equipment" [ngClass]="{'chipsSelected': item == 'Equipment'}" (click)="selectDetails($event)">Equipment</mat-chip>
    <mat-chip class="chips" value="TowerFoundationCheck" [ngClass]="{'chipsSelected': item == 'TowerFoundationCheck'}" (click)="selectDetails($event)">Tower Foundation Check</mat-chip>
    <mat-chip class="chips" value="TowerVerticalityCheck" [ngClass]="{'chipsSelected': item == 'TowerVerticalityCheck'}" (click)="selectDetails($event)">Tower Verticality Check</mat-chip>
    <mat-chip class="chips" value="TensionCheck" [ngClass]="{'chipsSelected': item == 'TensionCheck'}" (click)="selectDetails($event)">Tension Check</mat-chip>
    <mat-chip class="chips" value="LoadingonTower" [ngClass]="{'chipsSelected': item == 'LoadingonTower'}" (click)="selectDetails($event)">Loading on Tower</mat-chip>
    <mat-chip class="chips" value="PDF" [ngClass]="{'chipsSelected': item == 'PDF'}" class="deleteIcon" (click)="buildPDF()">Print PDF<mat-icon>article</mat-icon></mat-chip>
    <ng-container *ngIf="(role == 'aero-processing') && selected == 'Details'">
      <mat-chip *ngIf="!hasEquipmentData(item)" class="addIcon" (click)="addSection()"><mat-icon>add</mat-icon></mat-chip>
      <mat-chip class="deleteIcon" (click)="deleteSection()"><mat-icon>delete</mat-icon></mat-chip>
    </ng-container>
  </mat-chip-list>
  
  <div *ngIf="data" class="content">
    <div *ngIf="selected == 'General'">
      <div *ngFor="let items of data.General;index as j">
        <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
          <div class="contentBox" *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
            <div class="contentHeading">{{ items.Name }}
              <span class="detailsHeadingIcon" *ngIf="(role == 'aero-processing')"><i><mat-icon class="editIcon" (click)="editData('Name',items.Name,'General',i,j,true)"> edit</mat-icon></i></span>
            </div>
            <table class="table">
              <tr class="subHeading">
                <td>No</td>
                <td>Item</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td colspan="2">Description</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td>Visual</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
              </tr>
              <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
                <td>{{i+1}}</td>
                <td>{{ item.Name }}</td>
                <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'General',i,j,false)"> edit</mat-icon></i></td>
                <td colspan="2" class="boxContainer">
                  <span class="roundedBox"
                    [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                    {{ item.Value }}</span>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'General',i,j,false)"> edit</mat-icon></i></td>
                <td>
                  <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'General',i,j,false)"> edit</mat-icon></i></td>
              </tr>
            </table>
  
          </div>
        </div>
      </div>
    </div>
   <div *ngIf="selected == 'Equipment'">
      <div *ngFor="let items of data.Equipment;index as j">
        <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
          <div class="contentBox"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
            <div class="contentHeading">{{ items.Name }}</div>
            <table class="table">
              <tr class="subHeading">
                <td>No</td>
                <td>Item</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td colspan="2">Description</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td>Visual</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
              </tr>
              <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
                <td>{{i+1}}</td>
                <td>{{ item.Name }}</td>
                <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'Equipment',i,j,false)"> edit</mat-icon></i></td>
                <td colspan="2" class="boxContainer">
                  <span class="roundedBox"
                    [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                    {{ item.Value }}</span>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'Equipment',i,j,false)"> edit</mat-icon></i></td>
                <td>
                  <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'Equipment',i,j,false)"> edit</mat-icon></i></td>
  
              </tr>
            </table>
  
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selected == 'TowerFoundationCheck'">
      <div *ngFor="let items of data.TowerFoundationCheck;index as j">
        <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
          <div class="contentBox"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
            <div class="contentHeading">{{ items.Name }}</div>
            <table class="table">
              <tr class="subHeading">
                <td>No</td>
                <td>Item</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td colspan="2">Description</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td>Visual</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
              </tr>
              <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
                <td>{{i+1}}</td>
                <td>{{ item.Name }}</td>
                <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'TowerFoundationCheck',i,j,false)"> edit</mat-icon></i></td>
                <td colspan="2" class="boxContainer">
                  <span class="roundedBox"
                    [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                    {{ item.Value }}</span>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'TowerFoundationCheck',i,j,false)"> edit</mat-icon></i></td>
                <td>
                  <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'TowerFoundationCheck',i,j,false)"> edit</mat-icon></i></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selected == 'TowerVerticalityCheck'">
      <div *ngFor="let items of data.TowerVerticalityCheck;index as j">
        <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
          <div class="contentBox"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
            <div class="contentHeading">{{ items.Name }}</div>
            <table class="table">
              <tr class="subHeading">
                <td>No</td>
                <td>Item</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td colspan="2">Description</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td>Visual</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
              </tr>
              <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
                <td>{{i+1}}</td>
                <td>{{ item.Name }}</td>
                <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'TowerVerticalityCheck',i,j,false)"> edit</mat-icon></i></td>
                <td colspan="2" class="boxContainer">
                  <span class="roundedBox"
                    [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                    {{ item.Value }}</span>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'TowerVerticalityCheck',i,j,false)"> edit</mat-icon></i></td>
                <td>
                  <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'TowerVerticalityCheck',i,j,false)"> edit</mat-icon></i></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selected == 'TensionCheck'">
      <div *ngFor="let items of data.TensionCheck;index as j">
        <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
          <div class="contentBox"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
            <div class="contentHeading">{{ items.Name }}</div>
            <table class="table">
              <tr class="subHeading">
                <td>No</td>
                <td>Item</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td colspan="2">Description</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td>Visual</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
              </tr>
              <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
                <td>{{i+1}}</td>
                <td>{{ item.Name }} </td>
                <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'TensionCheck',i,j,false)"> edit</mat-icon></i></td>
                <td colspan="2" class="boxContainer">
                  <span class="roundedBox"
                    [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                    {{ item.Value }}</span>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'TensionCheck',i,j,false)"> edit</mat-icon></i></td>
                <td>
                  <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'TensionCheck',i,j,false)"> edit</mat-icon></i></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selected == 'LoadingonTower'">
      <div *ngFor="let items of data.LoadingonTower;index as j">
        <div *ngFor="let item of items.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
          <div class="contentBox"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
            <div class="contentHeading">{{ items.Name }}</div>
            
            <table class="table">
              <tr class="subHeading">
                <td>No</td>
                <td>Item</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td colspan="2">Description</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
                <td>Visual</td>
                <td *ngIf="role == 'aero-processing'">Edit</td>
              </tr>
              <tr class="contentRow"  *ngIf="item.Option != 1 && item.Option != '' && item.Option != null">
                <td>{{i+1}}</td>
                <td>{{ item.Name }}</td>
                <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,'LoadingonTower',i,j,false)"> edit</mat-icon></i></td>
                <td colspan="2" class="boxContainer">
                  <span class="roundedBox"
                    [ngClass]="{'monitor' : item.Option == 1,'planned' : item.Option == 2,'urgent' : item.Option == 3,'emergency' : item.Option == 4}">
                    {{ item.Value }}</span>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',item.Value,'LoadingonTower',i,j,false)"> edit</mat-icon></i></td>
                <td>
                  <i *ngIf="item.Image.length"><mat-icon class="imageIcon" (click)="sendImage(item.Image)">description</mat-icon></i>
                </td>
                <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Image',item.Image,'LoadingonTower',i,j,false)"> edit</mat-icon></i></td>
              </tr>
            </table>

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selected == 'Details'">

<!-- Loading on Tower - Equipment Table -->
      <div *ngIf="hasEquipmentData(item)">
        <div class="contentBox">
          <div class="contentHeading">
            {{item.Name}}
          </div>

          
          <div class="equipmentContent">
          <table  class="table equipmentContent">
            <tr class="subHeading">
              <td>Level</td>
              <td>Edit</td>
              <td>Mounting Frame</td>
              <td>Cellular</td>
              <td>Omni</td>
              <td>Parabolic Ø 1.2 m</td>
              <td>Parabolic Ø 2.4 m</td>
              <td>Parabolic Ø 3.0 m</td>
              <td>Grid Parabolic Ø 1.8 m</td>
              <td>RRU</td>
              <td>Installation Height (m)</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>
            
         
          
            <tr [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}" class="contentRow" *ngFor="let elt of item.ELDetails;index as i">
                <td style="border-spacing:5px">{{elt.Level }}</td>
                <td style="border-spacing:5px" *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editDataEquipment(item.Name,item.section,elt.Details, elt.Level)"> edit</mat-icon></i></td>
                <td style="border-spacing:5px" *ngFor="let data of elt.Details;index as i">
                    {{data.Value}} 
                </td>
                <td style="border-spacing:5px" *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,item.Name ,item.Section ,i,item.sectionIndex, elt.Level, 'big')"></span></p></td>
              </tr>
          </table>
        </div> 


          <table class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td colspan="2">{{item.Section === 'TowerVerticalityCheck' && item.Structure !== 'SteelPole' ? 'Deviation View A (L,R)(mm)' : 'Description'}}</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td>{{item.Section === 'TowerVerticalityCheck' && item.Structure !== 'SteelPole' ? 'Deviation View B (L,R)(mm)' : 'Visual'}}</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>

            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td>{{i+1}}</td>
              <td>{{ elt.Name == 'Equipment Leg' ? 'Equipment Placement' : elt.Name}}</td>
              <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
              <td colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>
              <td  *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
              <td>
                <span *ngIf="item.Section === 'TowerVerticalityCheck' && item.Structure !== 'SteelPole'" class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Image[0] }}
                </span>
                <i *ngIf="elt.Image.length && item.Structure !== 'SelfSupportingTower' && item.Structure !== 'Guyed' && item.Structure !== 'Monopole'"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData(item.Section === 'TowerVerticalityCheck' && (item.Structure === 'SelfSupportingTower' || item.Structure === 'Guyed' || item.Structure === 'Monopole')?'Image0':'Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
              <td  *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
            </tr>
          </table>
        </div>
        </div>
      
<!-- Loading on Tower - Remaining Table -->
      <div *ngIf="isLoadingOnTower(item)">
        <div class="contentBox">
          <div class="contentHeading">
            {{ item.Name }}
            <span class="detailsHeadingIcon" *ngIf="(role == 'aero-processing') && item.section === 'General'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,item.section,i,item.sectionIndex,true)"> edit</mat-icon></i></span>
          </div>

          <div *ngIf="item.Details[0].Name" class="contentHeading">
            {{item.Details[0].Value}}
          </div>
          <table *ngIf="item.Details[0].Name" class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <!-- <td *ngIf="role == 'aero-processing'">Edit</td> -->
              <td colspan="2">Description</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>
            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '1'">{{i+1}}</td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '1'">{{elt.Name.slice(0,-1)}}</td>
              <!-- <td *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '1'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td> -->
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '1'" colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '1'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '1'">
                <i *ngIf="elt.Image.length"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '1'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '1'"><p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.Name, item.EquipmentLoading)"></span></p></td>
            </tr>
          </table>

          <div *ngIf="item.Details[6].Name" class="contentHeading">
            {{item.Details[6].Value}}
          </div>
          <table *ngIf="item.Details[6].Name" class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <!-- <td *ngIf="role == 'aero-processing'">Edit</td> -->
              <td colspan="2">Description</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>
            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '2'">{{i+1}}</td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '2'">{{elt.Name.slice(0,-1)}}</td>
              <!-- <td *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '2'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td> -->
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '2'" colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '2'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '2'">
                <i *ngIf="elt.Image.length"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '2'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '2'"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
            </tr>
          </table>

          <div *ngIf="item.Details[12].Name" class="contentHeading">
            {{item.Details[12].Value}}
          </div>
          <table *ngIf="item.Details[12].Name" class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <!-- <td *ngIf="role == 'aero-processing'">Edit</td> -->
              <td colspan="2">Description</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>
            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '3'">{{i+1}}</td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '3'">{{elt.Name.slice(0,-1)}}</td>
              <!-- <td *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '3'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td> -->
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '3'" colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '3'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '3'">
                <i *ngIf="elt.Image.length"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '3'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '3'"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
            </tr>
          </table>

          <div *ngIf="item.Details[18].Name" class="contentHeading">
            {{item.Details[18].Value}}
          </div>
          <table *ngIf="item.Details[18].Name" class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <!-- <td *ngIf="role == 'aero-processing'">Edit</td> -->
              <td colspan="2">Description</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>
            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '4'">{{i+1}}</td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '4'">{{elt.Name.slice(0,-1)}}</td>
              <!-- <td *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '4'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td> -->
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '4'" colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '4'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td *ngIf="elt.Name !== null && elt.Name?.slice(-1) == '4'">
                <i *ngIf="elt.Image.length"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '4'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td  *ngIf="elt.Name !== null && role == 'aero-processing' && elt.Name?.slice(-1) == '4'"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
            </tr>
          </table>

          <div class="contentHeading">
            Grand Total Area
          </div>
          <table class="table">
            <tr class="subHeading">
              <td>No</td>
              <td>Item</td>
              <!-- <td *ngIf="role == 'aero-processing'">Edit</td> -->
              <td colspan="2">Description</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td>Visual</td>
              <td *ngIf="role == 'aero-processing'">Edit</td>
              <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
            </tr>
            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td *ngIf="elt.Name?.slice(-1) == '0'">{{i+1}}</td>
              <td *ngIf="elt.Name?.slice(-1) == '0'">{{elt.Name.slice(0,-1)}}</td>
              <!-- <td *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '0'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td> -->
              <td *ngIf="elt.Name?.slice(-1) == '0'" colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>
              <td  *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '0'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td *ngIf="elt.Name?.slice(-1) == '0'">
                <i *ngIf="elt.Image.length"><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '0'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.EquipmentLoading)"> edit</mat-icon></i></td>
              <td  *ngIf="role == 'aero-processing' && elt.Name?.slice(-1) == '0'"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
            </tr>
          </table>

        </div>
      </div>

<!-- Tower Verticality Check - Self Supporting, Guyed, Monopole Tables (L,R Deviation Table) -->
      <div *ngIf="item.Section === 'TowerVerticalityCheck' && item.Structure !== 'SteelPole'" >
        <div class="contentHeading">
          {{ item.Name }}
          <span class="detailsHeadingIcon" *ngIf="(role == 'aero-processing') && item.section === 'General'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,item.section,i,item.sectionIndex,true)"> edit</mat-icon></i></span>
        </div>

        <div class="towerVerticalityCheck">
        <table class="table">
          <tr class="subHeading">
            <td>No</td>
            <td>Item</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td>Deviation View A(mm)</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td>Deviation View B(mm)</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
          </tr>

          <tr class="evenRow">
            <td colspan="3"></td>
            <td class="deviationRow" id="LRColumn" colspan="1">
              <div class="leftDeviationBorder"> L </div>
              <span class="dividerLR">|</span>
              <div class="rightDeviationBorder"> R </div>
            </td>
            <td colspan="1"></td>

            <td class="deviationRow" colspan="1">
              <div class="leftDeviationBorder"> L </div>
              <span class="dividerLR">|</span>
              <div class="rightDeviationBorder"> R </div>
            </td>
            <td colspan="1"></td>
            <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'"></td>
            </tr>
            
            <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td *ngIf="i!==0">{{i}}</td>
              <td *ngIf="i!==0">{{ elt.Name }}</td>
              <td *ngIf="role == 'aero-processing' && i!==0"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name, item.Structure)"> edit</mat-icon></i></td>
              
              <td colspan="1" class="deviationRow" *ngIf="i!==0">
                <div>
                  <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}"> {{ elt.Value?.split(',')[0] }} </span> 
                </div>
              <span class="dividerLR">|</span>
              <div>
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}"> {{ elt.Value?.split(',')[1] }} </span>
              </div>
            </td>

            <td *ngIf="role == 'aero-processing' && i!==0"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.Structure)"> edit</mat-icon></i></td>
            <!-- <td  *ngIf="role == 'aero-processing' && item.section == 'TowerVerticalityCheck'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item)"> edit</mat-icon></i></td> -->

            <td colspan="1" class="deviationRow" *ngIf="elt.Image.length && i!==0">
              <div>
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}"> {{ elt.Image[0]?.split(',')[0] }} </span> 
              </div>
              <span class="dividerLR">|</span>
              <div>
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}"> {{ elt.Image[0]?.split(',')[1] }} </span>
              </div>
            </td>
            <td colspan="1" class="deviationRow" *ngIf="!elt.Image.length && i!==0">
              <span class="dividerLR">|</span>
            </td>
            <td *ngIf="role == 'aero-processing' && i!==0"><i><mat-icon class="editIcon" (click)="editData('Image0',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.Structure)"> edit</mat-icon></i></td>

            <td  *ngIf="role == 'aero-processing' && i!==0"><p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
          </tr>
          
        </table>
      </div>
      
        <table>
          <tr class="subHeading">
            <td>No</td>
            <td>Item</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td colspan="2">Description</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td>Visual</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <!-- <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td> -->
          </tr>

          <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
            <td *ngIf="i===0">{{i+1}}</td>
            <td *ngIf="i===0">{{elt.Name}}</td>
            <td *ngIf="role == 'aero-processing' && (i===0)"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
            <td colspan="2" class="boxContainer" *ngIf="i===0">
              <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                {{ elt.Value }}</span>
            </td>
            <td  *ngIf="role == 'aero-processing' && (i===0)"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
            <td *ngIf="elt.Image.length && (i===0)">
              <i><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
            </td>
            <td *ngIf="role == 'aero-processing' && (i===0)"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
            <td  *ngIf="role == 'aero-processing' && (i===0)"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
          </tr>

        </table>
      </div>
      
<!-- All other Tables -->
      <div *ngIf="!isLoadingOnTower(item) && !hasEquipmentData(item) && !(item.Section === 'TowerVerticalityCheck' && item.Structure !== 'SteelPole')" class="contentBox">
        <div class="contentHeading">
          {{ item.Name }}
          <span class="detailsHeadingIcon" *ngIf="(role == 'aero-processing') && item.section === 'General'"><i><mat-icon class="editIcon" (click)="editData('Name',item.Name,item.section,i,item.sectionIndex,true)"> edit</mat-icon></i></span>
        </div>

        <table class="table">
          <tr class="subHeading">
            <td>No</td>
            <td>Item</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td colspan="2">Description</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td>Visual</td>
            <td *ngIf="role == 'aero-processing'">Edit</td>
            <td *ngIf="role == 'aero-processing'"><p *ngIf="mode == 'Delete'">Delete</td>
          </tr>
          
          <tr class="contentRow" *ngFor="let elt of item.Details;index as i" [ngClass]="{'evenRow': 0 === i % 2, 'oddRow' : 0 !== i % 2}">
              <td>{{i+1}}</td>
              <td>{{ elt.Name == 'Equipment Leg' ? 'Equipment Placement' : elt.Name}}</td>
              <td *ngIf="role == 'aero-processing'"><i><mat-icon class="editIcon" (click)="editData('Name',elt.Name,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
              <td colspan="2" class="boxContainer">
                <span class="roundedBox" [ngClass]="{'monitor' : elt.Option == 1,'planned' : elt.Option == 2,'urgent' : elt.Option == 3,'emergency' : elt.Option == 4}">
                  {{ elt.Value }}</span>
              </td>

              <td  *ngIf="role == 'aero-processing'&& (item.section == 'General' || item.section == 'Equipment')"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
              <td  *ngIf="role == 'aero-processing' && item.section == 'TowerFoundationCheck'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.Pole)"> edit</mat-icon></i></td>
              <td  *ngIf="role == 'aero-processing' && item.section == 'TowerVerticalityCheck' && item.Structure == 'SteelPole'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.Structure)"> edit</mat-icon></i></td>
              <td  *ngIf="role == 'aero-processing' && item.section == 'TensionCheck'"><i><mat-icon class="editIcon" (click)="editData('Value',elt.Value,item.section,i,item.sectionIndex,false,item.Name, item.Level)"> edit</mat-icon></i></td>
              
              <td *ngIf="elt.Image.length">
                <i><mat-icon class="imageIcon" (click)="sendImage(elt.Image)">description</mat-icon></i>
              </td>
              <td *ngIf="!elt.Image.length"></td>

              <td *ngIf="role == 'aero-processing'&& (item.section == 'General' || item.section == 'Equipment')"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name)"> edit</mat-icon></i></td>
              <td *ngIf="role == 'aero-processing' && item.section == 'TowerFoundationCheck'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.Pole)"> edit</mat-icon></i></td>
              <td *ngIf="role == 'aero-processing' && item.section == 'TowerVerticalityCheck' && item.Structure == 'SteelPole'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.Structure)"> edit</mat-icon></i></td>
              <td *ngIf="role == 'aero-processing' && item.section == 'TensionCheck'"><i><mat-icon class="editIcon" (click)="editData('Image',elt.Image,item.section,i,item.sectionIndex,false,item.Name, item.Level)"> edit</mat-icon></i></td>
              
              <td  *ngIf="role == 'aero-processing' && (item.section == 'General' || item.section == 'Equipment')"><p *ngIf="mode == 'Delete' && i != 0 && i != 1"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
              <td  *ngIf="role == 'aero-processing' && item.section != 'General' && item.section != 'Equipment'"><p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,elt.Name,item.section,i,item.sectionIndex,item.name)"></span></p></td>
            </tr>
        </table>
 
      </div>

    </div>
  </div>
  