import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryFetchService {


  constructor(private fireStore : AngularFirestore, private snackBar: MatSnackBar, private service: AuthService) {
   }
projectId = this.service.projectID

  fetchData(towerName, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).doc(siteId).get();
}
setData(tower, siteId, year){
  return  this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +tower+'___'+siteId).doc(siteId);
  }








}
