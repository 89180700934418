import { Component, OnInit } from '@angular/core';
import { DocsFetchService } from './docs-fetch.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditComponent } from './../edit/edit.component';
import { DocumentDialogComponent } from './document-dialog/document-dialog.component';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements OnInit {
  role;
  towerName;
  result = [];
  isLoaded = false;
  urlSafe;
  updatedValue = '';
  link = null;
  year: any;
  constructor(private router : Router,private dialog: MatDialog,private service: DocsFetchService,private route : ActivatedRoute,public sanitizer: DomSanitizer,private storage : AngularFireStorage,private snackBar : MatSnackBar) {
    this.getData()
   }

  ngOnInit(): void {
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }
    this.route.params.subscribe(params =>{
      this.towerName = params.tower;
      if(this.towerName){

        this.service.fetchData(this.towerName,this.year).get().subscribe(
          (data)=>{
            this.result = data.data()['Docs'];
            // data.forEach(elt =>{
            //  this.result = elt.data()['Docs'];
            // })
          },
          (err)=>{
            this.snackBar.open(err, "close", { duration: 2000 });
          },
          ()=>{
            this.isLoaded = true;
          }
        )
  }
    });
  }

  updatePDF(link) {
    this.link = null;
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  openProcessedDialog(event: any){
    let dialogRef = this.dialog.open(DocumentDialogComponent, {
      height: 'auto',
      width: '600px',
      data: {
        projectID: this.service.projectId,
        towerName: this.towerName,
        event: event,
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.length > 0)
      {
        this.result = [...this.result,...result]
        this.updateDoc();
      }
    });
  }

  editDoc(event){
if(event.action == "Add"){
  this.openProcessedDialog(event)
}
if(event.action == "Delete"){
  for(let i = 0; i < event.files.length;i++){
    let id = null;
    this.result = this.result.filter(elt =>{
      if(elt.File == event.files[i]){
         this.storage.storage.refFromURL(elt.Link).delete().catch(err=>{
          this.snackBar.open(err, "close", { duration: 2000 });
        });
      }
     return elt.File !== event.files[i];
   });

   this.updateDoc();
  }
}
if(event.action == "Edit"){
  const dialogRef = this.dialog.open(EditComponent, {
    width: '450px',
    maxHeight: '350px',
    data: { value: event.name, name: 'Name' }
  });
  dialogRef.afterClosed().subscribe(
    result => {
      this.updatedValue = result.value;
    },
    (err) => {
      this.snackBar.open(err, "close", { duration: 2000 });
    },
    () => {
       if (this.updatedValue != '' || this.updatedValue != undefined) {
        if (this.updatedValue !== event.name) {
          this.result[event.index].File = this.updatedValue;
          this.updateDoc();
        }
        this.updatedValue = '';
      }
    }
  )
}
  }


  updateDoc(){
    this.service.setData(this.towerName,this.year).set(
      {
      Docs : this.result
      },
      {
        merge: true
      }
    ).catch(err=>{
      this.snackBar.open(err, "close", { duration: 2000 });
    })

  }

  getData() {
    this.route.params.subscribe(params => {
      this.year = params.year;
      console.log(this.year);
      

    });
  }

}
