<div class="navBar">
  <app-navbar [currentRoute]="'analyse'" [main]="true"></app-navbar>
</div>
<div class="container">
  <div class="right-pane">
    <h3 >Structure Details</h3>
    <div class="right-pane-content">
      <div class="structure-container">
        <h4>Structure</h4>
        <div class="image-link-container tower-button" style="background-color: rgb(174, 160, 113);color: white;" (click)="showTowerDetails()">
          <ion-icon name="image-outline"></ion-icon> <span>Tower</span>
        </div>
        <div class="image-link-container" (click)="showGroundDetails()">
          <ion-icon name="image-outline"></ion-icon> <span>Grounding</span>
        </div>

       </div>

      <div class="structure-container">
        <h4>Antenna</h4>
        <div class="image-link-container" (click)="antennaChange('antenna 1')">
          <ion-icon name="image-outline"></ion-icon> <span>Antenna 1</span>
        </div>
        <div class="image-link-container" (click)="antennaChange('antenna 2')">
          <ion-icon name="image-outline"></ion-icon> <span>Antenna 2</span>
        </div>
        <div class="image-link-container" (click)="antennaChange('antenna 3')">
          <ion-icon name="image-outline"></ion-icon> <span>Antenna 3</span>
        </div>
        <div class="image-link-container" (click)="antennaChange('antenna 4')">
          <ion-icon name="image-outline"></ion-icon> <span>Antenna 4</span>
        </div>
       </div>
      <div class="structure-container">
       <h4>Dish</h4>
       <div class="image-link-container" (click)="dishChange('dish 1')">
         <ion-icon name="image-outline"></ion-icon> <span>Dish 1</span>
       </div>
       <div class="image-link-container" (click)="dishChange('dish 2')">
         <ion-icon name="image-outline"></ion-icon> <span>Dish 2</span>
       </div>
       <div class="image-link-container" (click)="dishChange('dish 3')">
         <ion-icon name="image-outline"></ion-icon> <span>Dish 3</span>
       </div>
       <div class="image-link-container" (click)="dishChange('dish 4')">
         <ion-icon name="image-outline"></ion-icon> <span>Dish 4</span>
       </div>
      </div>


    </div>
   </div>


  <div class="middle-pane">
    <div class="tower-drop" >
     <h3>  <label for="tower">Tower List</label> </h3>
       <select  #towerName  id="tower" (change)="changeTower(towerName.value )" placeholder="Select Tower">
        <option class="option-data" value="IKEJA PLAZA" >IKEJA PLAZA </option>
        <option class="option-data" value="MARYLAND">MARYLAND </option>
      </select>
      </div>
      <div class="image-container">
      <img class="timage" [src]="towers[0].tower_imgs[0].img" alt="tower" srcset="">
      </div>

      <div class="range-container">
        <input class="slider"  min="2021" max="2022"  value="2021" type="range">
        <div class="slider-value" >
          <p>2021</p>
          <p>2022</p>
        </div>
        <!-- <input class="slider"  min="2000" max="2010"  value="2000" type="range" style="width: 100%;">
      <div class="slider-value" style="display: flex;justify-content:space-between;">
        <p>2000</p>
        <p>2001</p>
        <p>2002</p>
        <p>2003</p>
        <p>2004</p>
        <p>2005</p>
        <p>2006</p>
        <p>2007</p>
        <p>2008</p>
        <p>2009</p>
        <p>2010</p>
      </div> -->
      </div>
  </div>
  <div class="details-pane">
    <h3>Details</h3>


   <div class="tower-profile-data">
    <div class="single-profile-data" >
      <div>Site Code</div>
      <div class="profile-value">{{selected_tower.code}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Latitude</div>
      <div class="profile-value">{{selected_tower.lat}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Longitude</div>
      <div class="profile-value">{{selected_tower.lon}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Type</div>
      <div class="profile-value">{{selected_tower.type}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Structure Height</div>
      <div class="profile-value">{{selected_tower.data[0].structure_height}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Overall Height</div>
      <div class="profile-value">{{selected_tower.data[0].overall_height}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Total number of sections</div>
      <div class="profile-value">{{selected_tower.sections}}</div>
    </div>
    <div class="single-profile-data" >
      <div>	Tower top face width</div>
      <div class="profile-value">{{selected_tower.data[0].top_face_width}}</div>
    </div>
    <div class="single-profile-data" >
      <div>	Tower bottom face width</div>
      <div class="profile-value">{{selected_tower.data[0].bottom_face_width}}</div>
    </div>
    <div class="single-profile-data" >
      <div>	Straight Section Height</div>
      <div class="profile-value">{{selected_tower.data[0].straight_section_height}}</div>
    </div>
    <div class="single-profile-data" >
      <div>	Tower Base Dimension Width or Diameter</div>
      <div class="profile-value">{{selected_tower.data[0].Base_Dimension_Width_or_Diameter}}</div>
    </div>
    <div class="single-profile-data" >
      <div>	Structure identification plate</div>
      <div class="profile-value">{{selected_tower.structure_identification_plate}}</div>
    </div>
    <div class="single-profile-data" >
      <div>	Foundation type</div>
      <div class="profile-value">{{selected_tower.foundation_type}}</div>
    </div>
    <div class="single-profile-data" >
      <div>Any other identifying markings ?</div>
      <div class="profile-value">{{selected_tower.other_identifying_markings}}</div>
    </div>
   </div>

<div class="grounding-data">
  <div class="grounding-container">
   <div>Condition of earth connection above ground</div>
   <div class="grounding-value">{{selected_tower.grounding_data.condition}}</div>
  </div>
  <div class="grounding-container">
    <div>	Cable pit / lid damaged or missing</div>
    <div class="grounding-value">{{selected_tower.grounding_data.cable_pit_status}}</div>
   </div>

</div>

    <div class="tower-detail-container">
      <div class="single-details-container" >
        <div >Equipment Referance</div>
        <div class="value">D1</div>
      </div>
      <div class="single-details-container" >
        <div>Equipment Placement</div>
        <div  class="value">Leg1</div>
      </div>
      <div class="single-details-container" >
        <div>Carrier</div>
        <div  class="value">C1</div>
      </div>
      <div class="single-details-container" >
        <div>Equipment Type</div>
        <div  class="value">Panel</div>
      </div>
      <div class="single-details-container" >
        <div  class="change-element">Dimension H(m)xW(m)xD(m)</div>
         <div  class="value" style="align-self: flex-start;">1.34x0.26x0.09</div>
      </div>
      <div class="single-details-container" >
        <div>Height from ground (m)</div>
        <div  class="value">35.93</div>
      </div>
      <div class="single-details-container azimuth-container" >
        <div>Azimuth </div>
        <div  class="value">104</div>
      </div>
      <div class="single-details-container" >
        <div>Equipment damage visible?</div>
        <div  class="value equipment-status">Good</div>
      </div>
      <div class="single-details-container" >
        <div>Feeder damage visible?</div>
        <div  class="value">Good</div>
      </div>
     <!-- <div>
     <h4>Disc</h4>
     <p class="disc-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non hendrerit elit. Donec porttitor pretium sem, nec porttitor erat scelerisque a. Morbi scelerisque magna a mattis iaculis. Donec elementum ornare tortor, id malesuada ante ullamcorper eu.</p>
     </div>
     <div>
       <h4>Antenna</h4>
       <p class="antenna-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non hendrerit elit. Donec porttitor pretium sem, nec porttitor erat scelerisque a. Morbi scelerisque magna a mattis iaculis. Donec elementum ornare tortor, id malesuada ante ullamcorper eu.</p>
     </div>
     <div>
       <h4>Object 1</h4>
       <p class="object1-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non hendrerit elit. Donec porttitor pretium sem, nec porttitor erat scelerisque a. Morbi scelerisque magna a mattis iaculis. Donec elementum ornare tortor, id malesuada ante ullamcorper eu.</p>
     </div>
     <div>
       <h4>Object 2</h4>
       <p class="object2-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non hendrerit elit. Donec porttitor pretium sem, nec porttitor erat scelerisque a. Morbi scelerisque magna a mattis iaculis. Donec elementum ornare tortor, id malesuada ante ullamcorper eu.</p>
     </div> -->
    </div>

   </div>
  <!-- <div class="right-pane">
   <h3>Structure Details</h3>
   <div class="right-pane-content">
     <div class="structure-container">
      <h4>Disc</h4>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Disc 1</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Disc 2</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Disc 3</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Disc 4</span>
      </div>
     </div>
     <div class="structure-container">
      <h4>Antenna</h4>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Antenna 1</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Antenna 2</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Antenna 3</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Antenna 4</span>
      </div>
     </div>
     <div class="structure-container">
      <h4>Object</h4>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Object 1</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Object 2</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Object 3</span>
      </div>
      <div class="image-link-container">
        <ion-icon name="image-outline"></ion-icon> <span>Object 4</span>
      </div>
     </div>
   </div>
  </div> -->
</div>
