import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  year: any;
  value : string;
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  value;
  towersList;

  constructor(public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
      let year = this.data.year
      let totalArray: any = this.data.value
      for(var i = 0 ; i < totalArray.length; i++){
        if(totalArray[i].Towers.length > 0 && totalArray[i].hasOwnProperty("Year") && totalArray[i].Year === year) {
          this.towersList = totalArray[i]
        }
      }
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  searchFn(){
    if(this.value){
      let data = this.value.split("-")
      let towerName = data[0].trim()
      let siteID = data[1].trim()
  
      return {siteID : siteID, towerName: towerName}
    }
  }

}
