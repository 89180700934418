
<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>
<div class="doc">
  <div class="docTabs one" *ngIf="isLoaded">
    <h3 class="docHeading"> Files and Media </h3>
    <div class="docContent">
<app-files [data]="result" [role]="role" (editDoc)="editDoc($event)" (updateLink)="updatePDF($event)"></app-files>
    </div>
  </div>
<div class="docTabs two" *ngIf="isLoaded">
  <app-fileviewer [link]="link"></app-fileviewer>
</div>
</div>
