// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ihs: 'ihs_2021_1638616421',
  us: 'USCellular_2021_1640251570',
  hor: 'Horvath Communications_2022_1660096287',
  ais:'AIS Thailand_2022_1662000068',
  
  firebase_config: {
    apiKey: "AIzaSyAPeJsTZWjQDjvlJYEDMUY98YBJn24Ac9w",
    authDomain: "vertikaliti-admin.firebaseapp.com",
    projectId: "vertikaliti-admin",
    storageBucket: "vertikaliti-admin.appspot.com",
    messagingSenderId: "455103451727",
    appId: "1:455103451727:web:872f321b50399c3705503a"
  },
  
  secret: 'juW0kbrK6myG7hNn'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
