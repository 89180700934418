import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './../../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AeroAdminGuard implements CanActivate {

  constructor(private service: AuthService, private router: Router) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

       return this.authenticate();
  }

  async authenticate()  {
    let role;
    
    let service = await this.service.userRole();
    console.log(service);
    let result = await service.toPromise()
    let data = result.data()
    role = data['role'];
    if (this.service.isLoggedIn !== true || role !== 'aero-processing') {
      this.router.navigate(['sign-in']);
    }
    return true;
    }

}
