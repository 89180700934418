import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-rigger-audit-image',
  templateUrl: './rigger-audit-image.component.html',
  styleUrls: ['./rigger-audit-image.component.css']
})
export class RiggerAuditImageComponent implements OnInit {
  @Input() imageArray = [];
  img = 0;
  count = 0;
  dragX = 0;
  dragY = 0;
  panX = 0;
  panY = 0;
  crntPanX = 0;
  crntPanY = 0;
  zoomX = 0;
  zoomY = 0;
  size = { w: 1070, h: 720 }
  pos = { x: 0, y: 0 }
  zoom_target = { x: 0, y: 0 }
  zoom_point = { x: 0, y: 0 }
  scale = 1
  
  @ViewChild("image") image;
  @ViewChild("container") container;
  
    constructor() { }
  
    ngOnInit(): void {
    }
  
    ngOnChanges() {
      this.img = 0;
    }
  
    next(){
      if(this.img < this.imageArray.length-1){
        this.img += 1;
      }else{
        this.img = 0;
      }
    }
  
    previous(){
      if(this.img > 0){
        this.img -= 1;
      }else{
        this.img = this.imageArray.length-1;
      }
    }
  
  
    wheelZoom(event) {
      var offset = this.container.nativeElement.getBoundingClientRect();
      this.zoom_point.x = event.pageX - offset.left
      this.zoom_point.y = event.pageY - offset.top
  
      event.preventDefault();
      var delta = event.wheelDelta;
      if (delta === undefined) {
        //we are on firefox
        delta = event.detail;
      }
      delta = Math.max(-1, Math.min(1, delta)) // cap the delta to [-1,1] for cross browser consistency
      // determine the point on where the slide is zoomed in
      this.zoom_target.x = (this.zoom_point.x - this.pos.x) / this.scale
      this.zoom_target.y = (this.zoom_point.y - this.pos.y) / this.scale
  
      // apply zoom
      this.scale += delta * 0.2 * this.scale
      this.scale = Math.max(1, Math.min(4, this.scale))
  
      // calculate x and y based on zoom
      this.pos.x = -this.zoom_target.x * this.scale + this.zoom_point.x
      this.pos.y = -this.zoom_target.y * this.scale + this.zoom_point.y
  
  
      // Make sure the slide stays in its container area when zooming out
      if (this.pos.x > 0){
        this.pos.x = 0;
      }
      if (this.pos.x + this.size.w * this.scale < this.size.w){
        this.pos.x = -this.size.w * (this.scale - 1);
      }
      if (this.pos.y > 0){
        this.pos.y = 0;
      }
      if (this.pos.y + this.size.h * this.scale < this.size.h){
        this.pos.y = -this.size.h * (this.scale - 1);
      }
  
      this.update(event)
  
    }
  
  
  
    update(event) {
      this.zoomX = this.pos.x + this.size.w * (this.scale - 1) / 2;
      this.zoomY = this.pos.y + this.size.h * (this.scale - 1) / 2;
      this.image.nativeElement.setAttribute("style", 'transform : translate(' + this.zoomX + 'px,' + this.zoomY + 'px) scale(' + this.scale + ',' + this.scale + ');')
    }
  
  
    dragImage(event) {
      if (event.shiftKey === false) {
        this.count = this.count + 1;
        if (this.count % 5 !== 0) {   // 15 or 25
          return;
        }
          if (event.pageX > this.dragX && Math.abs(event.pageX - this.dragX) > Math.abs(event.pageY - this.dragY)) {  //down
            this.next();
          }
          else if (event.pageX < this.dragX && Math.abs(event.pageX - this.dragX) > Math.abs(event.pageY - this.dragY)) { //top
            this.previous();
          }
        this.dragX = event.pageX;
        this.dragY = event.pageY;
      }
      if (event.shiftKey === true) {
        //panning
        let imgElt = this.image.nativeElement;
        if ((event.clientX - this.panX) >= 4) {
          this.crntPanX = this.crntPanX + 4;
          imgElt.style.marginLeft = this.crntPanX + "px";
          this.panX = event.clientX;
          // console.log('R', this.actual_left);
  
        }
        else if ((event.clientX - this.panX) <= -4) {
          this.crntPanX = this.crntPanX - 4;
          imgElt.style.marginLeft = this.crntPanX + "px";
          this.panX = event.clientX;
          // console.log('L', this.actual_left);
        }
        else if ((event.clientY - this.panY) >= 4) {
          this.crntPanY = this.crntPanY + 4;
          imgElt.style.marginTop = this.crntPanY + "px";
          this.panY = event.clientY;
          // console.log('U', this.actual_up);
  
        }
        else if ((event.clientY - this.panY) <= -4) {
          this.crntPanY = this.crntPanY - 4;
          imgElt.style.marginTop = this.crntPanY + "px";
          this.panY = event.clientY;
          // console.log('D', this.actual_up);
        }
      }
    }
  
  
  
  
    endDrag(event) {
      let imgElt = this.image.nativeElement;
      if(this.pos.x + this.size.w * this.scale === this.size.w && this.pos.y + this.size.h * this.scale === this.size.h){
        this.crntPanX = 0;
        this.crntPanY = 0;
        imgElt.style.marginLeft = this.crntPanX + "px";
        imgElt.style.marginTop = this.crntPanY + "px";
      }
    }
}
  