

<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>
<div class="spin">
  
  
  <div #content class="viewContainer" *ngIf="imageData.length;else ElseBlock;">
     
    <app-image-view [s3Uri]="s3Uri" [role]="role" [loading]="loading" [tower]="tower" [view]="view" [payload]="imageData" [crntDir]="crntDir" [index]="index" (editDirEvent)="editDir($event)" (imageViewEvent)="setCrntDir($event)" (imageSliderEvent)="sendIndex($event)"></app-image-view>
 <app-spin-controls [s3Uri] = "s3Uri" [view]="view" [loading]="loading" [payload]="imageData"  [crntDir]="crntDir" [index]="index" [sliderIndex]="sliderIndex" (spinControlsEvent)="sendIndex($event)"></app-spin-controls>
  </div>
  <ng-template class="viewContainer" #ElseBlock>
    <div class="else">
      
      <!-- {{ loadMsg() }} -->
      <h1>{{loadingStatus}}</h1>
    </div>
  </ng-template>


</div>
