import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fileviewer',
  templateUrl: './fileviewer.component.html',
  styleUrls: ['./fileviewer.component.css']
})
export class FileviewerComponent implements OnInit {
@Input() link = null;

  constructor() { }

  ngOnInit(): void {
  }


  ngOnChanges(){
    console.log(this.link)
  }


}
