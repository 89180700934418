import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as S3 from 'aws-sdk/clients/s3';
// import { s31s } from "s3-ls";
import { AWS } from "aws-sdk/dist/aws-sdk-react-native";
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators'; 
import { AuthService } from 'src/app/auth.service';



// import { boto3 }  from 'boto3';

// const s31s = require("s3-ls")

// const { files, folders } = lister.ls("/my-folder/subfolder/");

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  towerName;
  // s31 = boto3.resource('s3')
  existingFiles = [];
  data1;
  data2;
  // data2 = [{filename: 'file 1'}, {filename: 'file 2'}, {filename: 'file 3'}, {filename: ''}, {filename: 'subfile 1'}, {filename: 'subfile 2'}, {filename: 'subfile 3'}, {filename: ''}, {filename: 'subfile 4'}, {filename: 'subfile 5'}];
  docTree;
  lister;
  params;
  test1;
  test2:any = [];
  pleasework = [];
  s3Data:any = [];
  s3Data1:any = [];
  s3Structure = [

  ]
  projectID: any;
  commonS3creds:any;
  s3:any
  
  constructor(private fireStore: AngularFirestore, 
              private service: AuthService,
              private http: HttpClient) {
                this.projectID = this.service.projectID
                this.getSetting()
                console.log(this.commonS3creds)
               }


  ngOnInit() {    
  }

  getSetting(){
    this.fireStore.doc('projects'+ '/' + 'projects'+ '/' + this.projectID + '/' + this.projectID + '/projectsetting/projectsetting').get().subscribe((items: any)=>{
      // console.log(items.data())
      let item = items.data()
      let s3 = new S3({
             accessKeyId: item.commonS3.accessKeyId,
             secretAccessKey: item.commonS3.secretAccessKey,
            //  region: item.s3setting.s3region
      })
      this.commonS3creds = item.commonS3
      this.s3 = s3
    })
  }


  addUser(name: string, profileImage: File): Observable<any> {
    var formData: any = new FormData();
    formData.append("name", name);
    formData.append("avatar", profileImage);
 
    return this.http.post('http://localhost:3000/upload', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }
 
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


//TowerName is fetched
  fetchData(towerName, siteId, year) {

    return this.fireStore.collection(towerName).doc(towerName).get();
  }

  //Bucket data is retireved  
  getS3() {

    // this.s3 = new S3(this.service.projectsetting['S3'])
    // console.log(this.service.projectsetting['S3']);
    
    console.log(this.s3);
    
    const params = {
      Bucket: "telstra-testing",
      Prefix: this.projectID + '/' + this.towerName + '/',
      StartAfter: this.projectID + '/'
    }

    this.data1 = this.s3.listObjectsV2(params, function (err, data) {
      // console.log(this.service.projectsetting['S3']);
      
      if (err) {
        console.log('ther was an error getting files:' + err);
        // return;        
      }
      if (data) {
        // console.log(data);
        
        this.test1 = data;
        return data;
      }
    })

    console.log(this.test1);
    
  }

  buildS3Structure(path){
    if (!this.test2.includes(path)) {   

      this.test2.push(path)
    }
    
    let path1 = path.split('/')[1]

    if(!this.s3Structure.includes(path1)){

      this.s3Structure.push(path1)
    }    
  }

  testUpload(tower){    
    for (let i = 0; i < this.test2.length; i++) {
      let path1 = this.test2[i].split('/')[1]    

        let path_ = this.test2[i].split('/')
        let path2 = this.test2[i].split('/')[2]
        let index = this.s3Structure.indexOf(path1)
        this.buildS3Data(tower, index , path1 , path2)     
    }    
  }  

  uploadPilot(tower, data , file){
    this.s3.upload({
      Bucket : "telstra-testing",
      Key: tower + '/' + data +'/' + file.name,
      Body: file
    },function (err, data) {
      this.fileuploading = false;
      if (err) {
        console.log(err, 'there was an error uploading your file');
      } else {
        console.log(data.Key+ ' uploaded successfully');  
      }        
      return true;
    })
  }

  //Pilot Directory
  pilotDirectory(tower,siteid,year,projectid,files){
    let file = files[0]
    console.log('pilot directory');    
    for (let i = 0; i < this.s3Structure.length; i++) {
      if(!this.s3Data.includes({name: this.s3Structure[i],images: {}}))
      this.s3Data.push({
        Name: this.s3Structure[i],
        Details: []
      })
      // this.uploadPilot(tower, this.s3Structure[i], file)
    }

    this.testUpload(tower)
    if(this.s3Data){ this.uploadDB(tower,siteid,year,projectid,)  }
    
  } 

  //Uploading to firestore

  buildS3Data(tower, i, d, file){
    this.s3Data[i].Details.push({
      Name: 'Image',
      Value: file,
      Option: 1,
      Image: ['https://telstra-testing.s3.ap-south-1.amazonaws.com/'+ tower + '/' + d+'/'+file ]
    })
  }

  uploadDB(tower,siteid,year,projectid,){
    console.log(this.s3Data);
    
    let d = {'General': this.s3Data}
    this.fireStore.doc('projects'+ '/' + 'projects'+ '/' + projectid + '/' + projectid + '/' + 'sitedata' + '/' + year + '/' + tower  + '/' + siteid).set({ 'Audit': d
  },{merge: true});
  }


  //Bucket data is organized
  async buildData() {
    this.existingFiles = []
    for (let i = 0; i < this.data1.response.data.Contents.length; i++) {
      let filePath = this.data1.response.data.Contents[i].Key;      
      let file1 = filePath.substring((filePath.indexOf('/',filePath.indexOf('/')+1)+1), filePath.length)
      this.existingFiles.push({ filename: file1, subDir: (file1.split('/').length -1) })

    }
    this.data2 = this.existingFiles;   
    
  }

  //Object is generated for File Directory structure
  generateObj() {
    this.docTree = []
    let f2 = -1;
    let num;
    
    if (this.data2) {
      let file = []
      let subFolder = [];           
      let subFolder1 = []; let subFolder1_1 = [];   
      let itterationCount = 0;      
      // console.log(this.data2);
      
      this.docTree.push(
        {
          name: this.towerName,
          type: 'folder',
          children: []
        })

        if (this.data2.length > 1) {
          let fcount = [true];
          // console.log(this.data2.length);
          let fc= 0
          let fc1 =1;
          for(itterationCount = 0; itterationCount < 2; itterationCount++){

            console.log('it run', itterationCount, 'times');
            

          for (let i = 0; i < this.data2.length; i++) {
            if(this.data2[i].filename != '')
            {
            if (this.data2[i].subDir == 0 && !file.includes(this.data2[i].filename) && this.data2[i].filename.split('.')[1] !== 'txt') {
              this.docTree[0].children.push({
                name: this.data2[i].filename,
                type: 'file'
              })
              file.push(this.data2[i].filename)
              f2++;
              
            } 
            if (this.data2[i].subDir == 1 && itterationCount == 0) { 
            console.log(this.data2[i]);
              
            let f1 = 0;            
            let name1 = this.data2[i].filename.substring(0, this.data2[i].filename.indexOf('/'))
            // console.log(name1);
            
            let n1= 0;
            let n2= 0; 
          
            if(name1){
              if(!subFolder.includes(name1)){subFolder.push(name1)}
              // console.log(subFolder);         
              for(let nc=i; nc< this.data2.length; nc++){             
                if(this.data2[nc]){
                  let name2 = this.data2[nc].filename.substring(0, this.data2[nc].filename.indexOf('/'))           
                  if(name2 == name1)
                  {n1++}
                  if(name2 != name1){n2++} }
              }
            }
            if(fc < subFolder.length){
              // console.log('this is f1', fc );
             this.docTree[f1].children.push({
              name:  name1,
              type: 'folder',
              children: []
            })
            // console.log(name1);
            
            fc++;
            f2++;
            for(let fc1 = 0; fc1< n1; fc1++){
              if(this.data2[i+fc1].subDir == 1 && itterationCount == 0){
              let name2 = this.data2[i+fc1].filename.split('/')              
           
              
              this.docTree[0].children[f2].children.push({
                 name: name2[1],
                 type: 'file',
                 dir: name2[0],
              })
            }
            }        
          }
          }    
          if (this.data2[i].subDir == 2 && itterationCount == 1) {
            let f1 = 0;            
            let name1 = this.data2[i].filename.substring(0, this.data2[i].filename.indexOf('/'))
            let name1_0 = this.data2[i].filename.substring((this.data2[i].filename.indexOf('/')+1), this.data2[i].length);
            let name1_1 = name1_0.substring(0,name1_0.indexOf('/'))
            let name1_2 = name1_0.substring((name1_0.indexOf('/')+1), name1_0.length);
            let folIndex = this.docTree[f1].children.findIndex( f => f.name == name1);   
            let n1= 0;let n2= 0; 
              if(!subFolder1_1.includes(name1_1)){
             this.docTree[f1].children[folIndex].children.push({
              name:  name1_1,
              type: 'folder',
              children: []
            })
          }
            fc++;
            f2++;
          if(name1_1){          
             let folIndex_f = this.docTree[f1].children[folIndex].children.findIndex( f => f.name == name1_1);
             this.docTree[f1].children[folIndex].children[folIndex_f].children.push({
               name: name1_2,
               type: 'file',

             })
        }

//Creates an array with folder names ans sub-folder names
            if(name1){
              if(!subFolder1.includes(name1)){subFolder1.push(name1)}
              if(!subFolder1_1.includes(name1_1)){subFolder1_1.push(name1_1)}
              console.log(subFolder1_1);         
              for(let nc=i; nc< this.data2.length; nc++){             
                if(this.data2[nc]){
                  this.data2[nc]
                  let name2 = this.data2[nc].filename.substring(0, this.data2[nc].filename.indexOf('/'))           
                  if(name2 == name1)
                  console.log(name2);
                  
                  {n1++}

                  if(name2 != name1){n2++} }
              }
            }
          }               
        }
        }  
        console.log('another ride');
      }      
      
      }
    }
    // console.log(this.docTree);
    
    return this.docTree;
  }

  getImageUrl(img){

    console.log(this.data2);
    
    // const params1 = {
    //   Bucket: "telstra-testing",
    //   Key: this.towerName + '/' + img,
    // }

    // let imgURL = this.s3.getSignedUrl('getObject', params1)
    // console.log(img);
    
    let imgURL = 'https://telstra-testing.s3.ap-south-1.amazonaws.com/' + this.projectID + '/' + this.towerName + '/' + img;
    
    console.log(imgURL);
    return imgURL
    


  }


}
