
<h5 class="contentHeading" style="margin-bottom: 0px;">Site Approval </h5>

<div>
  <div class="contentRow" ><p>Status</p><p *ngIf="summary">{{summary.SiteApproval.Status}}</p><p *ngIf="role === 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('SiteApproval','Status')"> edit</mat-icon></i></p></div>
  <div class="contentRow"><p>Name</p><p *ngIf="summary">{{summary.SiteApproval.Name}}</p><p *ngIf="role === 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('SiteApproval','Name')"> edit</mat-icon></i></p></div>
  <div class="contentRow"><p>Comment</p><p *ngIf="summary">{{summary.SiteApproval.Comment}}</p><p *ngIf="role === 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('SiteApproval','Comment')"> edit</mat-icon></i></p></div>
  <div class="contentRow"><p>Date</p><p *ngIf="summary">{{summary.SiteApproval.Date}}</p><p *ngIf="role === 'telstra-processing'"><i><mat-icon class="editIcon" (click)="editData('SiteApproval','Date')"> edit</mat-icon></i></p></div>
</div>

