import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-shelter-modal',
  templateUrl: './shelter-modal.component.html',
  styleUrls: ['./shelter-modal.component.css']
})
export class ShelterModalComponent implements OnInit {

  link ;

  constructor(public dialogRef: MatDialogRef<ShelterModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,public sanitizer: DomSanitizer) {
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(data.link);
    console.log(this.link);
    
    }

  ngOnInit(): void {
  }


  close() {
    this.dialogRef.close();
  }

}
