import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
  value : string;
  name: string;
  leg: string;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  eqLeg;
  value;
  valueArray = [];
  name;
  status;
  option;
  imageCount = 1;
  constructor(public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
      this.eqLeg = this.data.leg;
      if(this.data.value == null) this.data.value = '';
      if(this.data.value.length >= 0){
        this.imageCount = this.data.value.length;
        if(typeof(this.data.value) == 'object'){
          this.valueArray = this.data.value;
        }
      }else{
        this.valueArray = []
      }
      if(typeof(this.data.value) == 'string'){
         this.valueArray = [];
        this.value = this.data.value;
      }
      this.name = this.data.name;
     }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  numSequence(n: number): Array<number> {
  //  this.value = [];
    return Array(n);
  }
  editFn(){
  let result ;

  if(this.name == 'Value'){
    if(this.value == ''){
      this.option = 1;
    }
    result = {value : this.value ,  option :  this.option};
  }
  else if(this.name == 'Image'){
     if(this.valueArray.length > this.imageCount){
     while(this.imageCount != this.valueArray.length){
         this.valueArray.pop();
     }
   }
  // this.valueArray = this.valueArray.filter((elt)=>{
  //   return (elt== '') ? false : true;
  // })
    result = {value : this.valueArray ,  option :  this.option};
  }else{
    result = {value : this.value , option : null}
  }
  return result;
  }

}
