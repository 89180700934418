import { Component, OnInit, Input } from '@angular/core';
import { join } from 'path';
import { MapFetchService } from '../../map/map-fetch.service';

@Component({
  selector: 'app-inspection-details',
  templateUrl: './inspection-details.component.html',
  styleUrls: ['./inspection-details.component.css']
})
export class InspectionDetailsComponent implements OnInit {

  @Input() data;
  summary_page_connections;
  sumArray = [];
  constructor(private service: MapFetchService) { }

  ngOnInit(): void {
    console.log(this.data);
    
    this.service.fetchProjectSetting().valueChanges().subscribe(items=> {
      
      this.summary_page_connections = items['summary_page_connections']
      console.log(this.summary_page_connections);
      this.summaryArray()
    })
    console.log(this.data);
    
  }
  summaryArray(){
   
    console.log(Number(this.summary_page_connections[0]));
    for (let i = 0; i < this.summary_page_connections.length; i++) {
      let d = this.summary_page_connections[i].split(",");
      for (let i = 0; i < d.length; i++) {
        d[i] = (Number(d[i]) - 1)       
      }
      this.sumArray.push(d)  
    }
      
     console.log(this.sumArray);
     console.log(this.data[this.sumArray[0][0]].Details[this.sumArray[0][1]].Value);
     
     console.log(this.data[this.sumArray[7][0]].Details[this.sumArray[7][1]].Value);
     
  }

}
