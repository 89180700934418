import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private fireStore : AngularFirestore, private service: AuthService) { }

projectId = this.service.projectID
  fetchUsers(){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/users').get();
   }


   setData(uid){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/users/').doc(uid);
    }



}
