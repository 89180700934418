import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavbarFetchService } from './navbar-fetch.service'
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from './../../auth.service';
import { SearchComponent } from './../search/search.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  result: Object = {};
  tower;
  siteId;
  towerName;
  tempTowerName;
  logourl: any = ''
  selectedTower;
  tag: any = ''
  isLoaded: Boolean = false;
  isIHS: Boolean = false;
  isUS: Boolean = false;
  isHor: Boolean = false;
  isAIS: Boolean = false;
  @Input() main;
  @Input() currentRoute;  
  @Input() crntYear = 0;
  @Input() data = [];
  projectID: any;
  siteCode: any = 'N/A';
  siteInspected: any = 'N/A';
  logoStyles = {
    'max-width': '94px',
    'height': '25px'
  }

  @ViewChild('searchBox') searchBox;
  role = null;
  year;
  value;
  constructor(private snackBar: MatSnackBar, private service: NavbarFetchService, private router: Router, private route: ActivatedRoute, private authService: AuthService, private dialog: MatDialog) {
    
    this.isIHS = this.authService.projectID == environment.ihs
    this.isUS = this.authService.projectID == environment.us
    this.isHor = this.authService.projectID == environment.hor
    this.isAIS = this.authService.projectID == environment.ais
    this.getProjectLogo()
    this.getData()
   
   
   }  //private route: ActivatedRoute

  ngOnInit(): void {
    this.projectID = this.authService.projectID
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }
    if (!this.main) {
      // this.route.params.subscribe(params => { this.year = params.year; });
      this.route.params.subscribe(params => {
        let tw =params.tower.split('___');      
        this.towerName = tw[0]
        this.siteId= tw[1];
        this.year = params.year;
        if (this.towerName) {
          this.service.fetchData(this.towerName, this.siteId, this.year).forEach(data => {
            let temp = [];
            data.forEach(doc => {
              temp.push(doc.data());
            })
            this.result = temp[0].Audit["General"];
            this.getSiteInspected()
            console.log(this.result);
          }).then(() => {
            this.isLoaded = true;
          })
        }
      })
      this.service.fetchTowers().subscribe(
        (res) => {
          res.docs.forEach(elt => {
            this.data.push(elt.data())
          });
        },
        (err) => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
        });
    }
  }

  ngAfterViewInit() {
    console.log(this.data)
  }

  getSiteCode(){
    try{
    if(this.result[1]['Details'][1].Value)
    {
      this.siteCode = this.result[1]['Details'][1].Value
    }
    this.siteCode = 'N/A'
  }
  catch(err){
    this.siteCode = 'N/A'
  }
  }

  getSiteInspected(){
    try{
      if(this.result[1]['Details'][6].Value)
      {
        this.siteInspected = this.result[1]['Details'][6].Value
      }
      else{
        this.siteInspected = 'N/A'
      }
    }
    catch(err){
      this.siteInspected = 'N/A'
    }
  }

  logout() {
    this.authService.SignOut();
  }

  inspectedTower(event) {
    console.log(event);
    let data = event.target.value.split("-")
    let towerName = data[0].replaceAll(' ', '')
    let siteID = data[1].replaceAll(' ', '')
    this.router.navigate([this.role + '/' + this.data[this.crntYear].Year + '/' + towerName+ "___" + siteID  + '/summary']);
  }

  uninspectedTower(event) {
    console.log('event.target.value- ', event.target.value)

    let data = event.target.value.split("-")
    let towerName = data[0].replaceAll(' ', '')
    let siteID = data[1].replaceAll(' ', '')

    this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + towerName+ "___" + siteID  + '/upload']);
  }

  stopEvent(event) {
    if (event.which == 13) {
      event.preventDefault();
    }
  }


  search(event) {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '450px',
      maxHeight: '480px',
      data: { value: this.data, year: this.year }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.value = result;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if (this.value) {
          console.log(this.value)
          this.router.navigate(['/' + this.role + '/' + this.year + '/' + this.value.towerName + "___" + this.value.siteID + '/summary']);
        }
      })
  }

  async getProjectLogo(){
    let val = await this.service.fetchLogo().toPromise()
    let settingData: any = val.data()
    this.logourl = settingData['logosetting'].logourl
    this.logoStyles['max-width'] = settingData['logosetting'].logowidth
    this.logoStyles['height'] = settingData['logosetting'].logoheight
    this.tag = settingData['logosetting'].projecttag
  }

  getData(){
    this.route.params.subscribe(params => {
      console.log(params);
      let tw = params.tower.split('___');
      this.towerName = tw[0]
      this.siteCode = tw[1]
      this.year = params.year;
    });
  }

}
