import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-site-verification',
  templateUrl: './site-verification.component.html',
  styleUrls: ['./site-verification.component.css']
})
export class SiteVerificationComponent implements OnInit {
bool;
  @Input() summary;

 @Output() editEvent = new EventEmitter();
  constructor() {
    const user = JSON.parse(localStorage.getItem('user'));
     if(user.email == "david.young@aerodyne.group") {
      this.bool = true;
    }

  }

  ngOnInit(): void {
  }

  editData(section,field){
    let value;
    if(this.summary){
      value =  this.summary[section][field]
    }else{
      value = 'N/A';
    }
    this.editEvent.emit({section,field,value});
  }

}
