import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrthomapFetchService {
  projectId;
  
  constructor(private fireStore : AngularFirestore, private service: AuthService) { 
    this.projectId = this.service.projectID
  }
      
  fetchData(towerName, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+  '/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).doc(siteId).get();
  } 

}
