import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocsFetchService {

  projectId: any;
  constructor(private fireStore : AngularFirestore,private service: AuthService,private route: ActivatedRoute) {
    this.projectId = this.service.projectID
   }

  fetchData(towerName,year){
    let tower = towerName.split('___'); 
    // return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId + '/' + this.projectId + '/ ' + 'sitedata' + '/' + year + '/' +towerName);
  return this.fireStore.doc('projects'+ '/' + 'projects'+ '/' + this.projectId + '/' + this.projectId + '/' + 'sitedata' + '/' + year + '/' + towerName + '/' + tower[1]);
}


setData(towerName,year){
  let tower = towerName.split('___'); 
  return this.fireStore.doc('projects'+ '/' + 'projects'+ '/' + this.projectId + '/' + this.projectId + '/' + 'sitedata' + '/' + year + '/' + towerName + '/' + tower[1]);
}

}
