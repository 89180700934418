import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData {
}
@Component({
  selector: 'app-tower',
  templateUrl: './tower.component.html',
  styleUrls: ['./tower.component.css']
})
export class TowerComponent implements OnInit {
  Tower;
  SiteID;
CanradVariation;
Completed;
Corrosion;
Latitude;
Longitude;
Region;
Status;
StructureType;
Type;

Emergency = 0;
Monitor = 5;
Planned = 0;
Urgent = 0;
  constructor(public dialogRef: MatDialogRef<TowerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addTowerFn(){
    let towerData = {
      Tower:  this.Tower,
      SiteID: this.SiteID,
      CanradVariation: this.CanradVariation,
      Completed: this.Completed,
      Corrosion: this.Corrosion,
      Latitude: this.Latitude,
      Longitude: this.Longitude,
      Region: this.Region,
      Status: this.Status,
      StructureType: this.StructureType,
      Type: this.Type,
      Emergency: this.Emergency,
      Monitor: this.Monitor,
      Planned: this.Planned,
      Urgent: this.Urgent
    }
    let towerDetails = {
         DigitalTwin: { Link: "" },
         Docs: [],
         Los: [],
         OrthoMap: {},
         TopDown: [],
         TowerView: [],
         Audit: {
           General:  [
            {
              Name: "INSPECTION DETAILS",
              Details: [
                {
                  Name: "Company Name",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Company Address",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #1 Name",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #1 Mobile",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #1 Cert No",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #1 Certification Date",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #2 Name",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #2 Mobile",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #2 Cert No",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Inspector #2 Certification Date",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "SITE INFORMATION",
              Details: [
                {
                  Name: "Site Name",
                  Value: this.Tower,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Site Code",
                  Value: this.SiteID,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Site Status",
                  Value: this.Status,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Location / Address",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Date of Site Visit",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Date of Analysis",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "GPS (GDA/WGS)",
                  Value: this.Latitude + " " + this.Longitude,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "GPS Zone",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Easting",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Northing",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Tower Type",
                  Value: this.Type,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Tower Height",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "SITE ACCESS DETAILS",
              Details: [
                {
                  Name: "Site Phone No",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Key No",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "National Park Y/N",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "National Park - Name",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "National Park - Contact Name",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "National Park - Contact Number",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Indigenous Land Owner Y/N",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Indigenous Land Owner - Contact Name",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Indigenous Land Owner - Phone Number",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Risk Assessment Completed",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Additional - Location, Site or Contact Comments",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "LOCATION & SURROUNDS",
              Details: [
                {
                  Name: "Trees on & around site, and average height (m)",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Buildings on & around site, and average height (m)",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Soil Type in general area of site",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Topography - Site Exposure",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Topography - Site located on:",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Terrain or Topography Comments",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "STRUCTURE SUMMARY",
              Details: [
                {
                  Name: "Structure No.",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Structure Type",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Manufacturer & Type",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Access Restriction Type",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Height",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Ext'n Height",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Owner",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Status -",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Foundation",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Head Frame No.",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Type of Head Frame",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "STRUCTURE DETAILS",
              Details: [
                {
                  Name: "Width of structure at the base or at the HRP ?",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Total number of sections",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Tower top face width",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Tower bottom face width",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Straight Section Height",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Tower Base Dimension Width or Diameter",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Any identifying member markings ?",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Structure identification plate (may identify Contract/Schedule No.) ?",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Footing type and relevant dimensions ?",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Any other identifying markings ?",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "FOUNDATIONS",
              Details: [
                {
                  Name: "Central Foundation",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Guy Block Foundation",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Soil Subsidence around foundations",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Entry point of steel into footing",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "EARTHING",
              Details: [
                {
                  Name: "Condition of earth connection above ground",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Cable pit / lid damaged or missing",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "STRUCTURE",
              Details: [
                {
                  Name: "Verticality / Twist",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Guy wire",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Guy termination",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Guy tensioning steelwork",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Guy tensions recorded",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Structure Body condition",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Ladder condition",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Platform condition",
                  Value: null,
                  Option: 1,
                  Image: [],
                },

                {
                  Name: "Leg & Brace Members",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Gantry - Vertical & Horizontal runway",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Signage - ' RF '  &  ' Do Not Climb '",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "LADSAFE System",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Lightning Arrestor",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Anti - climbing devices",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "PAINT",
              Details: [
                {
                  Name: "Painting Type",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Lead paint",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Paint Condition",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Paint Colour",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "NESTING BIRDS",
              Details: [
                {
                  Name: "Occupant 1",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Occupant 2",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "ANTENNA & FEEDER",
              Details: [
                {
                  Name: "Antenna installed correctly & secure",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Feeders secure - clamps intact etc.",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Connectors weatherproofed",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Feeders earthed",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Waveguide Leaks",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "LIGHTING & GPO's",
              Details: [
                {
                  Name: "Electrical cables secure & in good condition",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Lighting lens covers intact",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Conduits, Junction Boxes secure & intact",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "GPO's secure & intact",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "AVIATION / OBSTRUCTION LIGHTING",
              Details: [
                {
                  Name: "Light No. & Structure No.",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Height",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Face",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Ext'n",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Position",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "GENERIC - Batten / Lens Type",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Wattage",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Voltage",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Comments",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "EME  (status at site)",
              Details: [
                {
                  Name: "Data",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "ISSUES REPORT  (Structure / External Plant)",
              Details: [
                {
                  Name: "Issue #1",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #2",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #3",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #4",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #5",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "SITE INSPECTION CHECKLIST",
              Details: [
                {
                  Name: "Site Access",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Site Fencing",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Gates & Locks",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Warning signs on fences & buildings",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Compound - weeds, regrowth, fire hazard",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Compound - soil subsidence",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Building security",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Water tanks & stands",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Site toilet",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Rubbish",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Underground cable / earth pits & manholes",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Evidence of vehicles or livestock near structure",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Other",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
            {
              Name: "ISSUES REPORT  (Site Maintenance)",
              Details: [
                {
                  Name: "Issue #1",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #2",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #3",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #4",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
                {
                  Name: "Issue #5",
                  Value: null,
                  Option: 1,
                  Image: [],
                },
              ],
            },
          ],
           Equipment: [],
         },
         Summary:{
          SiteApproval:{
            Comment:"N/A",
            Date:"N/A",
            Name:"N/A",
            Status:"N/A"
          },
          SiteVerification:{
            Comment:"N/A",
            Date:"N/A",
            Name:"N/A",
            Status:"N/A"
          }
         }
       }
  return {TowersData : towerData, Tower : towerDetails};
  }
}
