<div class="wrapper">
	<header>
		<mat-icon class="circle_button" (click)="goBack()" aria-hidden="false">arrow_back</mat-icon><h1>OrthoMap Image Crop Processing</h1>
	</header>
	
	<div class="sections">
	  
	  <section class="active">
		<div >
			<h1>{{file.name}}</h1>
		</div>
	  </section>

	  <div *ngIf="myResImage">
		<img style="cursor:pointer" [src]="myResImage" width="100%" height="100%" alt="" #img (click)="getCordinate($event,img)"/>
		<div>
		<label><h1>X Coordinate</h1></label>
		<input type="number" placeholder="XCoordinate" [(ngModel)]= "xCoodinate" id="title"/>
		<label><h1>Y Coordinate</h1></label>
		<input type="number" placeholder="YCordinate" [(ngModel)]= "yCoodinate" id="title"/>
		<label><h1>Radius</h1></label>
		<input type="number" placeholder="Radius" [(ngModel)]= "radius" id="title"/>
	    </div>
	  </div>
	
	</div>
	
	<footer>
	  <div *ngIf="myResImage">
	  <ul [style.display]="prs">
		<li><span id="send" (click)="OnSUpload()">Start Processing</span></li>
	  </ul>
	  </div>
	  <br>
	  <div [style.display]="prg">
	  <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
	  <div ><h1>Image being processed...</h1></div>
	  </div>
	  <div [style.display]="processdis">
		<div ><h1>Image being processed...</h1></div>
		<mat-progress-bar mode="buffer"></mat-progress-bar>
	  </div>
	  <div [style.visibility]="dis"><h1>Image processing completed</h1></div>
	</footer> 
	
  </div>
  <div class="notification"></div>