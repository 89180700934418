import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ThreedviewFetchService } from './threedview-fetch.service';

@Component({
  selector: 'app-threedview',
  templateUrl: './threedview.component.html',
  styleUrls: ['./threedview.component.css']
})
export class ThreedviewComponent implements OnInit {
  
  role;
  Link;
  urlSafe;
  year;
  towerName;
  siteId;

  constructor(private service: ThreedviewFetchService,private route: ActivatedRoute,private router: Router,public sanitizer: DomSanitizer) { 
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }

    this.route.params.subscribe(params => {
      console.log(params);
      let tw =params.tower.split('___');      
      this.towerName = tw[0]
      this.siteId= tw[1];
      this.year = params.year;
      console.log(this.year);
    });
  }

  ngOnInit(): void {
    this.getDBdata()
  }

  getDBdata(){
    this.service.fetchData(this.towerName,this.siteId,this.year).subscribe(
      (response) => {
        let data = response.data();
        if(data.hasOwnProperty('3dview'))
        {
          this.Link = data['3dview']['Link'];
          console.log(' this.Link 00 - ', this.Link);
          if (this.Link && this.Link != "") {
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.Link);
          }
        }
    })
  }

}
