import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.css']
})
export class GallerySliderComponent implements OnInit {

  @Input() imageData = [];
  @Input() crntDir = 0;
  @Input() index = 0;
  @Input() sliderIndex;
  @ViewChild("slider") slider;
  count = 0;

  wheelOffset = -100;
  scrollSpeed = 400;

  @Output() sliderEvent = new EventEmitter<any>();

  @Output() contentLoaded = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.slider){
      //this.selectImage();
      this.setSlider();
    }
  }

setSlider(){
  if(this.imageData.length > 0){
    let crntImage = this.slider.nativeElement.querySelector("#thumb_" + this.index);

    if(crntImage){
      crntImage.scrollIntoView({
        behavior: 'smooth',
        inline: 'center'
      });
      this.sliderIndex = false;
      this.sliderEvent.emit({index:  this.index, sliderIndex : this.sliderIndex});
    }
  }
}


  ngAfterViewInit() {
  }

  handleScroll(event) {
    if (event.deltaY === this.wheelOffset) {
      this.slider.nativeElement.scrollLeft += this.scrollSpeed;
    } else {
      this.slider.nativeElement.scrollLeft -= this.scrollSpeed;
    }
  }


  clickImage(index) {
    this.index = parseInt(index);
    this.sliderIndex = false;
    this.sliderEvent.emit({index:  this.index, sliderIndex : this.sliderIndex});
   // this.selectImage();
  }

  // selectImage() {
  //   if(this.imageData.length > 0){
  //   let prevImage = this.slider.nativeElement.querySelector(".selected");
  //   if(prevImage){
  //     prevImage.removeAttribute("class");
  //   }
  //   let crntImage = this.slider.nativeElement.querySelector("#thumb_" + this.index);
  //   if(crntImage){
  //     crntImage.setAttribute("class", "selected");
  //   }
  // }
  // }

}
