import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SummaryFetchService } from '../summary-fetch.service';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})


export class CategoriesComponent implements OnInit {
@Input() towerName;
@Input() siteId;
@Input() data  = [];
@Input() year;
role;
projectID: any;
  constructor(private router : Router,private service: SummaryFetchService,private summaryService: SummaryFetchService) { 
    this.projectID = service.projectId
  }

  ngOnInit(): void {
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }
  }
eltfn(elt){
  let arr = 0;
  if(elt == "all"){

    if(this.data["General"] != ""){
      this.data["General"].forEach(element => {
        arr += element.Details.filter(this.all).length;
      });
    }
    if(this.data["Equipment"] != ""){
    this.data["Equipment"].forEach(element => {
      arr += element.Details.filter(this.all).length;
    });
  }
  }
  if(elt == "monitor"){
    if(this.data["General"] != ""){
    this.data["General"].forEach(element => {
      arr += element.Details.filter(this.monitor).length;
    });
  }
    if(this.data["Equipment"] != ""){
    this.data["Equipment"].forEach(element => {
      arr += element.Details.filter(this.monitor).length;
    });
  }
  }
  if(elt == "planned"){
    if(this.data["General"] != ""){
    this.data["General"].forEach(element => {
      arr += element.Details.filter(this.planned).length;
    });
  }
  if(this.data["Equipment"] != ""){
    this.data["Equipment"].forEach(element => {
      arr += element.Details.filter(this.planned).length;
    });
  }
  }
  if(elt == "urgent"){
    if(this.data["General"] != ""){
    this.data["General"].forEach(element => {
      arr += element.Details.filter(this.urgent).length;
    });
  }
  if(this.data["Equipment"] != ""){
    this.data["Equipment"].forEach(element => {
      arr += element.Details.filter(this.urgent).length;
    });
  }
  }
  if(elt == "emergency"){
    if(this.data["General"] != ""){
    this.data["General"].forEach(element => {
      arr += element.Details.filter(this.emergency).length;
    });
  }
  if(this.data["Equipment"] != ""){
    this.data["Equipment"].forEach(element => {
      arr += element.Details.filter(this.emergency).length;
    });
  }
  }
  return arr;
}

  all(record){
    return record.Option;
  }

  monitor(record) {
    return (record.Option === 1 && record.Value != null);
  }
  planned(record) {
    return (record.Option === 2 && record.Value != null);
  }
  urgent(record) {
    return (record.Option === 3 && record.Value != null);
  }
  emergency(record) {
    return (record.Option === 4 && record.Value != null);
  }


  viewAudit(selected){

    this.summaryService.routedFromSummary = true
    this.summaryService.summaryCategory = selected
    this.router.navigate([this.role + '/'+this.year + '/' + this.towerName + '___' + this.siteId + '/audit'])

    // let val = 0;
    // if(selected == 'Emergency') val = this.eltfn("emergency");
    // if(selected == 'Planned') val = this.eltfn("planned");
    // if(selected == 'Monitor') val = this.eltfn("monitor");
    // if(selected == 'Urgent') val = this.eltfn("urgent");
    // if(val != 0){
  //     console.log(this.role+'/'+this.year+'/'+this.towerName+'/audit')
  // this.router.navigate([this.role+'/'+this.year+'/'+this.towerName+'/audit',{selected : selected}]);

    // }
}



}
