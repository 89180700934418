import { Component, OnInit, Input, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuditDetailsComponent, printObjStatus } from '../audit-details/audit-details.component';

@Component({
  selector: 'app-dialog-pdf',
  templateUrl: './dialog-pdf.component.html',
  styleUrls: ['./dialog-pdf.component.css']
})
export class DialogPdfComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DialogPdfComponent>, @Inject(MAT_DIALOG_DATA) public data: AuditDetailsComponent) {
    dialogRef.disableClose= true;
   }

  ngOnInit(): void {
  }

}
