<!-- <button mat-raised-button (click) = "showTree = !showTree ;">show tree</button> -->
<div class="container-fluid" style="width: 100vw;">

<!-- <div class="row d-flex justify-content-between">
  <div class="col" class="fileDirectory flex-shrink-1">
    <div *ngIf = showTree>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <button mat-icon-button disabled></button>
          <mat-icon id="fileN" class="type-icon" [attr.aria-label]="node.type + 'icon'" (click)="showFile(node.name)">
            {{ node.type === 'file' ? 'description' : 'folder' }}
          </mat-icon>
          <label for="fileN" (click)="showFile(node.name)">{{node.name}}</label>
          
        </mat-tree-node>
        
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
          {{ node.type ==='file' ? 'description' : 'folder' }}
        </mat-icon>
        <label for="fileN" matTreeNodeToggle>{{node.name}}</label>
      </mat-tree-node>
    </mat-tree>
    </div>
  </div>
  <div class="col" class="fileView w-100">

    <img [src]= "imgURL" alt="" srcset="">

  </div>
</div> -->

<div class="d-flex">
  <mat-icon (click)="reload()" class="reload">cached</mat-icon>
  <div class="p-2 flex-shrink-5 bd-highlight"> 
    <div class="col" class="fileDirectory">
    <div *ngIf = showTree style="width:30em">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [matTreeNodePaddingIndent]="10">
          <button mat-icon-button disabled></button>
          <mat-icon id="fileN" class="type-icon" [attr.aria-label]="node.type + 'icon'" (click)="showFile(node.name)">
            {{ node.type === 'file' ? 'description' : 'folder' }}
          </mat-icon>
          <label for="fileN" (click)="showFile(node.name)">{{node.name}}</label>
          
        </mat-tree-node>
        
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="10">
          <button mat-icon-button matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
          {{ node.type ==='file' ? 'description' : 'folder' }}
        </mat-icon>
        <label for="fileN" matTreeNodeToggle>{{node.name}}</label>
      </mat-tree-node>
    </mat-tree>
    </div>
  </div>

  </div>
  <div class="p-2 me-3 w-100 bd-highlight">
    <div class="col" class="fileView w-100">

      <img [src]= "imgURL" alt="" srcset="">
  
    </div>
  </div>
</div>
  
</div>