import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData {
  value : string;
  name: string;
  structure: string;  //did this to show "Please add comma separated values" for deviation table
}
@Component({
  selector: 'app-add-details',
  templateUrl: './add-details.component.html',
  styleUrls: ['./add-details.component.css']
})
export class AddDetailsComponent implements OnInit {
  item;
  description;
  issue;
  structure;
  constructor(public dialogRef: MatDialogRef<AddDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
      this.structure = this.data?.structure
      
     }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resultFn(){
if(this.issue == null || this.issue == undefined){
  this.issue = 1;
}
return {data: {Name : this.item,Value : this.description,Option : this.issue,Image: []}};
  }

}
