<div class="navBar">
    <app-navbar [main]="false"></app-navbar>
</div>
<div class="threedview">
    <div class="threedviewTab">
        <ng-container *ngIf="Link != null && Link != ''">
            <iframe style="width: 100%;height: 100%;" [src]="urlSafe"></iframe>
          </ng-container>
    </div>
</div>

