import { Component, OnInit, Input, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RiggerAuditDetailsComponent, printObjStatus } from '../rigger-audit-details/rigger-audit-details.component';


@Component({
  selector: 'app-rigger-dialog-pdf',
  templateUrl: './rigger-dialog-pdf.component.html',
  styleUrls: ['./rigger-dialog-pdf.component.css']
})
export class RiggerDialogPdfComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RiggerDialogPdfComponent>, @Inject(MAT_DIALOG_DATA) public data: RiggerAuditDetailsComponent) {
    dialogRef.disableClose= true;
   }

  ngOnInit(): void {
  }

}
