
<h5 class="contentHeading">Inspection Details </h5>
<div>

  <div class="contentRow"><p>Site Name</p><p>{{ data[sumArray[0][0]].Details[sumArray[0][1]].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>Site Code</p><p>{{ data[sumArray[1][0]].Details[sumArray[1][1]].Value || 'N/A'}}</p></div>
  <div class="contentRow" ><p>Site Status</p><p>{{ data[sumArray[2][0]].Details[sumArray[2][1]].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>Facility Address</p><p>{{ data[sumArray[3][0]].Details[sumArray[3][1]].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>Audit Date</p><p>{{ data[sumArray[4][0]].Details[sumArray[4][1]].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>GPS Latitude, Longitude</p><p>{{ data[sumArray[5][0]].Details[sumArray[5][1]].Value.split(" ").length > 1 ? (data[sumArray[5][0]].Details[sumArray[5][1]].Value?.replace(" ",',')|| 'N/A') : (data[sumArray[5][0]].Details[sumArray[5][1]].Value + ',' + data[sumArray[6][0]].Details[sumArray[6][1]].Value)}}</p></div>
  <div class="contentRow"><p>Tower Type</p><p>{{ data[sumArray[7][0]].Details[sumArray[7][1]].Value || 'N/A' }}</p></div>
  <div class="contentRow"><p>Tower Height</p><p>{{ data[sumArray[8][0]].Details[sumArray[8][1]].Value || 'N/A' }}</p></div>
</div>

