<div (click)='uploading && openDialog()'>

  <div class="navBar"  [ngClass]="{ 'unClickableDiv': uploading}">
    <app-navbar [main]="false"></app-navbar>
  </div>
</div>

<div >
<div class="summary" [ngClass]="{ 'unClickableDiv': uploading}">
 
    <!-- <input type="file" (change)="uploadFolders($event.target.files)" accept="image/*" webkitdirectory directory multiple> -->
    <div class="file-manager-wrapper">
      <div class="file-manager-nav" >
        <button mat-button class="ant-btn ant-btn-block" style="width: 130px; height: 40px; margin-top: 10px; margin-left: 10px; display: inline-block;" (click)="reload()"><mat-icon  class="reload" style="color: #fff;margin-top: -5px; margin-left: 10px;" [ngClass]="roll">cached</mat-icon>Reload</button>
        <div class="col" class="fileDirectory">
          <div *ngIf = showTree style="width:100%;">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [matTreeNodePaddingIndent]="10">
                <button mat-icon-button disabled></button>
                <mat-icon id="fileN" class="type-icon" [attr.aria-label]="node.type + 'icon'" (click)="showFile(node.name,node.dir,$event)" [ngStyle]="node.type === 'file' ? {'color':'rgb(0 123 255)'} : {'color':'#ffd767'}" >
                  {{ node.type === 'file' ? 'description' : 'folder' }}
                </mat-icon>
                <label for="fileN" (click)="showFile(node.name,node.dir,$event)" style="cursor:pointer;font-weight: 600;color: #1356cb">{{node.name}}</label>
                
              </mat-tree-node>
              
              <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="10">
                <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'" [ngStyle]="node.type === 'file' ? {'color':'rgb(0 123 255)'} : {'color':'#ffd767'}">
                {{ node.type ==='file' ? 'description' : 'folder' }}
              </mat-icon>
              <label for="fileN" matTreeNodeToggle style="cursor:pointer;" style="cursor:pointer;font-weight: 600;color: #1356cb">{{node.name}}</label>
            </mat-tree-node>
          </mat-tree>
          </div>
        </div>
      </div>
      <div class="file-manager-content">
        <div class="file-manager-content-header">
          <div style="position:relative; width: 100%;height: 100%;margin-right: 1rem;" *ngIf="showImageTitle">
            <h3 style="color: rgb(0 123 255);z-index: 1;position:absolute;left:45%;font-weight: 600;top: 30%;">{{ displayImageName }} </h3>
          </div>
          <button type="button" class="ant-btn ant-btn-block" [ngStyle]="{'margin-left':'auto'}" (click)="openUploadDialog()"><mat-icon>cloud_upload</mat-icon><span class="m-l-10" style="font-weight:400;font-size:20px;">Upload</span></button>
        </div>
        <div class="file-manager-content-body">
          <div class="col" class="fileView w-100" style="position:relative;">
            <div [ngStyle]="{'visibility':showLoading==true ? 'visible' : 'hidden'}"><mat-progress-spinner mode="indeterminate" ></mat-progress-spinner></div>
            <img *ngIf="imgURL" class="display_img" [src]= "imgURL" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
</div>

</div>
