<div class="table-holder" style="min-height:100%;max-height:auto">
      <div class="title-holder"><mat-icon class="circle_button" (click)="goBack()" aria-hidden="false">arrow_back</mat-icon><h1 class="list-title">Edit OrthoMap</h1>
      <mat-form-field style="margin-right:10px;">
        <input matInput (keyup)="applyFilter($event)" placeholder="Search by asset">
      </mat-form-field>
      <mat-form-field style="margin-right:10px;">
        <input matInput placeholder="Go to page number" [(ngModel)]="pageNumber" (keyup)="goToPage()">
      </mat-form-field>
    </div>
    <div class="sections" >
	  
      <section class="active" [ngClass]="upDisplay">
      <div >
        <h1>{{file.name}}</h1>
      </div>
      <div class="images" [style.display]="fileUpload" >
        <div class="pic" (click)="fileInput.click()">
          Upload Tif File OR Select any file listed in the Table
        </div>
        <input #fileInput type="file" (change)="onSelect($event)" accept="image/tif" style="display:none;" />
      </div>
      </section>
      <div [style.display]="prg">
        <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
        <div ><h1>Image being uploaded...</h1></div>
      </div>
    </div>
    <div class="loader" *ngIf="isLoaded; else elseBlock"><mat-spinner></mat-spinner></div>
    <ng-template #elseBlock >
      <div [ngClass]="tbDisplay">
    <table mat-table matSort [dataSource]="dataSource" >
  
      <ng-container matColumnDef="index">
        <th class="header index-column" mat-header-cell *matHeaderCellDef>
          <span>Index</span>
        </th>
        <td mat-cell *matCellDef="let i = index">{{pagin.pageIndex * pagin.pageSize + i + 1 }}</td>
      </ng-container>
  
      <ng-container matColumnDef="project">
        <th class="header project-column" mat-header-cell *matHeaderCellDef>
          <span>Project</span>
        </th>
        <td mat-cell *matCellDef="let details">{{details.project}}</td>
      </ng-container>
      
      <ng-container matColumnDef="asset">
        <th class="header asset-column" mat-header-cell *matHeaderCellDef>
          <span>Asset / ID / Algos</span>
        </th>
        <td mat-cell *matCellDef="let details">{{details.asset}}</td>
      </ng-container>
  
      <ng-container matColumnDef="vertikaliti">
        <th class="header vertikaliti-column" mat-header-cell *matHeaderCellDef>
          <span>Vertikaliti</span>
        </th>
        <td mat-cell *matCellDef="let details">{{details.vertikaliti}}</td>
      </ng-container>
        
      <ng-container matColumnDef="dateCreated">
        <th class="header dateCreated-column" mat-header-cell *matHeaderCellDef>
          <span>Date Created</span>
        </th>
        <td mat-cell *matCellDef="let details">{{details.dateCreated}}</td>
      </ng-container>
  
      <ng-container matColumnDef="totalImage">
        <th class="header totalImage-column" mat-header-cell *matHeaderCellDef>
          <span>Total Image</span>
        </th>
        <td mat-cell *matCellDef="let details">{{getImagesCount(details.images)}}</td>
      </ng-container>
      
      <ng-container matColumnDef="status">
        <th class="header status-column" mat-header-cell *matHeaderCellDef>
          <span>Status</span>
        </th>
        <td mat-cell *matCellDef="let details">{{details.status}}</td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row (click)="getTiffFile(details)" *matRowDef="let details; columns: columnsToDisplay"></tr>
  
    </table>
    <mat-paginator #pagin
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100,500]"
                aria-label="Select page">
    </mat-paginator>
  </div>
    </ng-template>
    </div>
  
