import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css']
})

export class TenantsComponent implements OnInit {

  @Output() editEvent = new EventEmitter();
  @Input() data  = [];

  constructor() { }

  ngOnInit(): void {
  }

 reducer(a,b){
   return a+b;
 }



 RF(record){
   return record.Name.startsWith("RF") === true;
 }
  MW(record){
  return record.Name.startsWith("MW") === true;
}
RRU(record){
  return record.Name.startsWith("RRU") === true;
}

}
