import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  value: string;
  name: string;
}
@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.css']
})
export class AddSectionComponent implements OnInit {
  sectionName = '';
  section = '';

  leg = 'Leg 1';
  equipment = "RF";
  equipmentName = null;
  result = {};

  // Pavan Edited
  routerEndsWithRigger = this.router.url.endsWith('riggeraudit');


  pole = '';
  TFCStructureMode = 'SizeandTowerFoundationBaseLevel';
  TFCStructureName = null;

  TowerVerticalityCheckStructure = 'SelfSupportingTower';
  TowerVerticalityCheckStructureName = '';

  TensionCheckLevel = '';
  TensionCheckStructureName = null;

  LoadingonTowerEquipment = 'Antenna';
  LoadingonTowerEquipmentName = '';
  LoadingonTowerLevelName = '';

  // Pavan Edited
  
  mulResults = [

  ]

  mode = 'Normal';
  rf = ['Equipment Reference',
    'Equipment Placement',
    'Equipment Type',
    'Equipment Leg',
    'Mount Type',
    'Carrier',
    'Dimension H(m)xW(m)xD(m)',
    'Height from ground (m)',
    'Azimuth °',
    'Equipment damage visible?',
    'Feeder damage visible?'
  ];
  rru = [
    'Equipment Reference',
    'Equipment Placement',
    'Equipment Type',
    'Equipment Location',
    'Dimension H(m)xW(m)xD(m)',
    'Height from ground Equipment (m)'
  ];
  mw = [
    'Equipment Reference',
    'Equipment Placement',
    'Equipment Type',
    'Carrier',
    'Diameter (m)',
    'Height from ground (m)',
    'Azimuth °',
    'Equipment damage visible?',
    'Feeder damage visible?'
  ];
  coaxRouting = [
    'Equipment Reference',
    'Equipment Placement',
    'Equipment Type',
    'Coax Numbering',
    'Size/Specs',
    'Manufacturer',
    'Model',
    'Carrier',
    'Antenna' 
  ];
  waveGuide = [
    'Equipment Reference',
    'Equipment Placement',
    'Equipment Type',
    'Location',
    'Rung Size',
    'Runner Size',
    'Ladder Width',
    'Spacing',
    'Start/Stop Height',
    'Condition'
  ];
  custom = [
    'Equipment Reference',
    'Equipment Placement',
    'Equipment Type',
  ];

  rfDetails = [];
  rruDetails = [];
  mwDetails = [];
  coaxRoutingDetails = [];
  waveGuideDetails = [];
  customDetails = [];

  cv = [
    'Class',
    'Type',
    'Antenna No',
    'Structure No',
    'Polarity',
    'Height (m)',
    'Owner',
    'Status',
    'CANRAD Correct'
  ]

  cr = [
    'Description of Corroded Component',
    'Quantity',
    'Severity',
    'Percentage of Surface Area Affected by Corrosion',
    'Additional Comments'
  ]

  cvDetails = [];
  crDetails = [];

  // Pavan Edited
//Tower Foundation, Structure type = Tower
  tfblCenter = [
    'Foundation base size',
    'Width (m)',
    'Length (m)',
    'Height from ground (m)',
    'Stub/Base plate',
    'Diff level (mm)'
  ]

  //Tower Foundation, Structure type = Pole 1, Pole 2, Pole 3, Pole 4
  tfbl = [
    'Foundation base size',
    'Width (m)',
    'Length (m)',
    'Height from ground (m)',
    'Condition',
    'Grounding',
    'Anchor bolt (qty)',
    'Anchor bolt',
    'Anchor frame'
  ]

  tsDistance = [
    'A-B (mm)',
    'B-C (mm)',
    'C-D (mm)',
    'D-A (mm)',
    'Site Layout'
  ]

  tdDistance = [
    'A-C',
    'B-D'
  ]

  tlDistance = [
    'Ct-A',
    'Ct-B',
    'A-B',
    'Site Layout'
  ]

  tfblCenterDetails = [];
  tfblDetails = [];
  tsDistanceDetails = [];
  tdDistanceDetails = [];
  tlDistanceDetails = [];

  selfSupportingTower = [
    'Allowable deviation = 87.5 mm',
    '5',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35'
  ]

  steelPole = [
    'Pole height (m)',
    'Pole diameter (inch)',
    'Point 1 measurement (°)',
    'Point 2 measurement (°)',
    'Allowable deviation (90° ±)',
    'Site layout'
  ]

  selfSupportingTowerDetails = [];
  steelPoleDetails = [];

  tensionCheck = [
    'Height (m)',
    'Left Tension',
    'Right Tension',
    'Guy Distance from Tower (m) (L)',
    'Guy Distance from Tower (m) (R)'
  ]

  tensionCheckDetails = [];

  loadingOnTowerEquipment = [
    'Mounting Frame (Qty by Level)',
    'Cellular (Qty by Level)',
    'Omni (Qty by Level)',
    'Parabolic Ø 1.2 m',
    'Parabolic Ø 2.4 m',
    'Parabolic Ø 3.0 m',
    'Grid Parabolic Ø 1.8 m',
    'RRU',
    'Installation Height by Level (m)',
    // 'Area Antenna',
    // 'Design Antenna Load on Tower',
    // 'Inspection Result'
  ]

  loadingOnTowerEquipment_1 = [
    'Area Antenna',
    'Design Antenna Load on Tower',
  ]

  loadingOnTowerru = [
    'Antenna Type1',
    'Model Type1',
    'Dimension H(m)xW(m)xD(m)1',
    'Height from Ground (m)1',
    'Quantity1',
    'Total Area Antenna1',
    'Antenna Type2',
    'Model Type2',
    'Dimension H(m)xW(m)xD(m)2',
    'Height from Ground (m)2',
    'Quantity2',
    'Total Area Antenna2',
    'Antenna Type3',
    'Model Type3',
    'Dimension H(m)xW(m)xD(m)3',
    'Height from Ground (m)3',
    'Quantity3',
    'Total Area Antenna3',
    'Antenna Type4',
    'Model Type4',
    'Dimension H(m)xW(m)xD(m)4',
    'Height from Ground (m)4',
    'Quantity4',
    'Total Area Antenna4',
    'Grand Total Area0'
  ]

  loadingOnTowerEquipmentDetails = [];
  loadingOnTowerEquipment_1Details = [];
  loadingOnTowerruDetails = [];

  // a = [
  //   'Company Name',
  //   'PM Date',
  //   'Location ID',
  //   'Location Code',
  //   'Name',
  //   'Province',
  //   'Region',
  //   'MC Zone',
  //   'Latitude, Longitude',
  //   'House Number',
  //   'Street',
  //   'Village / Moo',
  //   'Sub-district',
  //   'District',
  //   'Article No.',
  //   'Phase',
  //   'On service date',
  //   'On service year',
  //   'Article Description',
  //   'Tower',
  //   'Job ID',
  //   'Height (m)',
  //   'Type',
  //   'Tower Manufacturer',
  //   'Manufacture date',
  //   'Tower Installation company',
  //   'Equipment Room',
  //   'Size (Width x Length) m',
  //   'Size of Site (m)',
  //   'Width of fence'
  // ]

  // b = [
  //   'Location ID',
  //   'Location Code',
  //   'Name',
  //   'Province',
  //   'Region',
  //   'MC Zone',
  //   'Article Description',
  //   'Article No',
  //   'Phase',
  //   'On service date',
  //   'On service year'
  // ]

  // c = [
  //   'Check by',
  //   'Check date',
  //   'Job ID',
  //   'Tower',
  //   'Height (m)',
  //   'Type',
  //   'Manufacture by',
  //   'Manufacture date',
  //   'Latitude, Longitude',
  //   'House Number',
  //   'Street',
  //   'Village / Moo',
  //   'Sub-district',
  //   'District',
  //   'Province',
  // ]

  // d = [
  //   'Filled soil around the tower without subsidence',
  //   'Concrete base without subsidence',
  //   'Concrete base in good condition no crack',
  //   'Color of Tower base',
  // ]

  // e = [
  //   'Check for signs of damage from use, twisting damaged by the storm',
  //   'Structural steel fully installed',
  //   'Structural steel no damage, bending',
  //   'Bolt & Nut fully installed',
  //   'No loosening bolt & nut at main leg',
  //   'No loosening bolt & nut at other part',
  //   'Check the condition of the ladder climbing the antenna',
  //   'Check the condition of the platform',
  //   'Check the condition of vertical Feeder Rack',
  //   'Structure Galvanize surface no rust or peel',
  //   'Check condition of tower color',
  //   'Check the condition of Guyed Bracket (for Guy Mast tower)',
  //   'Check Torque arm (for Guy Mast Tower)',
  // ]

  // f = [
  //   'Check condition of foundation - Horizontal Feeder Rack',
  //   'Check condition of structure - Horizontal Feeder Rack',
  //   'Check condition of Mounting Frame',
  //   'Check condition of Mounting MW Antenna',
  //   'Check condition of Feeder Clamp',
  //   'Check conditon of Name Plate',
  // ]

  // g = [
  //   'Light bulb OB Light',
  //   'Lamp OB Light',
  //   'Electric Wire',
  //   'Electrical wire clamp material',
  //   'Electrical connection point',
  //   'Photo Cell',
  //   'Condition of Control OB Light Cabinet',
  //   'Working system of control OB Light Cabinet'
  // ]

  // h = [
  //   'The condition of the lightning rod',
  //   'Upper Ground Bar',
  //   'Lower Ground Bar',
  //   'Ground at tower leg or Anchor',
  //   'Ground at antenna ladder or center mast',
  //   'The value of ground that can measure'
  // ]

  // i = [
  //   'Leg distance or Anchor',
  //   'Leg level, Anchor Level',
  //   'Antenna`s verticality in the standard 1/400 (Max.Error..(mm))'
  // ]

  // j = [
  //   'The entrance easy to go in-out, No obstacles',
  //   'Check the access road condition'
  // ]

  // k = [
  //   'Door gate easy to open and close',
  //   'The gate can be closed easily',
  //   'The gate has a key lock',
  //   'Fence is Strong fence, no damage, no hole',
  //   'No rust on fence',
  //   'Barbed wire not damaged',
  //   'Barbed wire no rust',
  //   'Fence lamp is in good condition, no ants inside',
  //   'Check all fence lamp is in good condtion',
  //   'Check working status of Photo switch',
  //   'Check ground conditions inside the site',
  //   'Check the condition of the concrete floor inside the site',
  //   'Check there are no weeds inside the site',
  //   'Check cleanliness, no garbage,inside the site'
  // ]

  // ad = [];
  // bd = [];
  // cd = [];
  // dd = [];
  // ed = [];
  // fd = [];
  // gd = [];
  // hd = [];
  // id = [];
  // jd = [];
  // kd = [];

  // Pavan Edited

  constructor(private router: Router, public dialogRef: MatDialogRef<AddSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    for (let i = 0; i < this.rf.length; i++) {
      this.rfDetails.push({
        "Name": this.rf[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.mw.length; i++) {
      this.mwDetails.push({
        "Name": this.mw[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }


    for (let i = 0; i < this.rru.length; i++) {
      this.rruDetails.push({
        "Name": this.rru[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.custom.length; i++) {
      this.customDetails.push({
        "Name": this.custom[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.cv.length; i++) {
      this.cvDetails.push({
        "Name": this.cv[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.cr.length; i++) {
      this.crDetails.push({
        "Name": this.cr[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }


    for (let i = 0; i < this.coaxRouting.length; i++) {
      this.coaxRoutingDetails.push({
        "Name": this.coaxRouting[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.waveGuide.length; i++) {
      this.waveGuideDetails.push({
        "Name": this.waveGuide[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    // Pavan Edited

    for (let i = 0; i < this.tfblCenter.length; i++){
      this.tfblCenterDetails.push({
        "Name": this.tfblCenter[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.tfbl.length; i++){
      this.tfblDetails.push({
        "Name": this.tfbl[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.tsDistance.length; i++){
      this.tsDistanceDetails.push({
        "Name": this.tsDistance[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.tdDistance.length; i++){
      this.tdDistanceDetails.push({
        "Name": this.tdDistance[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.tlDistance.length; i++){
      this.tlDistanceDetails.push({
        "Name": this.tlDistance[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.selfSupportingTower.length; i++){
      this.selfSupportingTowerDetails.push({
        "Name": this.selfSupportingTower[i],
        "Value": "",
        "Option": "",
        "Image": [],
        // "DVAL": "-",
        // "DVAR": "-",
        // "DVBL": "-",
        // "DVBR": "-"
      })
    }

    for (let i = 0; i < this.steelPole.length; i++){
      this.steelPoleDetails.push({
        "Name": this.steelPole[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.tensionCheck.length; i++){
      this.tensionCheckDetails.push({
        "Name": this.tensionCheck[i],
        "Value": "",
        "Option":"",
        "Image": []
      })
    }

    for (let i = 0; i < this.loadingOnTowerEquipment.length; i++){
      this.loadingOnTowerEquipmentDetails.push({
        "Name": this.loadingOnTowerEquipment[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.loadingOnTowerEquipment_1.length; i++){
      this.loadingOnTowerEquipment_1Details.push({
        "Name": this.loadingOnTowerEquipment_1[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    for (let i = 0; i < this.loadingOnTowerru.length; i++){
      this.loadingOnTowerruDetails.push({
        "Name": this.loadingOnTowerru[i],
        "Value": "",
        "Option": "",
        "Image": []
      })
    }

    //remove

    // for (let i = 0; i < this.a.length; i++){
    //   this.ad.push({
    //     "Name": this.a[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.b.length; i++){
    //   this.bd.push({
    //     "Name": this.b[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.c.length; i++){
    //   this.cd.push({
    //     "Name": this.c[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.d.length; i++){
    //   this.dd.push({
    //     "Name": this.d[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.e.length; i++){
    //   this.ed.push({
    //     "Name": this.e[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.f.length; i++){
    //   this.fd.push({
    //     "Name": this.f[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.g.length; i++){
    //   this.gd.push({
    //     "Name": this.g[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.h.length; i++){
    //   this.hd.push({
    //     "Name": this.h[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let j = 0; j < this.i.length; j++){
    //   this.id.push({
    //     "Name": this.i[j],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.j.length; i++){
    //   this.jd.push({
    //     "Name": this.j[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    // for (let i = 0; i < this.k.length; i++){
    //   this.kd.push({
    //     "Name": this.k[i],
    //     "Value": "",
    //     "Option": "",
    //     "Image": []
    //   })
    // }

    //remove

    // Pavan Edited

  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  resultFn() {
    this.mulResults = []
    if (this.section == "Equipment") {
      this.result["Name"] = this.equipmentName;
      this.result["Leg"] = this.leg;
      this.result["Section"] = this.section
      if (this.equipment == 'RF') {
        this.result["Details"] = this.rfDetails;
      }
      if (this.equipment == 'RRU') {
        this.result["Details"] = this.rruDetails;
      }
      if (this.equipment == 'MW') {
        this.result["Details"] = this.mwDetails;
      }
      if (this.equipment == 'Custom') {
        this.result["Details"] = this.customDetails;
      }
      if (this.equipment == 'COAX ROUTING') {
        this.result["Details"] = this.coaxRoutingDetails;
      }
      if (this.equipment == 'WAVE GUIDE') {
        this.result["Details"] = this.waveGuideDetails;
      }
      this.result["Details"][1].Value = this.leg;
      this.result["Details"][1].Option = 1;
      this.result["Details"][0].Value = this.equipmentName;
      this.result["Details"][0].Option = 1;
      return { data: this.result, section: this.section};
    }

    if (this.section == "General") {
      this.result["Section"] = this.section

      // this.result["Name"] = this.sectionName
      // if(this.sectionName == "INSPECTION DETAILS"){
      //   this.result["Details"] = this.ad
      // }
      // if(this.sectionName == "SITE INFORMATION DATA"){
      //   this.result["Details"] = this.bd
      // }
      // if(this.sectionName == "SITE SURVEY DATA"){
      //   this.result["Details"] = this.cd
      // }
      // if(this.sectionName == "BASE CONCRETE FOUNDATION INSPECTION"){
      //   this.result["Details"] = this.dd
      // }
      // if(this.sectionName == "TOWER STRUCTURE INSPECTION"){
      //   this.result["Details"] = this.ed
      // }
      // if(this.sectionName == "CHECK EQUIPMENT ON TOWER"){
      //   this.result["Details"] = this.fd
      // }
      // if(this.sectionName == "CHECK OB LIGHT SYSTEM"){
      //   this.result["Details"] = this.gd
      // }
      // if(this.sectionName == "CHECK GROUND AND LIGHTNING PROTECTION SYSTEM"){
      //   this.result["Details"] = this.hd
      // }
      // if(this.sectionName == "CHECK VERTICAL DISTANCE (BY MEASURE)"){
      //   this.result["Details"] = this.id
      // }
      // if(this.sectionName == "CHECK THE SITE ENTRANCE (ACCESS ROAD)"){
      //   this.result["Details"] = this.jd
      // }
      // if(this.sectionName == "FENCE GATE AND INTERNAL SITE CONDITION"){
      //   this.result["Details"] = this.kd
      // }

      // return { data: this.result, section: this.section};

      if (this.mode == "Normal") {
        this.result["Name"] = this.sectionName;
        this.result["Details"] = [];
        return { data: this.result, section: this.section};
      }
      if (this.mode == "CanradVariations") {
            this.result["Name"] = this.sectionName;
            this.result["Details"] = this.cvDetails;
            return { data: this.result, section: this.section};
      }
      if (this.mode == "CorrosionReport") {
        this.result["Name"] = this.sectionName;
        this.result["Details"] = this.crDetails;
        return { data: this.result, section: this.section};
      }


    }

    // Pavan Edited

    if (this.section == "TowerFoundationCheck"){
      this.result["Name"] = this.TFCStructureName
      this.result["Pole"] = this.pole
      this.result["StructureMode"] = this.TFCStructureMode
      this.result["Section"] = this.section
      
      if (this.TFCStructureMode == 'SizeandTowerFoundationBaseLevel'){
        if (this.pole === 'Tower'){
          this.result["Details"] = this.tfblCenterDetails
        }
        else{
          this.result["Details"] = this.tfblDetails
        }
      }

      if (this.TFCStructureMode == 'TowerDistance'){

        if (this.TFCStructureName == 'SIDE DIAGONAL'){
          this.result["Details"] = this.tsDistanceDetails
        }
        else if (this.TFCStructureName == 'DIAGONAL DISTANCE'){
          this.result["Details"] = this.tdDistanceDetails
        }
        else if (this.TFCStructureName == 'TOWER LEG DISTANCE'){
          this.result["Details"] = this.tlDistanceDetails
        }

      }
      return { data: this.result, section: this.section};
    }

    if (this.section == "TowerVerticalityCheck"){
      this.result["Name"] = this.TowerVerticalityCheckStructureName
      this.result["Structure"] = this.TowerVerticalityCheckStructure
      this.result["Section"] = this.section

      if (this.TowerVerticalityCheckStructure !== 'SteelPole'){
        this.result["Details"] = this.selfSupportingTowerDetails
      }
      if (this.TowerVerticalityCheckStructure === 'SteelPole'){
        this.result["Details"] = this.steelPoleDetails
      }

      return {data: this.result, section: this.section}
    }

    if (this.section == "TensionCheck"){
      this.result["Name"] = this.TensionCheckStructureName
      this.result["Level"] =  this.TensionCheckLevel
      this.result["Details"] = this.tensionCheckDetails
      this.result["Section"] = this.section

      return {data: this.result, section: this.section}
    }

    if(this.section == "LoadingonTower"){
      this.result["Name"] = this.LoadingonTowerEquipmentName.toUpperCase() == 'EQUIPMENT' ? 'EQUIPMENT' : this.LoadingonTowerEquipmentName
      this.result["EquipmentLoading"] = this.LoadingonTowerEquipment
      this.result["Level"] = this.LoadingonTowerLevelName
      this.result["Section"] = this.section


      if (this.LoadingonTowerEquipmentName.toUpperCase() == "EQUIPMENT"){
        this.result["ELDetails"] = this.loadingOnTowerEquipmentDetails
        this.result["Details"] = this.loadingOnTowerEquipment_1Details

        return {data: this.result, section: this.section}
      }
      else{
        this.result["Details"] = this.loadingOnTowerruDetails

        return {data: this.result, section: this.section}
      }
    }
    
  }

}
