import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-spin-controls',
  templateUrl: './spin-controls.component.html',
  styleUrls: ['./spin-controls.component.css']
})
export class SpinControlsComponent implements OnInit {

  @Input() view;
  @Input() payload;
  @Input() crntDir;
  @Input() index;
  @Input() sliderIndex;
  @Input() loading;
  @Input() s3Uri;


@Output() spinControlsEvent = new EventEmitter<any>();
@Output() contentLoaded = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }
  sendEvent(event){
   this.spinControlsEvent.emit(event);
  }

}
