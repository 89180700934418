<h1 mat-dialog-title>Edit</h1>
<div mat-dialog-content>
  <!-- <h3>{{name}}</h3> -->
  <p>{{data.value}}</p>
  <ng-container>
    <mat-form-field *ngFor="let elt of equipmentData;let i = index">
      <mat-label >{{elt.Name}}</mat-label>
      <input matInput [(ngModel)]="equipmentDataTemp[i].Value">
    </mat-form-field>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="editFn()" cdkFocusInitial class="submit">Ok</button>
</div>