import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-telstra-admin',
  templateUrl: './telstra-admin.component.html',
  styleUrls: ['./telstra-admin.component.css']
})
export class TelstraAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
