import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MapFetchService {
  constructor(private fireStore : AngularFirestore, private service: AuthService) { }

projectId = this.service.projectID
fetchData(){
 return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' +this.projectId + '/' +this.projectId+ '/towers').get();
}

fetchProjectSetting(){
 return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' +this.projectId + '/' +this.projectId+ '/projectsetting').doc('projectsetting')
}

}
