<div class="navBar">
    <app-navbar [main]="false"></app-navbar>
</div>

<div class="audit">
    <div class="auditTabs one">
        <h3 class="auditHeading">Rigger Audit Findings
            <button class="download">
            <mat-icon inline="true">download</mat-icon>
            <b>Download Antenna & Dish</b>
            </button>
        </h3>
        <div class="auditContent">
            <div class="contentSection">
                <h2 class="sectionHeading">Categories</h2>
                <div *ngIf="isLoaded" class="contentContainer">
                    <app-rigger-audit-categories [selected]="selected" [role]="role" (deleteSectionEvent)="deleteSection($event)" (addSectionEvent)="addSection($event)" (detailsEvent)="getDetails($event)" [data]="result" #appChild></app-rigger-audit-categories>
                </div>
            </div>
            <div class="contentSection">
                <h2 class="sectionHeading">Details</h2>
                <div *ngIf="isLoaded" class="contentContainer">
                  <app-rigger-audit-details (imageEvent)="setImage($event)" [role]="role" [item]="item" (addDeleteEvent)="addDeleteData($event)" [data]="result" (editEvent)="editData($event)"></app-rigger-audit-details>
                </div>
            </div>
        </div>
    </div>
    <div class="auditTabs two">
        <h3 class="auditHeading">Visual Evidence </h3>
        <div class="imageContent">
          <app-audit-image [imageArray]="imageArray" *ngIf="isLoaded"></app-audit-image>
        </div>
    
      </div>
</div> 