<div class="file-manager-wrapper">
  <button type="button" class="cls-btn" (click)="closeDialog()">X</button>
    <div class="file-manager-nav" >
        <div class="ngx-dropzonee" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
            <span class="choose" id="choose" (click)="file.click()">
               <b style="color: #0053ad;">Select</b> or Drag & Drop your Folder here!
            </span>
             <input #file 
             [disabled]="isUploading"
              type="file"
              accept="image/*"
              webkitdirectory
              directory
              multiple                  
              (change)="uploadFolders($event.target.files)"
              style="display:none" />
    </div>
  </div>
    <div class="file-manager-content">
        <div class="file-manager-content-body">
            <div id="Uploading" 
            [ngStyle]="{visibility: uploadedHeadVis,padding:'3px'}" 
            [ngClass]="{ 'clickableDiv': uploading}"
            >
              <h3>Uploaded: {{ totalPrg.currUploadNumber }} / {{ totalPrg.totalNumberOfFiles }}</h3>
              <div style="position:relative;">
                <p style="color: white;z-index: 1;position:absolute;right:40%;">{{ totalPrg.percentUpload }} %</p>
                <mat-progress-bar mode="determinate" value="{{totalPrg.percentUpload}}" style="height:20px;border-radius:5px;"></mat-progress-bar>
              </div>
              <p style="margin:12px 0 0 0;font-weight:600;color:#656d8b">Currently uploading files</p>
              <div  *ngFor="let f of tempUploadList;index as i" class="" style="position: relative;">
                <div class="container-fluid pt-2 uploaded-files" style="padding:0">
                  <div class="">
                    <div class="">
                        <p class="file-items" style="position:relative">
                            <mat-icon [ngStyle]="{'color':'#656d8b','position':'absolute'}">drive_folder_upload</mat-icon>
                            <span style="margin-left:30px;font-weight:600;color:#276db9">{{f.filename}} uploaded as {{tempUploadList1[i]}}</span></p>
                        </div>
                    <div class="" *ngIf="!f.status; else statusBlock">
                      <mat-progress-bar 
                      mode="determinate" 
                      value="{{f.progress}}"
                      class="mat-bar">
                    </mat-progress-bar>
                  </div>
                  <ng-template #statusBlock>
                    <div>
                      <span style="font-weight:600;color:#197420">Finished Uploading</span>
                    </div>
                  </ng-template>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>