
<h5 class="contentHeading" style="margin-bottom: 0px;">Tenants </h5>
 <div class="contentRow"><p>Tenant</p><p>Antenna</p><p>Dish</p><p>RRU</p></div>
<div>
  <div class="contentRow" *ngIf="data.length">
    <p>Tenant</p>
    <p>{{ data.filter(RF).length }}</p>
    <p>{{ data.filter(MW).length }}</p>
    <p>{{ data.filter(RRU).length }}</p>
  </div>
  <!-- <div class="contentRow">
    <p>Total</p>
    <p>{{ data.Antenna.reduce(reducer) }}</p>
    <p>{{ data.Dish.reduce(reducer) }}</p>
    <p>{{ data.RRU.reduce(reducer) }}</p>
  </div> -->
 </div>
