import { Location } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-analyse',
  templateUrl: './analyse.component.html',
  styleUrls: ['./analyse.component.css']
})
export class AnalyseComponent implements OnInit {



//  ************************ ANTENNA DATA ******************************
ikeja_antennas = [
{
  name:'antenna 1',
  data:[{
      year:'2021',
      eqp_ref:'A1',
      eqp_place:'Leg 1',
      carrier:'C1',
      type : 'Panel',
      dimension : '1.34x0.26x0.09',
      height:'35.93',
      azimuth:'104',
      eqp_damage:'good',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2021/A1.JPG'
    },
    {
      year:'2022',
      eqp_ref:'A1',
      eqp_place:'Leg 1',
      carrier:'C1',
      type : 'Panel',
      dimension : '1.04x0.06x0.09',
      height:'35.90',
      azimuth:'98',
      eqp_damage:'Need to check azimuth difference too large',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2022/A1.JPG'
    }]
},
{
  name:'antenna 2',
  data:[{
      year:'2021',
      eqp_ref:'A2',
      eqp_place:'Leg 1',
      carrier:'C2',
      type : 'Panel',
      dimension : '1.33x0.28x0.15',
      height:'30.70',
      azimuth:'91',
      eqp_damage:'good',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2021/A2.JPG'
    },
    {
      year:'2022',
      eqp_ref:'A2',
      eqp_place:'Leg 1',
      carrier:'C2',
      type : 'Panel',
      dimension : '1.31x0.18x0.15',
      height:'30.65',
      azimuth:'89',
      eqp_damage:'good',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2022/A2.JPG'
    }]
},
{
  name:'antenna 3',
  data:[{
      year:'2021',
      eqp_ref:'A3',
      eqp_place:'Leg 1',
      carrier:'C3',
      type : 'Panel',
      dimension : '2.64x0.48x0.17',
      height:'30.91',
      azimuth:'126',
      eqp_damage:'good',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2021/A3.JPG'
    },
    {
      year:'2022',
      eqp_ref:'A3',
      eqp_place:'Leg 1',
      carrier:'C3',
      type : 'Panel',
      dimension : '2.50x0.38x0.15',
      height:'30.90',
      azimuth:'124',
      eqp_damage:'good',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2022/A3.JPG'
    }]
},
{
  name:'antenna 4',
  data:[{
      year:'2021',
      eqp_ref:'A4',
      eqp_place:'Leg 1',
      carrier:'C4',
      type : 'Panel',
      dimension : '1.34x0.30x0.07',
      height:'36.46',
      azimuth:'234',
      eqp_damage:'good',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2021/A4.JPG'
    },
    {
      year:'2022',
      eqp_ref:'A4',
      eqp_place:'Leg 1',
      carrier:'C4',
      type : 'Panel',
      dimension : '1.30x0.25x0.15',
      height:'36.46',
      azimuth:'220',
      eqp_damage:'Need to check azimuth difference too large',
      feeder_damage: 'good' ,
      img:'../../assets/images/IKEJAPLAZA/antenna/2022/A4.JPG'
    }]
}
];

maryland_antenna = [
  {
    name:'antenna 1',
    data:[{
        year:'2021',
        eqp_ref:'A1',
        eqp_place:'Leg 1',
        carrier:'C1',
        type : 'Panel',
        dimension : '1.34x0.30x0.11',
        height:'33.35',
        azimuth:'26',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2021/A1.JPG'
      },
      {
        year:'2022',
        eqp_ref:'A1',
        eqp_place:'Leg 1',
        carrier:'C1',
        type : 'Panel',
        dimension : '1.24x0.25x0.09',
        height:'33.32',
        azimuth:'24',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2022/A1.JPG'
      }]
  },
  {
    name:'antenna 2',
    data:[{
        year:'2021',
        eqp_ref:'A2',
        eqp_place:'Leg 1',
        carrier:'C2',
        type : 'Panel',
        dimension : '2.69x0.49x0.17',
        height:'29.90',
        azimuth:'1',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2021/A2.JPG'
      },
      {
        year:'2022',
        eqp_ref:'A2',
        eqp_place:'Leg 1',
        carrier:'C2',
        type : 'Panel',
        dimension : '2.66x0.25x0.17',
        height:'29.88',
        azimuth:'1',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2022/A2.JPG'
      }]
  },
  {
    name:'antenna 3',
    data:[{
        year:'2021',
        eqp_ref:'A3',
        eqp_place:'Leg 1',
        carrier:'C3',
        type : 'Panel',
        dimension : '1.23x0.26x0.09',
        height:'30.28',
        azimuth:'35',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2021/A3.JPG'
      },
      {
        year:'2022',
        eqp_ref:'A3',
        eqp_place:'Leg 1',
        carrier:'C3',
        type : 'Panel',
        dimension : '1x0.26x0.05',
        height:'30.24',
        azimuth:'32',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2022/A3.JPG'
      }]
  },
  {
    name:'antenna 4',
    data:[{
        year:'2021',
        eqp_ref:'A4',
        eqp_place:'Leg 1',
        carrier:'C4',
        type : 'Panel',
        dimension : '1.35x0.29x0.08',
        height:'26.72',
        azimuth:'17',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2021/A4.JPG'
      },
      {
        year:'2022',
        eqp_ref:'A4',
        eqp_place:'Leg 1',
        carrier:'C4',
        type : 'Panel',
        dimension : '1.34x0.25x0.08',
        height:'26.70',
        azimuth:'16.5',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/antenna/2022/A4.JPG'
      }]
  }

];
// ******************** END OF TOWER DATA ********************************

// ******************  DISH DATA  ****************************************

ikeja_dishes =  [
  {
    name:'dish 1',
    data:[{
        year:'2021',
        eqp_ref:'D1',
        eqp_place:'Leg 1',
        carrier:'C5',
        type :'Dish',
        diameter : '0.23',
        height:'36.96',
        azimuth:'101',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2021/D1.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D1',
        eqp_place:'Leg 1',
        carrier:'C5',
        type : 'Dish',
        diameter: '0.20',
        height:'36.94',
        azimuth:'95',
        eqp_damage:'Need to check azimuth difference too large',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2022/D1.JPG'
      }]
  },
  {
    name:'dish 2',
    data:[{
        year:'2021',
        eqp_ref:'D2',
        eqp_place:'Leg 1',
        carrier:'C6',
        type :'Dish',
        diameter : '0.14',
        height:'37.37',
        azimuth:'46',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2021/D2.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D2',
        eqp_place:'Leg 1',
        carrier:'C6',
        type : 'Dish',
        diameter: '0.12',
        height:'37.36',
        azimuth:'37',
        eqp_damage:'Need to check azimuth difference too large',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2022/D2.JPG'
      }]
  },
  {
    name:'dish 3',
    data:[{
        year:'2021',
        eqp_ref:'D3',
        eqp_place:'Leg 1',
        carrier:'C7',
        type :'Dish',
        diameter : '0.67',
        height:'36.84',
        azimuth:'65',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2021/D3.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D3',
        eqp_place:'Leg 1',
        carrier:'C7',
        type : 'Dish',
        diameter: '0.56',
        height:'36.80',
        azimuth:'65',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2022/D3.JPG'
      }]
  },
  {
    name:'dish 4',
    data:[{
        year:'2021',
        eqp_ref:'D4',
        eqp_place:'Leg 1',
        carrier:'C8',
        type :'Dish',
        diameter : '0.63',
        height:'34.41',
        azimuth:'165',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2021/D4.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D4',
        eqp_place:'Leg 1',
        carrier:'C8',
        type : 'Dish',
        diameter: '0.61',
        height:'34.40',
        azimuth:'163',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/IKEJAPLAZA/dish/2022/D4.JPG'
      }]
  },
];

maryland_dishes = [
  {
    name:'dish 1',
    data:[{
        year:'2021',
        eqp_ref:'D1',
        eqp_place:'Leg 1',
        carrier:'C5',
        type :'Dish',
        diameter : '0.37',
        height:'31.14',
        azimuth:'215',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2021/D1.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D1',
        eqp_place:'Leg 1',
        carrier:'C5',
        type : 'Dish',
        diameter: '0.35',
        height:'31.12',
        azimuth:'211',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2022/D1.JPG'
      }]
  },
  {
    name:'dish 2',
    data:[{
        year:'2021',
        eqp_ref:'D2',
        eqp_place:'Leg 1',
        carrier:'C6',
        type :'Dish',
        diameter : '0.66',
        height:'27.00',
        azimuth:'180',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2021/D2.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D2',
        eqp_place:'Leg 1',
        carrier:'C6',
        type : 'Dish',
        diameter: '0.55',
        height:'26.99',
        azimuth:'179',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2022/D2.JPG'
      }]
  },
  {
    name:'dish 3',
    data:[{
        year:'2021',
        eqp_ref:'D3',
        eqp_place:'Leg 2',
        carrier:'C7',
        type :'Dish',
        diameter : '0.64',
        height:'24.72',
        azimuth:'178',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2021/D3.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D3',
        eqp_place:'Leg 2',
        carrier:'C7',
        type : 'Dish',
        diameter: '0.60',
        height:'24.70',
        azimuth:'177',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2022/D3.JPG'
      }]
  },
  {
    name:'dish 4',
    data:[{
        year:'2021',
        eqp_ref:'D4',
        eqp_place:'Leg 3',
        carrier:'C8',
        type :'Dish',
        diameter : '0.66',
        height:'36.18',
        azimuth:'286',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2021/D4.JPG'
      },
      {
        year:'2022',
        eqp_ref:'D4',
        eqp_place:'Leg 3',
        carrier:'C8',
        type : 'Dish',
        diameter: '0.66',
        height:'36.15',
        azimuth:'286',
        eqp_damage:'good',
        feeder_damage: 'good' ,
        img:'../../assets/images/MARYLAND/dish/2022/D4.JPG'
      }]
  },
];

//  ************************** END OF DISH DATA  ********************************

towers = [
  {
    name:'IKEJA PLAZA',
    code:'IHS_LAG_1016A',
    lat:'6.593055556',
    lon:'3.343055556',
    type:'Rooftop',
    // structure_height:'20.07',
    // overall_height:'22.21',
    sections:'4',
    // top_face_width:'1.40',
    // bottom_face_width:'2.12',
    // straight_section_height:'2.00',
    // Base_Dimension_Width_or_Diameter:'15.59',
    data:[
      {
        year:2021,
        structure_height:'20.07',
        overall_height:'22.21',
        top_face_width:'1.40',
        bottom_face_width:'2.12',
        straight_section_height:'2.00',
        Base_Dimension_Width_or_Diameter:'15.59'
      },
      {
        year:2022,
        structure_height:'20.00',
        overall_height:'22.20',
        top_face_width:'1.39',
        bottom_face_width:'2.11',
        straight_section_height:'2.00',
        Base_Dimension_Width_or_Diameter:'15.57'
      }
    ],
    structure_identification_plate:'No structure identification plate on tower',
    foundation_type:'Concrete',
    other_identifying_markings:'Not covered by this analysis',
    tower_imgs:[
      {
        year:2021,
        img:'../../assets/images/IKEJAPLAZA/tower/2021/TOWER.JPG'
      },
      {
        year:2022,
        img:'../../assets/images/IKEJAPLAZA/tower/2022/TOWER.JPG'
      }
    ],
    antenna_data:this.ikeja_antennas,
    dish_data:this.ikeja_dishes,
    grounding_data:{
      condition :"Not covered by this analysis",
      cable_pit_status:"Not covered by this analysis",
      ground_imgs:[
        {
          year:2021,
          img:'../../assets/images/IKEJAPLAZA/ground/2021/GROUND.JPG'
        },
        {
          year:2022,
          img:'../../assets/images/IKEJAPLAZA/ground/2022/GROUND.JPG'
        }
      ]
    }
  },
  {
    name:'MARYLAND',
    code:'IHS_LAG_3964A',
    lat:'6.574194444',
    lon:'3.365555556',
    type:'Self Supporting Tower (SST)',
    // structure_height:'39.94',
    // overall_height:'42.05',
    sections:'20',
    // top_face_width:'1.11',
    // bottom_face_width:'3.22',
    // straight_section_height:'17.51',
    // Base_Dimension_Width_or_Diameter:'15.59',
    data:[
      {
        year:2021,
        structure_height:'39.94',
        overall_height:'42.05',
        top_face_width:'1.11',
        bottom_face_width:'3.22',
        straight_section_height:'17.51',
        Base_Dimension_Width_or_Diameter:'15.59'
      },
      {
        year:2022,
        structure_height:'39.92',
        overall_height:'42.04',
        top_face_width:'1.10',
        bottom_face_width:'3.02',
        straight_section_height:'17.50',
        Base_Dimension_Width_or_Diameter:'15.57'
      }
    ],
    structure_identification_plate:'No structure identification plate on tower',
    foundation_type:'Concrete',
    other_identifying_markings:'Not covered by this analysis',
    tower_imgs:[
      {
        year:2021,
        img:'../../assets/images/MARYLAND/tower/2021/TOWER.JPG'
      },
      {
        year:2022,
        img:'../../assets/images/MARYLAND/tower/2022/TOWER.JPG'
      }
    ],
    antenna_data:this.maryland_antenna,
    dish_data:this.maryland_dishes,
    grounding_data:{
      condition :"Not covered by this analysis",
      cable_pit_status:"Not covered by this analysis",
      ground_imgs:[
        {
          year:2021,
          img:'../../assets/images/MARYLAND/ground/2021/GROUND.JPG'
        },
        {
          year:2022,
          img:'../../assets/images/MARYLAND/ground/2022/GROUND.JPG'
        }
      ]
    }
  }

]


value:any;
button:any;
ran:any;
temp:any;
selected_tower:any;
selected_object :any;
tower_image:any;
selected_val :any ;
slider_value :any;
ant_dis_data : any;
grounding_data :any;
tower_data :any;
  constructor(private _location: Location) { }

  ngOnInit(): void {
    this.ant_dis_data = document.querySelector('.tower-detail-container')
    this.grounding_data = document.querySelector(".grounding-data");
    this.tower_data = document.querySelector(".tower-profile-data");
    this.ant_dis_data.style.display = 'none';
    this.grounding_data.style.display = 'none';
    this.value =document.querySelectorAll('.value');
    console.log(this.value);
   this.selected_tower = this.towers[0];     // ikeja tower is set first
   console.log(this.selected_tower);
   this.selected_val = 'tower';
   this.slider_value = document.querySelector('.slider');
   this.tower_image = document.querySelector('.timage');

  //  ********************** SLIDER EVENT LISTENER *********************
   this.slider_value.addEventListener("change",()=>{
    let value = this.slider_value.value ;
     console.log(this.slider_value.value);
     console.log(this.selected_val);
  if(this.selected_val == 'tower')
  {
    let selected_year = this.selected_tower.tower_imgs.find(e => e.year == this.slider_value.value)
    console.log(selected_year);
    this.tower_image.src = selected_year.img;
   let tower_profile_value = document.querySelectorAll('.profile-value');
   console.log(tower_profile_value);
   console.log(this.selected_tower);

    let selected_data = this.selected_tower.data.find(e =>  e.year == this.slider_value.value);
  tower_profile_value[0].innerHTML = this.selected_tower.code;
  tower_profile_value[1].innerHTML = this.selected_tower.lat;
  tower_profile_value[2].innerHTML = this.selected_tower.lon;
  tower_profile_value[3].innerHTML = this.selected_tower.type;
  tower_profile_value[4].innerHTML = selected_data.structure_height;
  tower_profile_value[5].innerHTML = selected_data.overall_height;
  tower_profile_value[6].innerHTML = this.selected_tower.sections;
  tower_profile_value[7].innerHTML = selected_data.top_face_width;
  tower_profile_value[8].innerHTML = selected_data.bottom_face_width;
  tower_profile_value[9].innerHTML = selected_data.straight_section_height;
  tower_profile_value[10].innerHTML = selected_data.Base_Dimension_Width_or_Diameter;
  tower_profile_value[11].innerHTML = this.selected_tower.structure_identification_plate ;
  tower_profile_value[12].innerHTML = this.selected_tower.foundation_type ;
  tower_profile_value[13].innerHTML =  this.selected_tower.other_identifying_markings ;
  }

  if(this.selected_val == 'ground')
  {
    console.log("ground executed");

    let selected_year = this.selected_tower.grounding_data.ground_imgs.find(e => e.year == this.slider_value.value)
    console.log(selected_year);
    this.tower_image.src = selected_year.img;
  }

  if(this.selected_val == 'antenna' || this.selected_val == 'dish' )
  {
      let selected_year = this.selected_object.data.find((e)=>{
        return  e.year == value
    })
    this.tower_image.src = selected_year.img;
    console.log(this.selected_object.data[0].azimuth);
    console.log(selected_year.azimuth);
    let azimuth_compare = this.selected_object.data[0].azimuth - selected_year.azimuth;
    console.log(azimuth_compare);

   if(azimuth_compare > 5)
   {
    let azimuth_container :any = document.querySelector(".azimuth-container");
    azimuth_container.style.color = 'red';
    azimuth_container.style.backgroundColor = 'white';
    azimuth_container.style.border = '1px solid red';
    azimuth_container.children[1].style.color = 'red';
    // azimuth_compare = 0;
   }
   else
   {
   this.changeDefaultDetTheme();
  //  azimuth_compare = 0;
   }

    if (this.selected_val == 'antenna')
    {
      this.value[0].innerHTML = selected_year.eqp_ref;
      this.value[1].innerHTML = selected_year.eqp_place;
      this.value[2].innerHTML = selected_year.carrier;
      this.value[3].innerHTML = selected_year.type;
      this.value[4].innerHTML = selected_year.dimension;
      this.value[5].innerHTML = selected_year.height;
      this.value[6].innerHTML = selected_year.azimuth;
    //   console.log(this.selected_object.data[0].azimuth);
    //   console.log(selected_year.azimuth);
    //   let azimuth_compare = this.selected_object.data[0].azimuth - selected_year.azimuth;
    //   console.log(azimuth_compare);

    //  if(azimuth_compare > 5)
    //  {
    //   let azimuth_container :any = document.querySelector(".azimuth-container");
    //   azimuth_container.style.color = 'red';
    //   azimuth_container.style.backgroundColor = 'white';
    //   azimuth_container.style.border = '1px solid red';
    //   azimuth_container.children[1].style.color = 'red';
    //   // azimuth_compare = 0;
    //  }
    //  else
    //  {
    //  this.changeDefaultDetTheme();
    // //  azimuth_compare = 0;
    //  }
      this.value[7].innerHTML = selected_year.eqp_damage;
      this.value[8].innerHTML = selected_year.feeder_damage;
      this.needRepairAlert();

    }
  else if (this.selected_val == 'dish')
  {
    this.value[0].innerHTML = selected_year.eqp_ref;
    this.value[1].innerHTML = selected_year.eqp_place;
    this.value[2].innerHTML = selected_year.carrier;
    this.value[3].innerHTML = selected_year.type;
    this.value[4].innerHTML = selected_year.diameter;
    this.value[5].innerHTML = selected_year.height;
    this.value[6].innerHTML = selected_year.azimuth;
    this.value[7].innerHTML = selected_year.eqp_damage;
    this.value[8].innerHTML = selected_year.feeder_damage;
    this.needRepairAlert();
  }

}

   })

   this.button = document.querySelectorAll(".image-link-container");
   console.log(this.button);

   for(let i=0;i<this.button.length;i++)
   {
     console.log("executed");

     this.button[i].addEventListener("click",()=>{
       if(this.button[i].style.backgroundColor == '#AEA071')
       {
        this.button[i].style.backgroundColor =  'rgba(240, 240, 240, 0.822)';
        this.button[i].style.color = 'black';
       }

       else
       {
        this.button[i].style.backgroundColor = '#AEA071';
        this.button[i].style.color = 'white';
      for(let j = 0;j<this.button.length;j++)
      {
        if(i != j)
        {
          console.log("executed");
          this.button[j].style.backgroundColor =  'rgba(240, 240, 240, 0.822)';
          this.button[j].style.color = 'black';
        }
      }
       }

     })
   }


  }


  changeTower(val :any )
  {
    console.log(val);
    this.selected_tower =  this.towers.find(e=> e.name == val)
    console.log(this.selected_tower);
    this.tower_image.src =  this.selected_tower.tower_imgs[0].img;
    this.showTowerDetails();
    let tower_button :any = document.querySelector('.tower-button');
    tower_button.style.backgroundColor = '#AEA071';
    tower_button.style.color = 'white';


    for(let i=1;i<this.button.length;i++)
    {
    this.button[i].style.backgroundColor =  'rgba(240, 240, 240, 0.822)';
    this.button[i].style.color = 'black';
    }
  }

  showTowerDetails()
  {
   this.ant_dis_data.style.display = 'none';
   this.grounding_data.style.display = 'none';
   this.tower_data.style.display = 'block';

   this.selected_val = "tower";
   this.slider_value.value = 2021;
   this.tower_image.src =  this.selected_tower.tower_imgs[0].img;
  }

  showGroundDetails()
  {
    this.ant_dis_data.style.display = 'none';
    this.grounding_data.style.display = 'block';
    this.tower_data.style.display = 'none';

    this.selected_val = "ground";
    this.slider_value.value = 2021;
    this.tower_image.src =  this.selected_tower.grounding_data.ground_imgs[0].img;
  }

  antennaChange(val:any)
  {
    this.changeDefaultDetTheme();
    this.ant_dis_data.style.display = 'block';
    this.grounding_data.style.display = 'none';
    this.tower_data.style.display = 'none';
    this.changeDefaultDetTheme();
    this.selected_val = 'antenna';
    this.slider_value.value = 2021;
   let element_name :any= document.querySelector('.change-element');
   element_name.textContent = "Dimension H(m)xW(m)xD(m)";

  this.selected_object  = this.selected_tower.antenna_data.find(e =>{
    return e.name == val
   })
   console.log(this.selected_object);



  this.value[0].innerHTML = this.selected_object.data[0].eqp_ref;
  this.value[1].innerHTML = this.selected_object.data[0].eqp_place;
  this.value[2].innerHTML = this.selected_object.data[0].carrier;
  this.value[3].innerHTML = this.selected_object.data[0].type;
  this.value[4].innerHTML = this.selected_object.data[0].dimension;
  this.value[5].innerHTML = this.selected_object.data[0].height;
  this.value[6].innerHTML = this.selected_object.data[0].azimuth;
  this.value[7].innerHTML = this.selected_object.data[0].eqp_damage;
  this.value[8].innerHTML = this.selected_object.data[0].feeder_damage;

  this.tower_image.src =  this.selected_object.data[0].img;
  this.needRepairAlert();

  }


  dishChange(val :any)
  {
    this.changeDefaultDetTheme();
    this.ant_dis_data.style.display = 'block';
    this.grounding_data.style.display = 'none';
    this.tower_data.style.display = 'none';

    this.selected_val = 'dish';
    this.slider_value.value = 2021;
    let element_name :any= document.querySelector('.change-element');
    element_name.textContent = "Diameter(m)";

    this.selected_object = this.selected_tower.dish_data.find((e)=>{
      return e.name == val
    });

    console.log(this.selected_object);

    this.value[0].innerHTML = this.selected_object.data[0].eqp_ref;
    this.value[1].innerHTML = this.selected_object.data[0].eqp_place;
    this.value[2].innerHTML = this.selected_object.data[0].carrier;
    this.value[3].innerHTML = this.selected_object.data[0].type;
    this.value[4].innerHTML = this.selected_object.data[0].diameter;
    this.value[5].innerHTML = this.selected_object.data[0].height;
    this.value[6].innerHTML = this.selected_object.data[0].azimuth;
    this.value[7].innerHTML = this.selected_object.data[0].eqp_damage;
    this.value[8].innerHTML = this.selected_object.data[0].feeder_damage;

    this.tower_image.src =  this.selected_object.data[0].img;
    this.needRepairAlert();

  }

  goBack(){
    this._location.back();
  }

  needRepairAlert()
  {
        console.log("need repair executed");
        let eqp_status :any = document.querySelector('.equipment-status');
        console.log(eqp_status.textContent);
        let compare_str = eqp_status.textContent.toLowerCase().match('need to check')
        console.log(compare_str);
      if (compare_str == 'need to check')
      {
      let  eqp_parent_element :any = eqp_status.parentElement;
      console.log(eqp_parent_element);
      eqp_parent_element.style.color = 'red';
      eqp_parent_element.style.backgroundColor = 'white';
      eqp_parent_element.style.border = '1px solid red';
      eqp_status.style.color = 'red';
      }
      else
      {
        let  eqp_parent_element :any = eqp_status.parentElement;
        console.log(eqp_parent_element);
        eqp_parent_element.style.color = 'black';
        eqp_parent_element.style.backgroundColor = 'rgba(240, 240, 240, 0.822';
        eqp_parent_element.style.border = '0px solid red';
        eqp_status.style.color = 'black';
      }
  }


  changeDefaultDetTheme()
  {
    let azimuth_container :any = document.querySelector(".azimuth-container");
    azimuth_container.style.backgroundColor = 'rgba(240, 240, 240, 0.822)';
    azimuth_container.style.border = '0px solid red';
    azimuth_container.style.color = 'black';
    azimuth_container.children[1].style.color = 'black';
  }


}
