import { Component } from '@angular/core';
import { ActivatedRoute, Router,NavigationEnd   } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router,public service: AuthService) {
  }

  ngOnInit() {
      
  }

  
}
