import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSliderModule} from '@angular/material/slider';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {MatTreeModule} from '@angular/material/tree';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";

import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthService } from './auth.service';
import { GuestComponent } from './roles/guest/guest.component';
import { PilotComponent } from './roles/pilot/pilot.component';
import { AeroAdminComponent } from './roles/aero-admin/aero-admin.component';
import { UnsetComponent } from './components/unset/unset.component';


import { FilesComponent } from './components/docs/files/files.component';
import { FileviewerComponent } from './components/docs/fileviewer/fileviewer.component';
import { DocsComponent } from './components/docs/docs.component';
import { OrthomapComponent } from './components/orthomap/orthomap.component';
import { SpinViewComponent } from './components/spin-view/spin-view.component';
import { ThreedviewComponent } from './components/threedview/threedview.component';
import { SummaryComponent } from './components/summary/summary.component';
import { CategoriesComponent } from './components/summary/categories/categories.component';
import { InspectionDetailsComponent } from './components/summary/inspection-details/inspection-details.component';
import { SiteApprovalComponent } from './components/summary/site-approval/site-approval.component';
import { SiteVerificationComponent } from './components/summary/site-verification/site-verification.component';
import { TenantsComponent } from './components/summary/tenants/tenants.component';
import { TableComponent } from './components/table/table.component';
import { TowerAuditComponent } from './components/tower-audit/tower-audit.component';
import { AuditImageComponent } from './components/tower-audit/audit-image/audit-image.component';
import { AuditDetailsComponent } from './components/tower-audit/audit-details/audit-details.component';
import { AuditCategoriesComponent } from './components/tower-audit/audit-categories/audit-categories.component';
import { MapComponent } from './components/map/map.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AddDetailsComponent } from './components/add-details/add-details.component';
import { SpinControlsComponent } from './components/spin-view/spin-controls/spin-controls.component';
import { ImageViewComponent } from './components/spin-view/image-view/image-view.component';
import { MapViewComponent } from './components/spin-view/spin-controls/map-view/map-view.component';
import { ImageSliderComponent } from './components/spin-view/spin-controls/image-slider/image-slider.component';
import { EditComponent } from './components/edit/edit.component';
import { AddSectionComponent } from './components/add-section/add-section.component';
import { SuperAdminComponent } from './roles/super-admin/super-admin.component';
import { TelstraAdminComponent } from './roles/telstra-admin/telstra-admin.component';



import { AgmCoreModule } from '@agm/core';
import { NgxCaptureModule } from 'ngx-capture';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryImageComponent } from './components/gallery/gallery-image/gallery-image.component';
import { GallerySliderComponent } from './components/gallery/gallery-slider/gallery-slider.component';
import { AddDirComponent } from './components/add-dir/add-dir.component';
import { UsersComponent } from './components/users/users.component';
import { SearchComponent } from './components/search/search.component';
import { EditSummaryComponent } from './components/edit-summary/edit-summary.component';
import { UploadComponent } from './components/upload/upload.component';
import { TowerComponent } from './components/tower/tower.component';
import { DatatreeComponent } from './components/upload/datatree/datatree.component';
import { OrthofilelistingComponent } from './components/orthomap/orthofilelisting/orthofilelisting.component';
import { OrthoProcessingComponent } from './components/orthomap/ortho-processing/ortho-processing.component';
import { UploadService } from './components/orthomap/orthofilelisting/service/upload.service';
import { DialogUploadComponent } from './components/upload/dialog-upload/dialog-upload.component';
import { ProcessDialogComponent } from './components/table/process-dialog/process-dialog.component';
import { DocumentDialogComponent } from './components/docs/document-dialog/document-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogPdfComponent } from './components/tower-audit/dialog-pdf/dialog-pdf.component';
import { AnalyseComponent } from './components/analyse/analyse.component'
import { environment } from 'src/environments/environment';
import { DigitaltwinComponent } from './components/digitaltwin/digitaltwin.component';
import { ShelterModalComponent } from './components/digitaltwin/shelter-modal/shelter-modal.component';

import { TowerRiggerAuditComponent } from './components/tower-rigger-audit/tower-rigger-audit.component';
import { RiggerAuditCategoriesComponent } from './components/tower-rigger-audit/rigger-audit-categories/rigger-audit-categories.component';
import { RiggerDialogPdfComponent } from './components/tower-rigger-audit/rigger-dialog-pdf/rigger-dialog-pdf.component';
import { RiggerAuditImageComponent } from './components/tower-rigger-audit/rigger-audit-image/rigger-audit-image.component';
import { RiggerAuditDetailsComponent } from './components/tower-rigger-audit/rigger-audit-details/rigger-audit-details.component';
import { EditEquipmentComponent } from './components/edit-equipment/edit-equipment.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    TelstraAdminComponent,
    GuestComponent,
    PilotComponent,
    AeroAdminComponent,
    UnsetComponent,
    DocsComponent,
    OrthomapComponent,
    DigitaltwinComponent,
    ShelterModalComponent,
    OrthoProcessingComponent,
    OrthofilelistingComponent,
    SpinViewComponent,
    ThreedviewComponent,
    SummaryComponent,
    CategoriesComponent,
    InspectionDetailsComponent,
    SiteApprovalComponent,
    SiteVerificationComponent,
    TenantsComponent,
    TableComponent,
    FileviewerComponent,
    FilesComponent,
    TowerAuditComponent,
    AuditImageComponent,
    AuditDetailsComponent,
    AuditCategoriesComponent,
    MapComponent,
    NavbarComponent,
    EditComponent,
    AddSectionComponent,
    SuperAdminComponent,
    AddDetailsComponent,
    SpinControlsComponent,
    ImageViewComponent,
    MapViewComponent,
    ImageSliderComponent,
    GalleryComponent,
    GalleryImageComponent,
    GallerySliderComponent,
    AddDirComponent,
    UsersComponent,
    SearchComponent,
    EditSummaryComponent,
    UploadComponent,
    TowerComponent,
    DatatreeComponent,
    DialogUploadComponent,
    ProcessDialogComponent,
    DocumentDialogComponent,
    DialogPdfComponent,
    AnalyseComponent,
    TowerRiggerAuditComponent,
    RiggerAuditCategoriesComponent,
    RiggerDialogPdfComponent,
    RiggerAuditImageComponent,
    RiggerAuditDetailsComponent,
    EditEquipmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    PinchZoomModule,
    MatCardModule,
    MatDividerModule,
    MatTreeModule,
    MatCardModule,
    MatIconModule,
    MatCheckboxModule,
    MatTabsModule,
    MatPaginatorModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase_config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MatIconModule,
    MatSnackBarModule,
    HttpClientModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatChipsModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatSortModule,
    MatSliderModule,
    NgSelectModule,
    NgxDropzoneModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCCevsDEkIq1dDj9gFJ4RBaSvOeI2dgu-k',
      libraries: ['geometry','drawing']
    }),
    NgxCaptureModule
  ],
  providers: [AuthService,UploadService],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
