

<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>
<div class="spin">
   <div #content class="viewContainer">
    <app-gallery-image [tower]="tower" [role]="role" [imageData]="imageData" (deleteEvent)="deleteDir($event)"  (editDirEvent)="editDir($event)" (updateDirEvent)="addDir($event)" [crntDir]="crntDir" [index]="index" (imageViewEvent)="setCrntDir($event)" (imageSliderEvent)="sendIndex($event)"></app-gallery-image>
    <app-gallery-slider  [imageData]="imageData" [crntDir]="crntDir" [index]="index" [sliderIndex]="sliderIndex" (sliderEvent)="sendIndex($event)"></app-gallery-slider>

  </div>
</div>
