import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapFetchService } from './map-fetch.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {
  @ViewChild("map") map;
  crntYear = 0;
  markers = [];
  rzoom;
  zoom;
  data = [];
  crntIssue = 'All';
  towerName;
  siteId;
  zoomControl = false;
  scrollwheel = true;
  disableDoubleClickZoom = true;
  maxZoom = 15;
  minZoom = 1;
  mapStyles;
  disableDefaultUI = true;
  // center = { lat: -24, lng: 134 }
  center = { lat: 36.5, lng: -98 }
  labelShow = true;
  role;
  projectID: any;
  processedStatus = {
    OrthoMap : {
      towers: 0,
      percent: 0
    },
    DigitalTwin : {
      towers: 0,
      percent: 0
    },
    TowerView : {
      towers: 0,
      percent: 0
    }
  }
  processedStatusArr = ['OrthoMap','DigitalTwin','TowerView']
  constructor(private service: MapFetchService, private router: Router, private snackBar: MatSnackBar, private authservice: AuthService) { 
    this.projectID = this.authservice.projectID
    this.mapStyles = [
      {
          featureType: "poi",
          elementType: "labels",
          stylers: [
                { visibility: "off" }
          ]
      }
  ];
  }

  ngOnChange() {
  }

  ngOnInit(): void {
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }
    this.service.fetchData().forEach(data => {
      data.forEach(doc => {
        console.log(doc.data());
        let data: any = doc.data()
        data.Towers = data.Towers.map((obj) => {
          if(!obj.processed){
            obj.processed = 'N/A'
            return obj
          }
          else{
            return obj
          }
        })
        this.data.push(data);
        this.processedStatusArr.forEach((col) => {
          this.getTowers(col)
        })
      })
      console.log(' this.data - ', this.data);
      console.log(this.crntYear);
      
      console.log(this.data[this.crntYear]['Towers']);
      
      let allTowers = this.data[this.crntYear].Towers
      console.log(' this.allTowers - ', allTowers);
      let latSum=0, longSum=0, latAvg=0, longAvg=0;

      for (let index = 0; index < allTowers.length; index++) {
        latSum = latSum+ parseFloat(allTowers[index].Latitude);
        longSum = longSum + parseFloat(allTowers[index].Longitude);
        // console.log('longSum - ', longSum);
      }
      latAvg = latSum/allTowers.length;
      longAvg = longSum/allTowers.length;

      console.log('latAvg - ', latAvg);
      console.log('longAvg - ', longAvg);
      this.center.lat = latAvg
      this.center.lng = longAvg
    })

    this.service.fetchProjectSetting().valueChanges().subscribe(items=>{
      this.rzoom = parseFloat(items['zoom_factor'])
      this.zoom = parseFloat(items['zoom_factor'])    
      console.log('zoom-factor from db '+this.zoom);
    })
    
   
  }
  changeRoute(Tower, status) {
    this.siteId = Tower.SiteID;    
    this.towerName = Tower.TowerName;
    console.log('Tower click - ', this.towerName);
    if (status == 'Inspected' && this.role !== 'pilot') {
      this.router.navigate([this.role + '/' + this.data[this.crntYear].Year + '/' + this.towerName+'___'+this.siteId + '/summary']);
    }

    else if (status === 'Uninspected' && this.role === 'pilot') {
      this.router.navigate([this.role+'/'+ this.data[this.crntYear].Year  +'/' +this.towerName+'___'+this.siteId + '/upload']);
    } else {
      this.snackBar.open('This Site is not Inspected yet!', "close", { duration: 2000 });
    }
  }

  switchTable(){
      let allTowers = this.data[this.crntYear].Towers
      console.log(' this.allTowers - ', allTowers);
      let latSum=0, longSum=0, latAvg=0, longAvg=0;

      for (let index = 0; index < allTowers.length; index++) {
        latSum = latSum+ parseFloat(allTowers[index].Latitude);
        longSum = longSum + parseFloat(allTowers[index].Longitude);
        // console.log('longSum - ', longSum);
      }
      latAvg = latSum/allTowers.length;
      longAvg = longSum/allTowers.length;

      console.log('latAvg - ', latAvg);
      console.log('longAvg - ', longAvg);
      this.center.lat = latAvg
      this.center.lng = longAvg

      this.service.fetchProjectSetting().valueChanges().subscribe(items=>{
        this.rzoom = parseFloat(items['zoom_factor'])
        this.zoom = parseFloat(items['zoom_factor'])    
        console.log('zoom-factor from db '+this.zoom);
      })
      this.processedStatusArr.forEach((col) => {
        this.getTowers(col)
      })
  }


  zoomIn() {
   
    if (this.zoom < this.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }

  restore(){
    this.zoom = this.rzoom 
  }


  // removeLabel(){
  //   if(this.labelShow){
  //     this.labelShow = false;
  //     this.map._elem["nativeElement"].querySelector(".gm-style-iw-a").style.display = "none";
  //   }else{
  //     this.labelShow = true;
  //     this.map._elem["nativeElement"].querySelector(".gm-style-iw-a").style.display = "block";
  //   }
  // }

  all(record) {
    return true;
  }

  uninspected(record) {
    return record.Status == "Uninspected";
  }
  inspected(record) {
    return record.Status == "Inspected";
  }
  completed(record) {
    return record.Completed == "Yes";
  }
  emergency(record) {
    return record.Status == "Emergency";
  }
  urgent(record) {
    return record.Status == "Urgent";
  }
  planned(record) {
    return record.Status == "Planned";
  }
  monitor(record) {
    return record.Status == "Monitor";
  }



  pole(record) {
    return record.StructureType == "Pole";
  }

  mast(record) {
    return record.StructureType == "Mast";
  }

  tower(record) {
    return record.StructureType == "Tower";
  }


  heavy(record) {
    return record.Corrosion == "Heavy";
  }

  medium(record) {
    return record.Corrosion == "Medium";
  }

  light(record) {
    return record.Corrosion == "Light";
  }

  surface(record) {
    return record.Corrosion == "Surface";
  }

  cvNo(record) {
    return record.CanradVariation == "No";
  }

  cvYes(record) {
    return record.CanradVariation == "Yes";
  }


  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

  clickEvent(event) {
    event.preventDefault();
  }

  getTowers(column){
    let filteredList = []
    if(column == 'OrthoMap'){
      filteredList = this.data[this.crntYear]['Towers'].filter((obj) => {
        if(obj.hasOwnProperty('processed')){
          return obj.processed.indexOf('O') !== -1
        }
        return false
      })
      this.processedStatus[column].towers = filteredList.length
      this.processedStatus[column].percent = ((filteredList.length / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
    }
    else if(column == 'DigitalTwin'){
      filteredList = this.data[this.crntYear]['Towers'].filter((obj) => {
        if(obj.hasOwnProperty('processed')){
          return obj.processed.indexOf('D') !== -1
        }
        return false
      })
      this.processedStatus[column].towers = filteredList.length
      this.processedStatus[column].percent = ((filteredList.length / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
    }
    else if(column == 'TowerView'){
      filteredList = this.data[this.crntYear]['Towers'].filter((obj) => {
        if(obj.hasOwnProperty('processed')){
           return obj.processed.indexOf('T') !== -1
        }
        return false
      })
      this.processedStatus[column].towers = filteredList.length
      this.processedStatus[column].percent = ((filteredList.length / this.data[this.crntYear]['Towers'].length) * 100).toFixed(2)
    }
  }
}
