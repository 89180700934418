 <div class="image-slider" #slider (wheel)="handleScroll($event)">
   <ng-container *ngIf="imageData.length > 0;else ElseSlider">
    <img class="image-slide" style="max-width: 100%;height: auto;display: inline-block;width: 210px;height: 160px;max-width: 210px;max-height: 160px;margin : 10px;" *ngFor="let elt of imageData[crntDir].Payload;let i = index;" id="thumb_{{i}}" [src]="elt.file" [ngClass]="{'selected': i === index}" (click)="clickImage(i)">
   </ng-container>
   <ng-template #ElseSlider>
    <div class="else">
    <h3  class="white">No Image Found</h3>
    </div>
  </ng-template>
</div>
