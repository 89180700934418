import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-add-dir',
  templateUrl: './add-dir.component.html',
  styleUrls: ['./add-dir.component.css']
})
export class AddDirComponent implements OnInit {
link;
  constructor(public dialogRef: MatDialogRef<AddDirComponent>) { } //@Inject(MAT_DIALOG_DATA) public data: DialogData

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  resultFn(){
    if(this.link != ""){
      return this.link;
    }
      }

}
