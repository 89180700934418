import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
 import { EditComponent } from './../edit/edit.component'
 import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from './users.service';

export interface dataTable {
  displayName: string,
  email: string,
  role: number,
  uid: string,
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['No', 'UserName', 'Email', 'Role'];
  data = [];
  dataSource: MatTableDataSource<dataTable>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  crntYear = 0;
  role;
  filterPredicate;
  isLoaded = false;
  result;
  crntRole = '';
  filterValue;
  constructor(private service : UsersService,private dialog: MatDialog, private router: Router,private snackBar : MatSnackBar) { }

  ngOnInit(): void {
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }
  }

   ngAfterViewInit() {
     this.service.fetchUsers().forEach(data => {
       data.forEach(doc => {
         this.data.push(doc.data());
       })
     }).then(() => {
   this.switchTable();
     });

   }

   switchTable() {
    this.isLoaded = false;
    this.dataSource = new MatTableDataSource(this.data);
    this.filterPredicate = this.dataSource.filterPredicate;
    setTimeout(() => this.dataSource.sort = this.sort);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    this.isLoaded = true;
  }


   applyFilter(bool) {
     if(bool == true){
      this.dataSource.filter = this.crntRole;
     }else{
      this.dataSource.filter = this.filterValue.trim();
     }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editEvent(event,uid,key,value) {
    event.stopPropagation();
      const dialogRef = this.dialog.open(EditComponent, {
        width: '300px',
        data: { value: value , name : key}
      });
      dialogRef.afterClosed().subscribe(
        result=>{
          this.result = result.value;
        },
        (err)=>{
          console.log(err)
        },
        ()=>{
             for(let i = 0;i < this.data.length;i++){
               let temp = this.data[i];
               if (temp && temp['uid'] == uid) {
                this.data[i][key] = this.result;
                 this.result = this.data[i];
               }
             }
             console.log(this.result)
            this.service.setData(this.result.uid).set(this.result, { merge: true });


        }
      );
  }
  deleteEvent(event,uid){
    this.data=this.data.filter((elt)=>{
  return elt['uid'] != uid;
})

    event.stopPropagation();
    this.service.fetchUsers().subscribe(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        if(doc.ref.id === uid)
        {
          doc.ref.delete();
        }
      });
    });
  }

}
