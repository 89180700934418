import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RiggerAuditFetchService {

  count = 0;
  constructor(private fireStore : AngularFirestore, private service: AuthService) { }

  projectId = this.service.projectID;

fetchData(towerName, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).snapshotChanges();
}

 setData(tower, siteId, year){
 return  this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +tower+'___'+siteId).doc(siteId)
 }

 towersData(year){
   return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/towers').doc(year);
 }

//  fetchData(towerName, siteId, year){
//   return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).get();
// }

}
