import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableFetchService } from '../table-fetch.service';

@Component({
  selector: 'app-process-dialog',
  templateUrl: './process-dialog.component.html',
  styleUrls: ['./process-dialog.component.css']
})
export class ProcessDialogComponent implements OnInit {

  totalPrg = {
    currentTowers : 0,
    totalTowers: 0,
    percentProcessed: 0,
  }
  twrdata: any;
  crntYear: any;
  siteID: any;
  year: any;
  stopProcessing = false

  constructor(public dialogRef: MatDialogRef<ProcessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private snackBar: MatSnackBar,private service: TableFetchService) {
           this.twrdata = this.data.data;
           this.crntYear = this.data.yearIdex;
           this.siteID = this.data.siteID;
           this.year = this.data.year
           this.getProcessedStatuss()
     }

  ngOnInit(): void {
  }

  
  async getProcessedStatuss(){
    var percentToUpdate = 100 / this.twrdata[this.crntYear]['Towers'].length
    var percentGoing = 0
    this.totalPrg.totalTowers = this.twrdata[this.crntYear]['Towers'].length
    for(var i = 0;i < this.twrdata[this.crntYear]['Towers'].length;i++){
      if(!this.stopProcessing)
      {
          try{
            let res: any = await this.service.fetchData(this.twrdata[this.crntYear]['Towers'][i].TowerName,this.twrdata[this.crntYear]['Towers'][i].SiteID,this.year).toPromise()
            let processedStatus = ''
            let temp = res.data();
            if(temp != undefined)
              {
                let dg =  temp['DigitalTwin'];
                if(dg.Link != ''){
                  processedStatus = "D"
                }
                let towerView = temp['TowerView']
                if(towerView.length > 0){
                  processedStatus = processedStatus + "T"
                }
                let ortho = temp['OrthoMap']
                if(JSON.stringify(ortho) != '{}'){
                  processedStatus = processedStatus + "O"
                }
                if(processedStatus == ""){
                  this.twrdata[this.crntYear]['Towers'][i].processed = "-"
                }
                else{
                  this.twrdata[this.crntYear]['Towers'][i].processed = processedStatus
                }
              }
              else{
                this.twrdata[this.crntYear]['Towers'][i].processed = "-"
                this.snackBar.open(`Tower ${this.twrdata[this.crntYear]['Towers'][i].Tower.replace(/\s+/g, '')} data not available`, "close", { duration: 2000 });
            }
            await this.service.setData(this.twrdata[this.crntYear].Year).set(
              this.twrdata[this.crntYear],
              {
                merge: true
              }
            );
            percentGoing = percentGoing + percentToUpdate
            this.totalPrg.percentProcessed = Math.ceil(percentGoing)
            this.totalPrg.currentTowers = i + 1
          }
          catch(err){
            console.log(err.message)
          }
      }
    }
    // await this.service.setData(this.twrdata[this.crntYear].Year).set(
    //   this.twrdata[this.crntYear],
    //   {
    //     merge: true
    //   }
    // );
    this.dialogRef.close()
  }

  closeDialog(){
    this.stopProcessing = true
    this.snackBar.open("Update cancelled", "close", { duration: 2000 });
    this.dialogRef.close()
  }
}
