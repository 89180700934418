<!-- <img class="image" [src]="imageArray[img]"> -->
<div class="row " #container>
<div class="col-md-12 container-fluid justify-content-center align-items imageBox "  (dragover)="dragImage($event)" (dragend)="endDrag($event)" (wheel)="wheelZoom($event)">
  <i class="previous" *ngIf="imageArray.length > 1" (click)="previous()"><mat-icon>chevron_left</mat-icon></i>
  <div class="pan-zoom-frame ">
             <div class="pan-element " >
                <div class="zoom_element " >
                   <div  style="position: relative; width: 100%; height: 100%;padding:0; margin:0;" >
                     <pinch-zoom>
                        <img class="image" [src]="imageArray[img]" >
                    </pinch-zoom>
                   </div>
                </div>
          </div>
    </div>
    <i class="next" *ngIf="imageArray.length > 1" (click)="next()"><mat-icon>chevron_right</mat-icon></i>
</div>
</div>
