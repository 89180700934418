import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData {
  value : string;
  name: string;
}
@Component({
  selector: 'app-edit-summary',
  templateUrl: './edit-summary.component.html',
  styleUrls: ['./edit-summary.component.css']
})
export class EditSummaryComponent implements OnInit {
value;
name;
  constructor(public dialogRef: MatDialogRef<EditSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
      this.value = data.value;
      this.name =data.name;
    }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
  editFn(){
  return this.value;
  }


}
