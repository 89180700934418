import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TableFetchService {

  constructor(private fireStore : AngularFirestore, private service: AuthService) { }

projectId = this.service.projectID
  fetchTowers(){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/towers').get();
   }
   addTower(tower, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +tower+'___'+siteId).doc(siteId);
   }

   fetchAuditData(towerName, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).get();
   }

   setData(year){
   return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/towers/').doc(year);
   }

   fetchData(tower, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + year+ '/' +tower+'___'+siteId).doc(siteId).get();
   }

   fetchFullData(towerName,siteId){
     console.log(this.projectId,towerName,siteId);
     
    //  return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/sitedata' + '/2021' + '/MARYLAND___IHS_LAG_3964H').get();

      return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+ 'sitedata'+ '/' + '2021'+ '/' +towerName+'___'+siteId).get();
  
   }

}
