import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SummaryFetchService } from '../../summary/summary-fetch.service';
import { AuditFetchService } from '../audit-fetch.service';

@Component({
  selector: 'app-audit-categories',
  templateUrl: './audit-categories.component.html',
  styleUrls: ['./audit-categories.component.css']
})



export class AuditCategoriesComponent implements OnInit,OnDestroy {

  @Input() data: any;
  @Input() selected: String;
  @Input() role: String;

  @Output() detailsEvent = new EventEmitter<any>();

  @Output() addSectionEvent = new EventEmitter<any>();

  @Output() deleteSectionEvent = new EventEmitter<any>();

  datas;
  mode = null;
  deleteIndex = [];
  elts;
  ngOnChanges() {
  }

  constructor(private summaryService: SummaryFetchService) {
  }
  ngOnDestroy(): void {
    this.summaryService.routedFromSummary = false
    this.summaryService.summaryCategory = ''
  }

  ngOnInit(): void {
    if(this.summaryService.routedFromSummary){
      this.selected = this.summaryService.summaryCategory
    }
  }

  ngAfterViewInit(): void{
    this.datas = this.data
  }

  select(event) {
  let chip = event.target;
  this.selected = chip.getAttribute("value");
  }

  monitor(record) {
    return (record.Option === 1 && record.Value != null);
  }
  planned(record) {
    return (record.Option === 2 && record.Value != null);
  }
  urgent(record) {
    return (record.Option === 3 && record.Value != null);
  }
  emergency(record) {
    return (record.Option === 4 && record.Value != null);
  }

  getDetails(items,section = null,sectionIndex = null){
    this.elts = items;
    this.elts['section'] = section;
    this.elts['sectionIndex'] = this.data[section].findIndex((item) => item.Name== items.Name);
    this.detailsEvent.emit(this.elts);
  }

  refreshDetails(data){
    const details = data[this.elts['section']][this.elts['sectionIndex']]
    this.detailsEvent.emit(details);
  }

leg1(elt){
return elt.Leg == "Leg 1";
}

leg2(elt){
  return elt.Leg == "Leg 2";
}

leg3(elt){
    return elt.Leg == "Leg 3";
}

leg4(elt){
    return elt.Leg == "Leg 4";
}

leg5(elt){
    return elt.Leg == "Leg 5";
}
leg6(elt){
    return elt.Leg == "Leg 6";
}
faceA(elt){
  return elt.Leg == "Face A";
}
  
faceB(elt){
  return elt.Leg == "Face B";
}
  
faceC(elt){
  return elt.Leg == "Face C";
}
  
faceD(elt){
  return elt.Leg == "Face D";
}
  
faceE(elt){
  return elt.Leg == "Face E";
}

faceF(elt){
  return elt.Leg == "Face F";
}

faceAB(elt){
  return elt.Leg == "Face AB";
}

faceBC(elt){
  return elt.Leg == "Face BC";
}

faceCD(elt){
  return elt.Leg == "Face CD";
}

faceCA(elt){
  return elt.Leg == "Face CA  ";
}

faceDA(elt){
  return elt.Leg == "Face DA";
}

hasLeg(fn,status){
  if(this.data.Equipment.filter(fn).length > 0)
  {
    let data = this.data.Equipment.filter(fn)
    for(var datas of data)
    {
      if(datas.Details.filter(status).length > 0)
      {
        return true
      }
    }
    return false
  }
  else{
    return false
  }
}

hasFace(fn,status){
  if(this.data.Equipment.filter(fn).length > 0)
  {
    let data = this.data.Equipment.filter(fn)
    for(var datas of data)
    {
      if(datas.Details.filter(status).length > 0)
      {
        return true
      }
    }
    return false
  }
  else{
    return false
  }
}

      addSection(){
        this.addSectionEvent.emit();
      }

      deleteItems(event,name, section){
        if(event.target.checked){
          this.deleteIndex.push({section , name});
        }else{
        this.deleteIndex = this.deleteIndex.filter(elt =>{
         return elt.name != name;
        });
        event.target.checked = false;
        }
      }


      deleteSection(){
        if(this.mode == 'Delete'){
          if(this.deleteIndex.length > 0){
            this.deleteSectionEvent.emit(this.deleteIndex);
          }
          this.deleteIndex = [];
        this.mode = null;
      }else{
        this.mode = 'Delete';
      }
      }

}
