import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './../../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignInGuard implements CanActivate {

  constructor(private service: AuthService, private router: Router,private route: ActivatedRoute,private firebaseAuth: AngularFireAuth) {
  }

  // canActivate(){
  //   if(this.service.isLoggedIn){
  //     return true;
  //   }
  //   this.authenticate()
  //   return false;
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // this.firebaseAuth.authState.pipe(map(user => {
      //   let us = user
      //   return us !== null
      // })).subscribe((data) => console.log('firebase auth',data))
      return this.authenticate();
  }

  async authenticate()  {
    let role;
    let service = await this.service.userRole();
    console.log(service);
    if(service != undefined)
    {
      let result = await service.toPromise()
      let data = result.data()
      role = data['role'];
      if (role && this.service.isLoggedIn === true) {
            this.router.navigate([role+'/map']);
      }
      else{
            return true
      }
    }
    else{
      return true
    }
    }
}
