import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarFetchService {

  constructor(private fireStore : AngularFirestore, private service: AuthService) { }
projectId = this.service.projectID;
  fetchData(towerName, siteId, year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' +this.projectId+'/'+this.projectId+'/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).get();
}

fetchLogo(){
  return this.fireStore.doc('projects'+ '/' + 'projects'+ '/' +this.projectId+'/'+this.projectId+'/'+ 'projectsetting' + '/' + 'projectsetting').get();
}


fetchTowers(){
  return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' +this.projectId+'/'+this.projectId+'/'+ 'towers').get();
 }


//  fetchYear(year){
//   return this.fireStore.collection('towers').doc(year).get();
//  }

}
