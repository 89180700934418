import { Component, Input, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { files } from './example-data'
import { UploadService } from '../upload.service';

/** File node data with possible child nodes. */
export interface FileNode {
  name: string;
  type: string;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  name: string;
  type: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-datatree',
  templateUrl: './datatree.component.html',
  styleUrls: ['./datatree.component.css']
})
export class DatatreeComponent implements OnInit{
  showTree: boolean = false;
  @Input() ExistingFiles : any;  
  
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;
  
  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;
  
  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;
  
  files1;
  imgURL;
  constructor(private upload: UploadService) {       
        
  }

  ngOnInit():void{
  }

  ngAfterViewInit(){

    setTimeout(() => {
      
      this.showValue();
    }, 1000);
    // console.log('hello');
    
  }

  reload(){
    setTimeout(() => {
      
      this.showValue();
    }, 1000);
  }

  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number): FlatTreeNode {
    return {
      name: node.name,
      type: node.type,
      level,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode): number {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }

  showValue(){
    this.showTree = true;
    console.log(this.showTree);
    
    this.upload.buildData()

    let treeD = this.upload.generateObj();
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = treeD;
    console.log(treeD);
    
    // console.log(this.upload.generateObj());
    
       
  }

  showFile(d){
    let data = this.dataSource.data[0].children
    // console.log(data);
    let img:any;
    for (let i = 0; i < data.length; i++) {
      // console.log(data[i].name);
      
      for (let j = 0; j < data[i].children.length; j++) {
        let imgName = data[i].children[j].name;
        
        // console.log(imgName);
        if (imgName === d) {
          img = data[i].name + '/' + imgName;
          // console.log(img);
          
          this.imgURL = this.upload.getImageUrl(img);
        }
        
        
      }
      
    }

// console.log(d.name);
// console.log(this.upload.getImageUrl(d));
  }

}
