import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DigitaltwinFetchService {
url;
  constructor(private fireStore : AngularFirestore,private http :  HttpClient,public sanitizer: DomSanitizer,private storage : AngularFireStorage, private service: AuthService) { }

projectId = this.service.projectID
fetchData(tower){
  console.log('hello');
  
  return this.http.get("assets/json/"+this.projectId+ '/'+tower+"/digitaltwin.json");
  }


  setData(tower,siteId,year){
    // return  this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+ '/'+tower).doc(tower);
    return  this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+  '/'+ 'sitedata'+ '/' + year+ '/' +tower+'___'+siteId).doc(siteId);
    }

    towersData(year){
      return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' +this.projectId+'/'+ this.projectId+ '/towers').doc(year);
    }

    fetchProjectSetting(){
      return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' +this.projectId + '/' +this.projectId+ '/projectsetting').doc('projectsetting')
     }

loadGLTF(link){
  console.log(link);
  console.log(this.http.get(link));
  
 return this.http.get(link);
}



}
