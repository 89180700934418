import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { UploadService } from '../upload.service';
import * as S3 from 'aws-sdk/clients/s3';


@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrls: ['./dialog-upload.component.css']
})
export class DialogUploadComponent implements OnInit {


  s3: any;
  projectSetting: any;

  testData = "akhil";
  uploadList;
  tempUploadList = [];
  tempUploadList1 = [];

  existingFiles;
  testArr = ['name1', 'name2'];
  towerName;
  siteId;
  projectID;
  role;
  year;
  currUploadNumber;
  totalNumberOfFiles;
  percentUpload;
  uploadVis = 'visible';
  uploadedHeadVis = 'hidden';
  request: any;
  uploading = false;
  Object = Object;
  finishedFiles: any = [];
  awsS3UploadObj: any = []
  awsS3Index = 0
  isUploading: any;
  bucketName;

  totalPrg = {
    currUploadNumber: 0,
    totalNumberOfFiles: 0,
    percentUpload: 0
  }

  constructor(private upload: UploadService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private service: AuthService,private afs: AngularFirestore,@Inject(MAT_DIALOG_DATA) public data: {towerName: string,siteID: string,year: string,projectID: string}) { 
    this.projectID = this.service.projectID
    this.getSetting()
    this.towerName = this.data.towerName
    this.siteId = this.data.siteID
    this.year = this.data.year
    console.log(this.data)
    }

  ngOnInit(): void {
  }

  getSetting(){
    this.afs.doc('projects'+ '/' + 'projects'+ '/' + this.projectID + '/' + this.projectID + '/projectsetting/projectsetting').get().subscribe((items: any)=>{
      // console.log(items.data())
      let item = items.data()
      let s3 = new S3({
             accessKeyId: item.s3setting.s3accessid,
             secretAccessKey: item.s3setting.s3accesskey,
             region: item.s3setting.s3region
      })
      this.projectSetting = item.s3setting
      this.s3 = s3
      this.bucketName = item.s3setting.s3uploadbucket
    })
  }

  // uploadFolders(files) {
  //   this.uploading = true;
  //   if (files != null) {
  //     this.uploadList = files;
  //     this.currUploadNumber = 0;
  //     this.percentUpload = 0;
  //   }
  //   this.uploadVis = 'hidden';
  //   this.uploadedHeadVis = 'visible';
  //   this.totalNumberOfFiles = this.uploadList.length
  //   this.tempUploadList = [];

  //   for (let i = 0; i < this.uploadList.length; i++) {
  //     let path = this.uploadList[i].webkitRelativePath;
  //     this.tempUploadList.push({ filename: this.uploadList[i].name, progress: 0 });
  //     this.uploadS3(path, this.uploadList[i])
  //   }
  //   this.upload.pilotDirectory(this.towerName, this.siteId,this.year,this.projectID, this.uploadList)
  //   this.uploadList = []
  // }

  uploadS3(path, file) {
    let path1 = path.substring(path.indexOf('/'), path.length)
    this.upload.buildS3Structure(path1)
    
    this.tempUploadList1.push(path1)
    path = path.split('/').pop()
    let nm_nw = this.projectID + '/' + this.towerName;
    
    this.request = this.s3.putObject({
      Bucket: this.bucketName,
      Key: nm_nw + path1,
      Body: file,
    });
    let d;
    this.request.on('httpUploadProgress', function (progress) {
      // console.log(progress.loaded + " of " + progress.total + " bytes");
      // console.log(Math.round(progress.loaded/progress.total*100)+ '% done');
      d = Math.round(progress.loaded / progress.total * 100)
      push_data(d, path)
      // console.log(d);
    });
    let push_data = (data, p) => {
      let index = this.tempUploadList.map((o) => {
        return o.filename;
      }).indexOf(p)


      this.tempUploadList[index].progress = data

    }

    this.request.send((res) => {
      // null if successful
      // console.log('res - ', res);
      // RequestTimeTooSkewed: The difference between the request time and the current time is too large.
      // IF THIS ERROR, SHOW MESSAGE
            
      if (res == null) {

        this.tempUploadList[this.currUploadNumber].progress = 100;

        this.currUploadNumber = this.currUploadNumber + 1;
        this.percentUpload = Math.round(((this.currUploadNumber / this.totalNumberOfFiles) * 100) * 10) / 10
        this.snackBar.open('Uploaded - ' + this.percentUpload + '% OR ' + this.currUploadNumber + '/' + this.totalNumberOfFiles + ' files', "close", { duration: 5000 });

        if (this.currUploadNumber == this.totalNumberOfFiles) {
          this.snackBar.open('Redirecting to upload page in a moment', '', { duration: 5000 })
          this.uploading = false;
          this.loadData()
        }
      }
      else {
        this.uploadVis = 'visible';
        this.uploadedHeadVis = 'hidden';
        this.snackBar.open('Error - ' + res, "close", { duration: 5000 });
        console.log('ERROR - ', res);

      }

    });
  }

  loadData(){
    this.upload.getS3()
  }

  closeDialog(){
    for(var i =this.awsS3Index; i< this.awsS3UploadObj.length;i++){
      this.awsS3UploadObj[i].abort()
    }
    this.dialog.closeAll()
  }

  async onDrop(event){
    event.preventDefault();
    console.log(event)
    let files = await this.getAllFileEntries(event.dataTransfer.items);
    await this.dragUploadFolders(files)
    console.log(files)
  }

  async dragUploadFolders(files) {
    this.isUploading =true
    this.finishedFiles = []
    this.uploading = true;
    if (files != null) {
      this.uploadList = files;
      this.currUploadNumber = 0;
      this.percentUpload = 0;
    }
    this.uploadVis = 'hidden';
    this.uploadedHeadVis = 'visible';
    this.totalPrg.totalNumberOfFiles = this.uploadList.length
    this.tempUploadList = [];

    for (let i = 0; i < this.uploadList.length; i++) {
      let path = this.uploadList[i].fullPath;
      this.tempUploadList = [{ filename: this.uploadList[i].name, progress: 0, status: false },...this.tempUploadList];
      await this.uploadS3New(i,path, this.uploadList[i],this.totalPrg,this.finishedFiles)
    }
    this.loadData()
    let statusBlob = new Blob(['Success'], {type: '.txt'});
    await this.uploadStatusFile(statusBlob)
    // this.upload.pilotDirectory(this.towerName, this.siteId,this.year,this.projectID, this.uploadList)
    this.uploadList = []
    this.isUploading = false
    this.dialog.closeAll()
  }

  async uploadFolders(files) {
    this.isUploading = true
    this.finishedFiles = []
    this.uploading = true;
    if (files != null) {
      this.uploadList = files;
      this.currUploadNumber = 0;
      this.percentUpload = 0;
    }
    this.uploadVis = 'hidden';
    this.uploadedHeadVis = 'visible';
    this.totalPrg.totalNumberOfFiles = this.uploadList.length
    this.tempUploadList = [];

    for (let i = 0; i < this.uploadList.length; i++) {
      let path = this.uploadList[i].webkitRelativePath;
      this.tempUploadList = [{ filename: this.uploadList[i].name, progress: 0, status: false },...this.tempUploadList];
      await this.uploadS3New(i,path, this.uploadList[i],this.totalPrg,this.finishedFiles)
    }
    this.loadData()
    let statusBlob = new Blob([JSON.stringify({status : 'success'})], {type: '.txt'});
    await this.uploadStatusFile(statusBlob)
    // this.upload.pilotDirectory(this.towerName, this.siteId,this.year,this.projectID, this.uploadList)
    this.uploadList = []
    this.isUploading = false
    this.dialog.closeAll()
  }

  onDragOver(event){
    event.stopPropagation();
    event.preventDefault();
    // console.log(event)
  }

  async getAllFileEntries(dataTransferItemList) {
    let fileEntries = [];
    // Use BFS to traverse entire directory/file structure
    let queue = [];
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
      queue.push(dataTransferItemList[i].webkitGetAsEntry());
    }
    while (queue.length > 0) {
      let entry = queue.shift();
      if (entry.isFile) {
        let file: any = await this.getFile(entry);
        file.fullPath = entry.fullPath.substring(1)
        fileEntries.push(file);
      } else if (entry.isDirectory) {
        let reader = entry.createReader();
        queue.push(...await this.readAllDirectoryEntries(reader));
      }
    }
    return fileEntries;
  }

  async getFile(fileEntry) {
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      console.log(err);
    }
  }

  async readAllDirectoryEntries(directoryReader) {
    let entries = [];
    let readEntries: any = await this.readEntriesPromise(directoryReader);
    while (readEntries.length > 0) {
      entries.push(...readEntries);
      readEntries = await this.readEntriesPromise(directoryReader);
    }
    return entries;
  }

  async  readEntriesPromise(directoryReader) {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      console.log(err);
    }
  }
  
  uploadfile = function(fileName, file, folderName) {
    const params = {
      Bucket: this.bucketName,
      Key: this.projectID + '/' + this.towerName + folderName,
      Body: file,
      ContentType: file.type
    };
    return this.s3.upload(params, function(err, data) {
 
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
  }

  uploadStatusFile = function(file) {
    const params = {
      Bucket: this.bucketName,
      Key: this.projectID + '/' + this.towerName + '/status.txt',
      Body: file,
    };
    return this.s3.upload(params, function(err, data) {
 
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      console.log('Successfully uploaded file.', data);
      return true;
    });
  }

  get_Obj = (data, p) => {
    let index = this.tempUploadList.map((o) => {
      return o.filename;
    }).indexOf(p)
    return this.tempUploadList[index]
  }
 
  async uploadS3New(index,path, file,totalprg,finishedfiles) {
    let path1 = path.substring(path.indexOf('/'), path.length)
    this.tempUploadList1 = [path1,...this.tempUploadList1]
    this.upload.buildS3Structure(path1)
    var temp_obj = this.get_Obj('',file.name)
    let uploadObj = await this.uploadfile(file.name, file, path1)
  
    this.awsS3Index = index
    this.awsS3UploadObj.push(uploadObj)
    return uploadObj.on('httpUploadProgress', async function(progress) {
        let progressPercentage = Math.round(progress.loaded / progress.total * 100);
        console.log(progressPercentage);
        if (progressPercentage < 100) {
          temp_obj.progress = progressPercentage;
 
        } else if (progressPercentage == 100) {
          if(!finishedfiles.includes(progress.key))
          {
            temp_obj.progress = progressPercentage;
            totalprg.currUploadNumber = totalprg.currUploadNumber + 1;
            totalprg.percentUpload = Math.round((totalprg.currUploadNumber /totalprg.totalNumberOfFiles) * 100)
            temp_obj.status = true
            console.log("Uploaded")
            finishedfiles.push(progress.key)
           
          if (totalprg.currUploadNumber == totalprg.totalNumberOfFiles) {
            console.log("Total File Uploading Finished")
            
            // this.uploading = false;
            // this.loadData()
            }
          }
        }
      }).promise()
  }
  
}
