import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ResolveEnd } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  role: string;
  password: string;
}


@Injectable({
  providedIn: 'root'
})



export class AuthService {
  projectID: any;
  projectsetting:any;
  userState: any;
  role;
  public href: any = "";
  protocol: string =""
  currentRoute: string;
  data$:any;

  constructor(
    private fireAuth: AngularFireAuth, 
    private fireStore: AngularFirestore, 
    private router: Router, 
    private ngZone: NgZone, 
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    ) {
    this.fireAuth.authState.subscribe(user => {
      if (user) {
        this.userState = user;
        this.getCollection(this.userState.uid)
        localStorage.setItem('user', JSON.stringify(this.userState));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
    this.getProjectSetting()
  }
  
  async getProjectID(){
//For deployment uncomment these 4 lines
      let url = window.location.href;
      const regexp = /(?<=https:\/\/)(.){2,}(?=\.[(web.app)(com)(in)])/g;
      const match = url.match(regexp);
      this.href = match[0]
      
//For deployment comment these domain
      // this.href = "ais.vertikaliti"
      // this.href = "beeline.vertikaliti"
      // this.href = "telco.wl.vertikaliti"

      let doc = await this.getProjectList().toPromise()
      let projectListData: any = doc.data()
      let projectID = this.getValues(projectListData.projectList,this.href)
      this.projectID = projectID
      return projectID
  }

  getValues(data: any,directedDomain: any){
    var value = ""
    for(var d in data){
      if(data[d] == directedDomain){
        value = d
      }
    }
    return value
  }

  SignIn(email, password,projectID) {
    return this.fireAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {
      this.ngZone.run(() => {
        this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + projectID + '/' + projectID + '/users').valueChanges().subscribe(
          (data) => {
            data.forEach(elt => {
              if (result.user.uid === elt['userID']) {
                  console.log('is it working');
                  this.role = elt['role'];
                  let role = this.role;
                  if (this.isLoggedIn !== true) {
                    this.router.navigate(['sign-in']);
                  }
                  if (role == "telstra-processing") {
                    this.router.navigate(['telstra-processing/map']);
                  }
                  if (role == "pilot") {
                    this.router.navigate(['pilot/map']);
                  }
                  if (role == "aero-processing") {
                    this.router.navigate(['aero-processing/map']);
                  }
                  if (role == "user") {
                    this.router.navigate(['user/map']);
                  }
                  if (role == "super-admin") {
                    
                    this.router.navigate(['super-admin/map']);
                  }
                  if (role == "unset") {
                    this.router.navigate(['unset']);
                  }

                }
              });
            },
            (err) => {
              // this.snackBar.open(err, "close", { duration: 2000 });
            }
            );
        });
      }).catch((error) => {
        this.snackBar.open('Email/Password is Incorrect!' + error, "close", { duration: 2000 });
      })
  }

  SignUp(email, password, username,role) {
    return this.fireAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        //    this.SendVerificationMail();
        if (result.user) {
          this.snackBar.open('Email is Registered!', "close", { duration: 2000 });
          this.SetUserData(result.user, username,role, password);
        }
      }).catch((error) => {
        this.snackBar.open(error.message, "close", { duration: 2000 });
        if (error.code === "auth/email-already-in-use") {
          setTimeout(() => {
            this.router.navigate(['sign-in']);
          }, 2000);
        }
      })
  }

  SetUserData(user, username,role, password) {
    const userRef: AngularFirestoreDocument<any> = this.fireStore.doc(this.projectID + '/' +this.projectID+ `/users/${user.uid}`);
    const userState: User = {
      uid: user.uid,
      email: user.email,
      displayName: username,
      role: role,
      password: password
    }
    return userRef.set(userState, {
      merge: true
    })
  }


  //   SendVerificationMail() {
  //     return this.fireAuth.currentUser.then(u => u.sendEmailVerification())
  //     .then(() => {
  //       this.snackBar.open('Verification Link has been Sent to your Email!', "close",{ duration: 2000});
  //     })
  // }


  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
     if(user) {
      return true;
    }
    return false;
  }

  async userRole() {
    let projectID = await this.getProjectID()
    this.projectID = projectID
    let user = JSON.parse(localStorage.getItem('user'));
    console.log('hello user');
    let fireAuthCheck = await this.fireAuth.currentUser
    if (user && fireAuthCheck) {
     return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectID + '/' + this.projectID+ '/users').doc(user.uid).get();
    }
  }

   //check if project exists in db
  checkProjectExist(projectID){
    let docRef = this.fireStore.collection('projects').doc('projectList').get().subscribe(val => {
      console.log(val.data())
    })
  }


  ForgotPassword(passwordResetEmail) {
    return this.fireAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.snackBar.open('Password reset email sent, check your inbox!', "close", { duration: 2000 });
      }).catch((error) => {
        this.snackBar.open(error.message, "close", { duration: 2000 });
      })
  }

  //for signing out the application
  async SignOut() {
      await this.fireAuth.signOut()
      localStorage.removeItem('user');
      setTimeout(() => {
        this.router.navigate(['/sign-in']);
      },500)
  }

  getCollection(uid:any){

  }

  //fetch project settings from db
  getProjectSetting(){
    this.fireStore.doc('projects'+ '/' + 'projects'+ '/' + this.projectID + '/' + this.projectID + '/projectsetting/projectsetting').get().subscribe((items: any)=>{
      console.log(items.data())
      this.projectsetting = items.s3setting
    })
  }

  //fetch projects list
  getProjectList(){
    return this.fireStore.doc('projects' + '/' + 'projectList').get()
  }

}
