
<div (click)="clickEvent($event)">
  <div class="nav-Bar">
    <app-navbar [currentRoute]="'map'" [crntYear]="crntYear" [data]="data"  [main]="true"></app-navbar>
  </div>


  <agm-map *ngIf="data" #map [latitude]="center.lat" [longitude]="center.lng" [(zoom)]="zoom" [disableDefaultUI]="disableDefaultUI" [minZoom]="minZoom" [maxZoom]="maxZoom" [scrollwheel]="scrollwheel" [disableDoubleClickZoom]="disableDoubleClickZoom" [styles]="mapStyles">
   <ng-container *ngFor="let marker of data[crntYear]['Towers'];let i as index;">
    <agm-marker *ngIf="crntIssue == 'All' || marker.Status == crntIssue" [latitude]="marker.Latitude" [longitude]="marker.Longitude" (click)="changeRoute(marker)" (mouseOver)="onMouseOver(infoWindow, $event)" (mouseOut)="onMouseOut(infoWindow, $event)" [iconUrl]="$any({ url: (marker.Completed== 'Yes' ? '/assets/images/icons/Completedd.png' : '/assets/images/icons/'+marker.Status +'.png'),scaledSize: {width: 25, height: 35}})" [title]="marker.TowerName" (markerClick)="changeRoute(marker,marker.Status)">
     <agm-info-window class="label"  #infoWindow [disableAutoPan]="false"><strong>{{marker.SiteID +" - "+marker.TowerName}}</strong></agm-info-window>
    </agm-marker>
   </ng-container>
  </agm-map>


  <div class="zoom">
    <div class="tagBox">
        <select class="issueSelect" [(ngModel)]="crntIssue">
          <option readonly disabled>Choose a Option</option>
          <option [value]="'All'">All</option>
          <option [value]="'Uninspected'">Uninspected</option>
          <option [value]="'Inspected'">Inspected</option>
          <option [value]="'Completed'">Completed</option>
          <option [value]="'Emergency'">Emergency</option>
          <option [value]="'Urgent'">Urgent</option>
          <option [value]="'Planned'">Planned</option>
          <option [value]="'Monitor'">Monitor</option>
        </select>
      <select class="year" [(ngModel)]="crntYear" (change)="switchTable()">
      <option readonly disabled>Select a Year</option>
      <option *ngFor="let item of data;let i = index;" [value]="i">{{ item.Year }}</option>
    </select>
    <!-- <button class="zoomBtn" (click)="removeLabel()"><i class="fas fa-tags tags"></i></button> -->
  </div>
    <button class="zoomBtn plus" (click)="zoomIn()">+</button>
    <button class="zoomBtn minus" (click)="zoomOut()">-</button>
    <button  matTooltip="Restore zoom"
    matTooltipPosition="below"
    matTooltipHideDelay="100" class="zoomBtn restore" (click)="restore()"><mat-icon class="reload">cached</mat-icon></button>
  </div>

  <div class="antennaGroup" *ngIf="data">
    <table>
      <tr>
        <td class="tableField" colspan="4"><p class="antennaHeading borderTop">Projects Status</p>
      </tr>
      <tr>
        <td class="tableField"><img class="icon" src="assets/images/icons/Uninspected.png"></td>
        <td class="tableField">Uninspected</td>
        <td class="tableField">{{ data[crntYear]['Towers'].filter(uninspected).length }}</td>
        <td class="tableField">{{ (data[crntYear]['Towers'].filter(uninspected).length / data[crntYear]['Towers'].filter(all).length*100).toFixed(2)  }}%</td>
      </tr>
      <tr>
        <td class="tableField"><img class="icon"  src="assets/images/icons/Inspected.png"></td>
        <td class="tableField">Inspected</td>
        <td class="tableField">{{ data[crntYear]['Towers'].filter(inspected).length }}</td>
        <td class="tableField">{{ (data[crntYear]['Towers'].filter(inspected).length / data[crntYear]['Towers'].filter(all).length*100).toFixed(2) }}%</td>
      </tr>
      <tr>
        <td class="tableField"><img class="icon" src="assets/images/icons/Completedd.png"></td>
        <td class="tableField">Completed</td>
        <td class="tableField">{{ data[crntYear]['Towers'].filter(completed).length }}</td>
        <td class="tableField">{{ (data[crntYear]['Towers'].filter(completed).length / data[crntYear]['Towers'].filter(all).length*100).toFixed(2) }}%</td>
      </tr>
      <tr>
        <td class="tableField" colspan="4"><p class="antennaHeading">Processed Status</td>
      </tr>
      <tr>
        <td class="tableField">1)</td>
        <td class="tableField">OrthoMap</td>
        <td class="tableField">{{ processedStatus.OrthoMap.towers }}</td>
        <td class="tableField">{{ processedStatus.OrthoMap.percent }}%</td>
      </tr>
      <tr>
        <td class="tableField">2)</td>
        <td class="tableField">DigitalTwin</td>
        <td class="tableField">{{ processedStatus.DigitalTwin.towers }}</td>
        <td class="tableField">{{ processedStatus.DigitalTwin.percent }}%</td>
      </tr>
      <tr>
        <td class="tableField">3)</td>
        <td class="tableField">TowerView</td>
        <td class="tableField">{{ processedStatus.TowerView.towers }}</td>
        <td class="tableField">{{ processedStatus.TowerView.percent }}%</td>
      </tr>
    </table>

  </div>



</div>
