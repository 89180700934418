
 <div class="optionsHeading">
  <mat-chip-list aria-label="Edit Selection"  *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">
    <mat-chip class="addIcon" (click)="fileInput.click()"><mat-icon>add</mat-icon></mat-chip>
    <mat-chip class="deleteIcon" (click)="deleteDoc()"><mat-icon>delete</mat-icon></mat-chip>
    <input #fileInput type="file" (change)="addDoc($event.target)" class="hidden" accept=".pdf,application/pdf,.xls,application/xls,.xlsm,application/xlsm,.xlsx,application/xlsx,.png,image/png,.jpg,image/jpg,.tif,image/tif,.doc,application/doc,.docx,application/docx" multiple/>
    </mat-chip-list>
</div>
<div class="content">
  <div class="contentHeading" *ngIf="data"><h3 class="fileDiv"> Files </h3>
  </div>
 <div *ngIf="data">
  <div class="contentRow" *ngFor="let item of data;let i = index;">
    <p *ngIf="item.Link">{{ i+1 }}</p>
    <p class="name" *ngIf="item.Link">{{ item.File }}</p>
    <span *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing' && item.Link"><i><mat-icon class="editIcon" (click)="editName(item.File,i)"> edit</mat-icon></i></span>
    <i *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing' || role == 'user' && item.Link"><mat-icon class="imageIcon" (click)="sendLink(item['Link'])">description</mat-icon></i>
    <span *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing' && item.Link && mode == 'Delete'" class="delete"><input type="checkbox" (change)="deleteItems($event,item.File)"></span>
  </div>
</div>
</div>
