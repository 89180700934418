import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-site-approval',
  templateUrl: './site-approval.component.html',
  styleUrls: ['./site-approval.component.css']
})
export class SiteApprovalComponent implements OnInit {

  @Input() summary;
  @Input() role;

  @Output() editEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


editData(section,field){
  let value;
    if(this.summary){
      value =  this.summary[section][field]
    }else{
      value = 'N/A';
    }
  this.editEvent.emit({section,field,value});
}
}
