<h1 mat-dialog-title>Add Section</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Select Section Type</mat-label>
    <mat-select [(ngModel)]="section" required>
      <mat-option [value]="'General'">General</mat-option>
      <mat-option [value]="'Equipment'">Equipment</mat-option>
      <mat-option [value]="'TowerFoundationCheck'" *ngIf="routerEndsWithRigger">Tower Foundation Check</mat-option>
      <mat-option [value]="'TensionCheck'" *ngIf="routerEndsWithRigger">Tension Check</mat-option>
      <mat-option [value]="'TowerVerticalityCheck'" *ngIf="routerEndsWithRigger">Tower Verticality Check</mat-option>
      <mat-option [value]="'LoadingonTower'" *ngIf="routerEndsWithRigger">Loading on Tower</mat-option>
    </mat-select>
  </mat-form-field>
    <ng-container *ngIf="section === 'Equipment'">
      <mat-form-field appearance="fill">
        <mat-label>Select a Leg</mat-label>
        <mat-select [(ngModel)]="leg" required>
          <mat-option [value]="'Leg 1'">Leg 1</mat-option>
          <mat-option [value]="'Leg 2'">Leg 2</mat-option>
          <mat-option [value]="'Leg 3'">Leg 3</mat-option>
          <mat-option [value]="'Leg 4'">Leg 4</mat-option>
          <mat-option [value]="'Leg 5'">Leg 5</mat-option>
          <mat-option [value]="'Leg 6'">Leg 6</mat-option>
          <mat-option [value]="'Face A'">Face A</mat-option>
          <mat-option [value]="'Face B'">Face B</mat-option>
          <mat-option [value]="'Face C'">Face C</mat-option>
          <mat-option [value]="'Face D'">Face D</mat-option>
          <mat-option [value]="'Face E'">Face E</mat-option>
          <mat-option [value]="'Face F'">Face F</mat-option>
          <mat-option [value]="'Face AB'">Face AB</mat-option>
          <mat-option [value]="'Face BC'">Face BC</mat-option>
          <mat-option [value]="'Face CD'">Face CD</mat-option>
          <mat-option [value]="'Face CA'">Face CA</mat-option>
          <mat-option [value]="'Face DA'">Face DA</mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="leg !== 0">
      <mat-label>Select an Equipment</mat-label>
      <mat-select [(ngModel)]="equipment" required>
        <mat-option [value]="'RF'">RF</mat-option>
        <mat-option [value]="'RRU'">RRU</mat-option>
        <mat-option [value]="'MW'">MW</mat-option>
        <mat-option [value]="'COAX ROUTING'">COAX ROUTING</mat-option>
        <mat-option [value]="'WAVE GUIDE'">WAVE GUIDE</mat-option>
        <mat-option [value]="'Custom'">Custom</mat-option>
      </mat-select>
    </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="leg !== 0">
    <mat-label>Equipment Name : {{equipment}}</mat-label>
    <input matInput [(ngModel)]="equipmentName" required>
  </mat-form-field>
    </ng-container>
    <ng-container *ngIf="section === 'General'">
      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="mode" required>
          <mat-option [value]="'Normal'">Normal</mat-option>
          <mat-option [value]="'CanradVariations'">Canrad Variations</mat-option>
          <mat-option [value]="'CorrosionReport'">Corrosion Report</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Section Name</mat-label>
        <input matInput [(ngModel)]="sectionName" required>
      </mat-form-field>
      <!-- <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="sectionName">
          <mat-option [value]="'INSPECTION DETAILS'">INSPECTION DETAILS</mat-option>
          <mat-option [value]="'SITE INFORMATION DATA'">SITE INFORMATION DATA</mat-option>
          <mat-option [value]="'SITE SURVEY DATA'">SITE SURVEY DATA</mat-option>
          <mat-option [value]="'BASE CONCRETE FOUNDATION INSPECTION'">BASE CONCRETE FOUNDATION INSPECTION</mat-option>
          <mat-option [value]="'TOWER STRUCTURE INSPECTION'">TOWER STRUCTURE INSPECTION</mat-option>
          <mat-option [value]="'CHECK EQUIPMENT ON TOWER'">CHECK EQUIPMENT ON TOWER</mat-option>
          <mat-option [value]="'CHECK OB LIGHT SYSTEM'">CHECK OB LIGHT SYSTEM</mat-option>
          <mat-option [value]="'CHECK GROUND AND LIGHTNING PROTECTION SYSTEM'">CHECK GROUND AND LIGHTNING PROTECTION SYSTEM</mat-option>
          <mat-option [value]="'CHECK VERTICAL DISTANCE (BY MEASURE)'">CHECK VERTICAL DISTANCE (BY MEASURE)</mat-option>
          <mat-option [value]="'CHECK THE SITE ENTRANCE (ACCESS ROAD)'">CHECK THE SITE ENTRANCE (ACCESS ROAD)</mat-option>
          <mat-option [value]="'FENCE GATE AND INTERNAL SITE CONDITION'">FENCE GATE AND INTERNAL SITE CONDITION</mat-option>
        </mat-select>
      </mat-form-field> -->
    </ng-container>
    <ng-container *ngIf="section === 'TowerFoundationCheck'">
      <mat-form-field appearance="fill">
        <mat-label>Select a Structure</mat-label>
        <mat-select [(ngModel)]="pole" required>
          <mat-option [value]="'Tower'">Tower</mat-option>
          <mat-option [value]="'Pole 1'">Pole 1</mat-option>
          <mat-option [value]="'Pole 2'">Pole 2</mat-option>
          <mat-option [value]="'Pole 3'">Pole 3</mat-option>
          <mat-option [value]="'Pole 4'">Pole 4</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Select one</mat-label>
        <mat-select [(ngModel)]="TFCStructureMode" required>
          <mat-option [value]="'SizeandTowerFoundationBaseLevel'">Size and Tower Foundation Base Level</mat-option>
          <mat-option [value]="'TowerDistance'">Tower Distance</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="structureMode !== 0">
        <mat-label>Structure Name</mat-label>
        <input matInput [(ngModel)]="TFCStructureName" required *ngIf="TFCStructureMode === 'SizeandTowerFoundationBaseLevel'">
        <mat-select [(ngModel)]="TFCStructureName" required *ngIf="TFCStructureMode === 'TowerDistance'">
          <mat-option [value]="'SIDE DIAGONAL'" *ngIf="pole === 'Tower'">SIDE DIAGONAL</mat-option>
          <mat-option [value]="'DIAGONAL DISTANCE'" *ngIf="pole === 'Tower'">DIAGONAL DISTANCE</mat-option>
          <mat-option [value]="'TOWER LEG DISTANCE'" *ngIf="pole !== 'Tower'">TOWER LEG DISTANCE</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="section === 'TowerVerticalityCheck'">
      <mat-form-field appearance="fill">
        <mat-label>Select a Structure</mat-label>
        <mat-select [(ngModel)]="TowerVerticalityCheckStructure" required>
          <mat-option [value]="'SelfSupportingTower'">Self Supporting Tower</mat-option>
          <mat-option [value]="'Guyed'">Guyed</mat-option>
          <mat-option [value]="'SteelPole'">Steel Pipe</mat-option>
          <mat-option [value]="'Monopole'">Monopole</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Structure Name</mat-label>
        <input matInput [(ngModel)]="TowerVerticalityCheckStructureName" required>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="section === 'TensionCheck'">
      <mat-form-field appearance="fill">
        <mat-label>Select a Level</mat-label>
        <mat-select [(ngModel)]="TensionCheckLevel" required>
          <mat-option [value]="'Level 1'">Level 1</mat-option>
          <mat-option [value]="'Level 2'">Level 2</mat-option>
          <mat-option [value]="'Level 3'">Level 3</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Structure Name</mat-label>
        <input matInput [(ngModel)]="TensionCheckStructureName" required>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="section === 'LoadingonTower'">
      <mat-form-field appearance="fill">
        <mat-label>Select an Equipment</mat-label>
        <mat-select [(ngModel)]="LoadingonTowerEquipment" required>
          <mat-option [value]="'Antenna'">Antenna</mat-option>
          <mat-option [value]="'RRU'">RRU</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Equipment Name</mat-label>
        <input matInput [(ngModel)]="LoadingonTowerEquipmentName" required>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="LoadingonTowerEquipmentName.toLowerCase() == 'equipment'">
        <mat-label>Level Name</mat-label>
        <input matInput [(ngModel)]="LoadingonTowerLevelName" required>
      </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="resultFn()" class="submit">Ok</button>
</div>
