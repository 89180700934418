import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unset',
  templateUrl: './unset.component.html',
  styleUrls: ['./unset.component.css']
})
export class UnsetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
