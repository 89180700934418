import { Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  value : string;
  section : string;
  equipmentData : any[];
  equipmentLevel : string;
}



@Component({
  selector: 'app-edit-equipment',
  templateUrl: './edit-equipment.component.html',
  styleUrls: ['./edit-equipment.component.css']
})
export class EditEquipmentComponent implements OnInit {
    
  value : string;
  section : string;
  equipmentData : any[];
  equipmentLevel : string;
  equipmentDataTemp : any[];

  constructor(public dialogRef: MatDialogRef<EditEquipmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) {
      // console.log("Edit Data", this.data );
      this.value = this.data.value
      this.section = this.data.section
      this.equipmentData = this.data.equipmentData
      this.equipmentDataTemp = this.data.equipmentData
      this.equipmentLevel = this.data.equipmentLevel
      
      }

    ngOnInit(): void {
      
    }

  
    onNoClick(): void {
      let result = this.equipmentDataTemp;
      this.dialogRef.close();
    }
    // numSequence(n: number): Array<number> {
    // //  this.value = [];
    //   return Array(n);
    // }
    editFn(){
    let result = this.equipmentData;
    return result;
    }
  
}
  
