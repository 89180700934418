import { AuthService } from 'src/app/auth.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ThreedviewFetchService {
url;
projectId;
  constructor(private fireStore : AngularFirestore,private http :  HttpClient,public sanitizer: DomSanitizer,private storage : AngularFireStorage,private service: AuthService) {
    this.projectId = this.service.projectID
   }


  fetchData(towerName,siteId,year){
    return this.fireStore.collection('projects'+ '/' + 'projects'+ '/' + this.projectId +'/'+this.projectId+  '/'+ 'sitedata'+ '/' + year+ '/' +towerName+'___'+siteId).doc(siteId).get();
  }


  setData(tower){
    return  this.fireStore.collection(tower).doc(tower);
  }

  towersData(year){
    return this.fireStore.collection('towers').doc(year);
  }

  loadGLTF(link){
    return this.http.get(link);
  }



}
