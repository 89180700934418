<div mat-dialog-title class="dialog-title">
    <h2>Building PDF</h2>
    <button mat-icon-button aria-label="close dialog" id="close-btn" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
<mat-dialog-content>
  <!-- <h2>{{data}}</h2> -->
    <h2>Status: {{data['name']}}</h2>
    <h2>{{data['animal']}}</h2>
    <mat-progress-bar mode="indeterminate" value="40"></mat-progress-bar>
</mat-dialog-content>
