import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { GalleryFetchService } from "./gallery-fetch.service";
import { AddDirComponent } from '../add-dir/add-dir.component';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { EditComponent } from './../edit/edit.component';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  s3;
  projectId = this.auth.projectID

  @ViewChild("content") content;
  tower: string;
  siteId;
  year;
  role;
  dirLink = null;
  view: string;
  imageData = [];
  prevDir = 0;
  crntDir = 0;
  index = 0;
  sliderIndex = false;
  loading = false;
  result;
  data;
  imageDir = [];
  dirValue;
  isLoaded = false;
  bucketName;
  galleryUri;
  constructor(private fireStore: AngularFirestore, private dialog: MatDialog, private route: ActivatedRoute, private service: GalleryFetchService, private renderer: Renderer2, private snackBar: MatSnackBar, private router: Router, private auth:AuthService) {
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }

    this.route.params.subscribe(params => {
      let tw =params.tower.split('___');      
      this.tower = tw[0]
      this.siteId= tw[1];
      this.year = params.year
      this.getTower();
    })
    this.getSetting()
  }

  getSetting(){
    this.fireStore.doc('projects'+ '/' + 'projects'+ '/' + this.projectId + '/' + this.projectId + '/projectsetting/projectsetting').get().subscribe((items: any)=>{
      // console.log(items.data())
      let item = items.data()
      let s3 = new S3({
             accessKeyId: item.s3setting.s3accessid,
             secretAccessKey: item.s3setting.s3accesskey,
             region: item.s3setting.s3region
      })
      this.s3 = s3
      this.bucketName = item.s3setting.s3gallerybucket
      this.galleryUri = item.s3setting.s3galleryuri
    })
  }

  getTower() {
    this.isLoaded = false;
    this.index = 0;
    this.service.fetchData(this.tower,this.siteId,this.year).subscribe(
      (result) => {
        let temp = {};
        temp = result.data();
        console.log(temp);
        
        if (temp['Gallery'] != undefined) {
          this.imageData = temp['Gallery'];
        } else {
          this.imageData = [];
        }
      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if (this.imageData.length > 0) {
          this.isLoaded = true;
        }
      })
  }


  ngOnInit(): void {
  }

  setCrntDir(event) {
    this.crntDir = event.crntDir;
    this.index = event.contentLoadedEvent;

  }
  sendIndex(event) {
    this.sliderIndex = event.sliderIndex;
    this.index = event.index;
  }



  addDir(event) {
    const dialogRef = this.dialog.open(AddDirComponent, {
      width: '500px',
      maxHeight: '480px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result == null || result == undefined) { return; }
        this.dirLink = result;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      async () => {
        let temp = this.imageData;
        let resNw = this.dirLink.split('/')
        // let dirName = this.dirLink ? this.dirLink.split('/')[3] : null;
        let dirName = resNw[resNw.length - 1];
        let firestore = this.fireStore.collection(this.projectId+'/'+this.projectId+'/' +this.tower).doc(this.tower);
        console.log('this.dirLink - ', this.dirLink);
        console.log('dirName - ',dirName);

        var bucketParams = {
          Bucket: this.bucketName,
          // Bucket: "aerodyne-pwc-telco",
          // Bucket: "aerodyne-telstra-dev",
          Prefix: this.dirLink
        };
        this.s3.listObjects(bucketParams, this.s3CallbackFn(this.service,this.tower,this.siteId,this.year,dirName,temp));

      }
    )
  }

s3CallbackFn(service,tower,siteID,year,dirName,temp)
{
  return (err, data) => {
    if (err) {
      this.snackBar.open(err, "close", { duration: 2000 });
    }
    this.imageDir = data.Contents;
    console.log('data.Contents - ', data.Contents);

    let arr = []
    if (this.imageDir.length > 0) {
      for (var i = 0; i < this.imageDir.length; i++) {
        let tempNw = this.imageDir[i].Key.split(".");
        let key = tempNw[tempNw.length - 1];
        console.log('key - ', key);

        if(key == "jpg" || key == "jpeg" || key == "JPG" || key == "JPEG"){
          // https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.ASH.TRANQUILLE/CV/DJI_0541.JPG
          // arr.push({ file: "https://aerodyne-telstra-dev.s3-ap-southeast-2.amazonaws.com/" + this.imageDir[i].Key })
          // arr.push({ file: "https://aerodyne-pwc-telco.s3.amazonaws.com/" + this.imageDir[i].Key })
          arr.push({ file: this.galleryUri  + this.imageDir[i].Key })
        }
      }
      console.log(' arr - ', arr);
      this.imageData = temp;
      this.imageData.push({
        Directory: dirName,
        Payload: arr
      });

      if (arr.length > 0 && dirName != '') {
        service.setData(tower,siteID,year).update({
          Gallery: this.imageData
        }).catch(err => {
          this.snackBar.open(err, "close", { duration: 2000 });
        })
      }else{
        this.snackBar.open('ERROR!', "close", { duration: 2000 });
      }

    }
    this.dirLink = null;
  }
}

  editDir(event) {
    console.log(event);
    const dialogRef = this.dialog.open(EditComponent, {
      width: '450px',
      maxHeight: '480px',
      data: { name: "Directory", value: event.value }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.dirValue = result.value;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        this.imageData[event.index].Directory = this.dirValue;
        this.updateData();
      })
  }

  updateData() {
    this.service.setData(this.tower,this.siteId,this.year).update({
      Gallery: this.imageData
    }).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })
  }

  deleteDir(event) {
    event.forEach(data => {
      this.imageData = this.imageData.filter(elt => {
        return (elt.Directory === data.name) ? false : true;
      });
      console.log(event)
      this.updateData();

    });
  }

}
