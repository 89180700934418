import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinModelServiceService } from "./spin-model-service.service";
import { EditComponent } from './../edit/edit.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-spin-view',
  templateUrl: './spin-view.component.html',
  styleUrls: ['./spin-view.component.css'],
  providers: []
})
export class SpinViewComponent implements OnInit {
  @ViewChild("content") content;
  iframe = true;
  tower : string;
  siteId;
  year
  role;
  view : string;
  imageData = [];
  prevDir;
  crntDir;
  index;
  sliderIndex;
  loaded;
  result;
  data;
  dirValue;
  loading;
  s3Uri;
  loadingStatus = "Loading..."
  constructor(private sanitizer: DomSanitizer,private dialog: MatDialog,private route: ActivatedRoute,private service : SpinModelServiceService, private renderer : Renderer2, private snackBar: MatSnackBar, private router: Router) {
    this.loading = false;
    this.crntDir = 0;
    this.index = 0;
    this.prevDir = 0;
    this.sliderIndex = false;
    this.loaded = false;
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }

    this.route.params.subscribe(params =>{
      let tw =params.tower.split('___');      
      this.tower = tw[0]
      this.siteId= tw[1];
      this.year = params.year;
      this.view = params.view;
      this.loading = false;
      this.crntDir = 0;
      this.index = 0;
      this.prevDir = 0;
      this.sliderIndex = false;
      this.loaded = false;
      this.service.fetchData(this.tower, this.siteId, this.year).subscribe(
        (result)=>{
          let temp = {};
                temp = result.data();
        if(this.view === "towerview"){
          this.view = 'TowerView';
           }
           if(this.view === "topdown"){
            this.view = 'TopDown';
           }
           if(this.view === "los"){
            this.view = 'Los';
           }
           this.imageData = temp[this.view];
        },
        (err)=>{
          this.loadingStatus = "No Data Found"
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        ()=>{
        }
      )
    });
    this.gets3Uri()
  }

  ngOnInit(): void {

  }

  ngAfterViewChecked() {

  }

  gets3Uri(){
    this.service.gets3Setting().subscribe((items: any)=>{
      console.log(items.data())
      let item = items.data()
      this.s3Uri = item.s3setting.s3prependurl
    })
  }

  setCrntDir(event){
    this.crntDir = event.crntDir;
    this.index = event.index;
  }
    sendIndex(event){
      this.sliderIndex = event.sliderIndex;
      this.index = event.index;
    }

    editDir(event){
        const dialogRef = this.dialog.open(EditComponent, {
          width: '450px',
          maxHeight: '480px',
          data: { name:"Directory", value: event.value }
        });
        dialogRef.afterClosed().subscribe(
          (result) => {
            this.dirValue = result.value;
          },
          err => {
            this.snackBar.open(err, "close", { duration: 2000 });
          },
          () => {
            this.imageData[event.index].Directory = this.dirValue;
            this.updateData();
          })
  }

  updateData(){
    let result = {};
    result[this.view] = this.imageData;
    this.service.setData(this.tower, this.siteId, this.year).update(result).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })
  }

  loadMsg(){
setTimeout(()=>{this.loaded = true;},2000);
  }

}
