import { Component, OnInit, ViewChild } from '@angular/core';
import { TableFetchService } from './table-fetch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EditComponent } from './../edit/edit.component'
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProcessDialogComponent } from './process-dialog/process-dialog.component'
import { TowerComponent } from './../tower/tower.component';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

export interface dataTable {
  TowerName: string,
  SiteID: number,
  Status: string,
  Completed: string,
  Latitude: number,
  Longitude: number,
  // StructureType: string,
  // CanradVariation: string,
  Emergency: number,
  Urgent: number,
  Planned: number,
  Monitor: number

  Critical: number,
  Major: number,
  PassStar: number,
  Pass: number
}


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  // displayedColumns: string[] = ['No', 'Tower', 'TowerType', 'SiteID', 'Region', 'Status', 'Completed', 'Latitude', 'Longitude', 'Corrosion', 'StructureType', 'CanradVariation', 'Emergency', 'Urgent', 'Planned', 'Monitor'];//
  displayedColumns: string[];  
  data = [];
  dataSource: MatTableDataSource<dataTable>;
  crntYear = 0;
  role;
  selectedStatus = '';
  isLoaded = false;
  summariesCSV;
  filterPredicate;
  detailsCSV;
  count = 0;
  towerName;
  siteId;
  year;
  result;
  projectID: any;
  objAudit: any;
  isProcessing = false;
  totalPrg = {
    currentTowers : 0,
    totalTowers: 0,
    percentProcessed: 0,
  }
  processedStatus = ['T','D','O','N/A','RESET']
  detailedData
  isIHS
  isAIS
  workbook = new Workbook()
  workSheet = this.workbook.addWorksheet('Summaries');
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('status') status;

  constructor(private service: TableFetchService, private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar,private route: ActivatedRoute) { 

    this.isIHS = this.service.projectId == "ihs_2021_1638616421"
    this.isAIS = this.service.projectId == "AIS Thailand_2022_1662000068"

    if(this.isAIS){
      this.displayedColumns = ['No', 'Tower', 'SiteID', 'Status', 'Completed', 'Latitude', 'Longitude', 'Processed', 'Emergency', 'Urgent', 'Planned', 'Monitor','Critical','Major','PassStar','Pass'];
    }else{
      this.displayedColumns = ['No', 'Tower', 'SiteID', 'Status', 'Completed', 'Latitude', 'Longitude', 'Processed', 'Emergency', 'Urgent', 'Planned', 'Monitor'];
    }
    this.getData()
  }

  ngOnInit(): void {
    this.detailedData = []
    this.projectID = this.service.projectId
    if (this.router.url.split('/')[1] == "aero-processing") {
      this.role = "aero-processing";
    }
    if (this.router.url.split('/')[1] == "user") {
      this.role = 'user';
    }
    if (this.router.url.split('/')[1] == "pilot") {
      this.role = 'pilot';
    }
    if (this.router.url.split('/')[1] == "super-admin") {
      this.role = 'super-admin';
    }
    if (this.router.url.split('/')[1] == "telstra-processing") {
      this.role = 'telstra-processing';
    }

    // this.route.params.subscribe(params => {
    //   this.year = params.year
    // })
    console.log(this.year);
    
  }

  ngAfterViewInit() {
    this.service.fetchTowers().forEach(async (data) => {
      var index = 0 ;
      this.objAudit = []
      for (var j in data.docs) {
        let dataDoc: any = data.docs[j].data()
        this.year = dataDoc.Year
        dataDoc.Towers = dataDoc.Towers.map((obj) => {
          if(!obj.processed){
            obj.processed = 'N/A'
            return obj
          }
          else{
            return obj
          }
        })
        this.data.push(dataDoc);
        if(dataDoc.Towers.length > 0)
        {
          for(var i = 0; i < dataDoc.Towers.length ; i++)
          {
            let towername = dataDoc.Towers[i].TowerName
            const twdata = await this.getTowerData(dataDoc.Towers[i].TowerName,dataDoc.Towers[i].SiteID,this.year).toPromise()
            var monitor = 0;
            var planned = 0;
            var urgent = 0;
            var emergency = 0;

            var critical = 0;
            var major = 0;
            var passStar = 0;
            var pass = 0;

            twdata.forEach((doc: any) => {
              var docu = doc.data()
              if(docu.Audit)
              {
                let auditG = docu.Audit.General
                let auditE = docu.Audit.Equipment
                let audit =  [...auditG,...auditE]
                monitor = audit.reduce(this.calcMonitor,0)
                planned = audit.reduce(this.calcPlanned,0)
                urgent = audit.reduce(this.calcUrgent,0)
                emergency = audit.reduce(this.calcEmergency,0)
              }
              if(docu.Rigger){
                let RiggerG = docu.Rigger.General
                let RiggerE = docu.Rigger.Equipment
                let RiggerTFC = docu.Rigger.TowerFoundationCheck
                let RiggerTVC = docu.Rigger.TowerVerticalityCheck
                let RiggerTC = docu.Rigger.TensionCheck
                let RiggerLOT = docu.Rigger.LoadingonTower

                let Rigger = [...RiggerG,...RiggerE,...RiggerTFC,...RiggerTVC,...RiggerTC,...RiggerLOT]

                pass = Rigger.reduce(this.calcMonitor,0)
                passStar = Rigger.reduce(this.calcPlanned,0)
                major = Rigger.reduce(this.calcUrgent,0)
                critical = Rigger.reduce(this.calcEmergency,0)
              }
            })
            let sObj = {}
            sObj[towername] = {'Monitor' : monitor, 'Planned' : planned, "Urgent": urgent, 'Emergency': emergency,"Pass": pass, "PassStar":passStar, "Major":major, "Critical": critical};          
            if(this.objAudit.length - 1 >= index)
            {
              this.objAudit.splice(index,1,{...this.objAudit[index], ...sObj })
            }
            else{
              this.objAudit.splice(index,1,sObj)
            }
            // this.dataSource.data = [...this.data[this.crntYear]['Towers']]
            console.log(this.dataSource)
            console.log(this.objAudit)
          }
          for(var i = 0;i < this.data[index]['Towers'].length; i++)
            {
              for(var key  in this.objAudit[index]){
                if(this.data[index]['Towers'][i].TowerName == key ){
                  this.data[index]['Towers'][i] = {...this.data[index]['Towers'][i],...this.objAudit[index][key]}
                }
              }
            }
            index++
        }
        else{
          this.objAudit.splice(index,1,{})
          index++
        }
      }
    }).then(() => {
      
      this.dataSource = new MatTableDataSource(this.data[this.crntYear]['Towers']);
      console.log(this.data);
      
      this.filterPredicate = this.dataSource.filterPredicate;
      setTimeout(() => this.dataSource.sort = this.sort);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      this.isLoaded = true;
      // this.summariesCSV = ['Tower', 'SiteID', 'Status', 'Completed', 'Latitude', 'Longitude', 'Processed'].join(",") + "\n";
      // for (let j = 0; j < this.data[this.crntYear]['Towers'].length; j++) {
      //   this.summariesCSV += this.data[this.crntYear]['Towers'][j]["TowerName"] + ','  + this.data[this.crntYear]['Towers'][j]["SiteID"] +  ',' + this.data[this.crntYear]['Towers'][j]["Status"] + "," + this.data[this.crntYear]['Towers'][j]["Completed"] + ',' + this.data[this.crntYear]['Towers'][j]["Latitude"] + ',' + this.data[this.crntYear]['Towers'][j]["Longitude"] + ',' + this.data[this.crntYear]['Towers'][j]["processed"]  + "\n";
      // }
      this.workSheet.addRow(['Tower', 'SiteID', 'Status', 'Completed', 'Latitude', 'Longitude', 'Processed'])
      for (let j = 0; j < this.data[this.crntYear]['Towers'].length; j++) {
          this.workSheet.addRow([this.data[this.crntYear]['Towers'][j]["TowerName"] , this.data[this.crntYear]['Towers'][j]["SiteID"] , this.data[this.crntYear]['Towers'][j]["Status"] , this.data[this.crntYear]['Towers'][j]["Completed"] , this.data[this.crntYear]['Towers'][j]["Latitude"] , this.data[this.crntYear]['Towers'][j]["Longitude"] , this.data[this.crntYear]['Towers'][j]["processed"] ]);
      }
      this.adjustColumnWidth(this.workSheet)
      let towerDetails = this.data[this.crntYear]['Towers']
      towerDetails.forEach(tower => {
        this.service.fetchFullData(tower.TowerName, tower.SiteID).forEach(data => {
          data.forEach(doc => {
            if(Object.keys(doc.data()).length > 2) this.detailedData.push({...doc.data()['Audit'], 'tower' : tower.TowerName});
          })
        })
      })
    });

  }

  adjustColumnWidth(worksheet) {
    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number')) + 2;
      column.width = maxLength;
    });
  } 

  calcMonitor(total,obj) {
    return total + obj.Details.filter((record) => {
      return (record.Option === 1 && record.Value != null); }).length
  }

  calcPlanned(total,obj) {
    return total + obj.Details.filter((record) => {
      return (record.Option === 2 && record.Value != null); }).length
  }

  calcUrgent(total,obj) {
    return total + obj.Details.filter((record) => {
      return (record.Option === 3 && record.Value != null); }).length
  }

  calcEmergency(total,obj) {
    return total + obj.Details.filter((record) => {
      return (record.Option === 4 && record.Value != null); }).length
  }


  switchStatus(event) {
    console.log(event.target.value)
    this.sort.sort(({ id: 'Status' }) as MatSortable);
    this.sort.direction = event.target.value;
    this.sort.disableClear = false;
    this.dataSource.sort = this.sort;
  }

  inspectedList(elt) {
    return elt.Status === 'Inspected';
  }

  unInspectedList(elt) {
    return elt.Status === 'Uninspected';
  }

  switchTable() {
    this.isLoaded = false;
    this.dataSource = new MatTableDataSource(this.data[this.crntYear]['Towers']);
    this.filterPredicate = this.dataSource.filterPredicate;
    setTimeout(() => this.dataSource.sort = this.sort);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    this.isLoaded = true;

    this.generateExcel()
  }

  generateExcel(){
    this.workbook.removeWorksheet("Summaries")
    this.workSheet = this.workbook.addWorksheet('Summaries');
    this.workSheet.addRow(['Tower', 'SiteID', 'Status', 'Completed', 'Latitude', 'Longitude', 'Processed'])
    for (let j = 0; j < this.data[this.crntYear]['Towers'].length; j++) {
        this.workSheet.addRow([this.data[this.crntYear]['Towers'][j]["TowerName"] , this.data[this.crntYear]['Towers'][j]["SiteID"] , this.data[this.crntYear]['Towers'][j]["Status"] , this.data[this.crntYear]['Towers'][j]["Completed"] , this.data[this.crntYear]['Towers'][j]["Latitude"] , this.data[this.crntYear]['Towers'][j]["Longitude"] , this.data[this.crntYear]['Towers'][j]["processed"] ]);
    }
    this.adjustColumnWidth(this.workSheet)
  }

  downloadCSV(name) {
    let today = name + '_' + this.data[this.crntYear].Year + '_' + Math.round(Date.now() / 1000)
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, today+'.xlsx');
    });
  }

  getTotal(issue) {
    // this.count = 0;
    // this.data[this.crntYear]['Towers'].forEach(record => {
    //   if (record[issue]) {
    //     this.count += parseInt(record[issue])
    //   }
    // });
    // return this.count;
    var count = 0;
    for(var key in this.objAudit[this.crntYear]){
        count += this.objAudit[this.crntYear][key][issue]
    }
    return count
  }
  applyStatusFilter() {
    //  this.dataSource.filterPredicate = this.filterPredicate;

    let filterValue = this.selectedStatus;
    this.dataSource.filter = filterValue;
    this.status.nativeElement.click();
    if (this.dataSource.paginator) {
      if (this.dataSource.filter === 'inspected') {

      }
      this.dataSource.paginator.firstPage();

    }
  }


  applyFilter(event: Event) {

    // this.dataSource.filterPredicate =
    // (data: dataTable, filter: string) => !filter || data.Status == filter;

    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    this.status.nativeElement.click();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  sortData(sort: Sort){
    const data = this.data[this.crntYear]['Towers'];
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
      return;
    }

    this.dataSource = new MatTableDataSource(data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Tower': return this.compare(a.TowerName, b.TowerName, isAsc);
        case 'SiteID': return this.compare(a.SiteID, b.SiteID, isAsc);
        case 'Status': return this.compare(a.Status, b.Status, isAsc);
        case 'Completed': return this.compare(a.Completed, b.Completed, isAsc);
        case 'Latitude': return this.compare(a.Latitude, b.Latitude, isAsc);
        case 'Longitude': return this.compare(a.Longitude, b.Longitude, isAsc);
        case 'Longitude': return this.compare(a.Longitude, b.Longitude, isAsc);
        case 'Emergency': return this.compare(a.Emergency, b.Emergency, isAsc);
        case 'Urgent': return this.compare(a.Urgent, b.Urgent, isAsc);
        case 'Planned': return this.compare(a.Planned, b.Planned, isAsc);
        case 'Monitor': return this.compare(a.Monitor, b.Monitor, isAsc);
        case 'Processed': return this.compare(a.processed, b.processed, isAsc);
        default: return 0;
      }
    }))
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  clickEvent(value, event, condition, id, status, field) {
        
    event.stopPropagation();
    if (condition == false) {
      if (status == 'Inspected' && this.role !== 'pilot') {
        this.router.navigate([this.role + '/' + this.data[this.crntYear].Year + '/' + value +'___'+id + '/' + '/summary']);
      }
      else if (status === 'Uninspected' && this.role === 'pilot') {
        this.router.navigate([this.role+'/'+ this.data[this.crntYear].Year  +'/' + value +'___'+id + '/' + '/upload']);
      } 
      else if (status === 'Inspected' && this.role === 'pilot') {
        this.snackBar.open('This Site is Inspected!', "close", { duration: 2000 });
      }else {
        this.snackBar.open('This Site is not Inspected yet!', "close", { duration: 2000 });
      }
    }
    if (condition == true && id != null) {
      const dialogRef = this.dialog.open(EditComponent, {
        width: '300px',
        data: { value: value, name: field }
      });
      dialogRef.afterClosed().subscribe(
        result => {
          this.result = result.value;
        },
        (err) => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
          this.data[this.crntYear]['Towers'].forEach(elt => {
            if (elt.SiteID == id) {
              elt[field] = this.result;
            }
          });
          this.service.setData(this.data[this.crntYear].Year).set(
            this.data[this.crntYear],
            {
              merge: true
            }
          );

          this.switchTable()

        }
      );
    }
  }

  monitor(record) {
    return (record.Option === 1 && record.Value != null);
  }
  planned(record) {
    return (record.Option === 2 && record.Value != null);
  }
  urgent(record) {
    return (record.Option === 3 && record.Value != null);
  }
  emergency(record) {
    return (record.Option === 4 && record.Value != null);
  }

  getTowerData(towerName,siteId,year) {
    return this.service.fetchAuditData(towerName, siteId, year)
  }

  getIssueCount(towerName,issue){
    if(this.objAudit[this.crntYear] != undefined){
     for(var key in this.objAudit[this.crntYear]){
         if(key == towerName){
           return this.objAudit[this.crntYear][key][issue]
         }
     }
    }
  }

  openProcessedDialog(){
    let dialogRef = this.dialog.open(ProcessDialogComponent, {
      height: 'auto',
      width: '600px',
      data: {
        data: this.data,
        yearIdex: this.crntYear,
        siteID: this.siteId,
        year: this.year
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async getProcessedStatuss(){
    this.isProcessing = true
    var percentToUpdate = 100 / this.data[this.crntYear]['Towers'].length
    var percentGoing = 0
    this.totalPrg.totalTowers = this.data[this.crntYear]['Towers'].length
    for(var i = 0;i < this.data[this.crntYear]['Towers'].length;i++){
      try{
        let res: any = await this.service.fetchData(this.data[this.crntYear]['Towers'][i].Tower.replace(/\s+/g, ''),this.siteId,this.year).toPromise()
        let processedStatus = ''
        let temp = res.data();
        if(temp != undefined)
          {
            let towerView = temp['TowerView']
            if(towerView.length > 0){
              processedStatus = processedStatus + "T"
            }
            let dg =  temp['DigitalTwin'];
            if(dg.Link != ''){
              processedStatus = "D"
            }
            let ortho = temp['OrthoMap']
            if(JSON.stringify(ortho) != '{}'){
              processedStatus = processedStatus + "O"
            }
            if(processedStatus == ""){
              this.data[this.crntYear]['Towers'][i].processed = processedStatus
            }
            else{
              this.data[this.crntYear]['Towers'][i].processed = processedStatus
            }
          }
          else{
            this.data[this.crntYear]['Towers'][i].processed = "-"
            this.snackBar.open("Tower data not available", "close", { duration: 2000 });
        }
        // await this.service.setData(this.data[this.crntYear].Year).set(
        //   this.data[this.crntYear],
        //   {
        //     merge: true
        //   }
        // );
        percentGoing = percentGoing + percentToUpdate
        this.totalPrg.percentProcessed = Math.floor(percentGoing)
        this.totalPrg.currentTowers = i + 1
      }
      catch(err){
        console.log(err.message)
      }
    }
    this.isProcessing = false
    this.snackBar.open("Show Processed data completed", "close", { duration: 2000 });
  }

  getData() {
    this.route.params.subscribe(params => {
      console.log(params);
      let tw =params.tower.split('___');      
      this.towerName = tw[0]
      this.siteId= tw[1];
      this.year = params.year;
      console.log(this.year);
      

    });
  }

  
  applyProcessedStatusFilter(filterValue: string) {
    if(filterValue != 'RESET'){
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
    else{
      this.dataSource.filter = "";
    }
  }

  setupFilter(column: string) {
    this.dataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toUpperCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  reportDataArrange(item){
    console.log(item);
    
    let  arranged = []; 
    arranged.push({general: 'sdfs'})
    arranged.push({tower: 'towerName'})
    return arranged
  }
  downloadExcelReport = () =>{
    // console.log(this.detailedData);
    // let workbook = new Workbook();
    // this.detailedData.forEach(_item => {
    //   console.log(_item);
    //   let workSheet = workbook.addWorksheet(`${_item.tower}`);

    //   workSheet.addRow(['General'])
    //   _item['General'].forEach(g_item => {
    //     workSheet.addRow(['',`${g_item.Name}`])
    //     g_item.Details.forEach(gd_item => {
    //       workSheet.addRow(['','',`${gd_item.Name}`, `${gd_item.Value}`])
    //     })
    //   })
    //   workSheet.addRow([' '])

    //   workSheet.addRow(['Equipment'])
    //   _item['Equipment'].forEach(e_item => {
    //     workSheet.addRow(['',`${e_item.Name}`])
    //     e_item.Details.forEach(ed_item => {
    //       workSheet.addRow(['','',`${ed_item.Name}`, `${ed_item.Value}`])
    //     })
    //   })
      
    // })

    // let today = new Date().toLocaleDateString()
    // workbook.xlsx.writeBuffer().then((data) => {
    //   let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   fs.saveAs(blob, today+'.xlsx');
    // });
    const url= "https://firebasestorage.googleapis.com/v0/b/vertikaliti-admin.appspot.com/o/template%2Fdata.ods?alt=media&token=189a30f8-5515-4f92-9654-7f1bed066aa1"
    window.open(url);
   
  }


}
