<h1 mat-dialog-title>Add Directory</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Link</mat-label>
    <input matInput [(ngModel)]="link" required>
    <mat-hint align="start"><strong>Eg : 'towers_data/TWR.ASH.TRANQUILLE/CV' (Path as it is in AWS-s3 Bucket)</strong> </mat-hint>
  </mat-form-field>
</div>
<br>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="resultFn()" cdkFocusInitial class="submit">Ok</button>
</div>
