import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { UploadService } from './upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';


import * as S3 from 'aws-sdk/clients/s3';
// import { ExDataService } from './ex-data.service';
import { AuthService } from 'src/app/auth.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';
import { AngularFirestore } from '@angular/fire/firestore';



/** File node data with possible child nodes. */
export interface FileNode {
  name: string;
  type: string;
  dir: string;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  name: string;
  type: string;
  level: number;
  dir: string;
  expandable: boolean;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],

})

export class UploadComponent implements OnInit {

  showTree: boolean = false;     
  imgURL;           

  tabIndex = 0;
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;



  s3: any;
  projectSetting: any;

  testData = "akhil";
  uploadList;
  tempUploadList = [];
  tempUploadList1 = [];

  existingFiles;
  testArr = ['name1', 'name2'];
  towerName;
  siteId;
  projectID;
  role;
  year;
  currUploadNumber;
  totalNumberOfFiles;
  percentUpload;
  uploadVis = 'visible';
  uploadedHeadVis = 'hidden';
  request: any;
  uploading = false;
  showLoading: any = false;
  roll: any;
  displayImageName;
  showImageTitle = false;
  constructor(private upload: UploadService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    // public exData: ExDataService,
    private service: AuthService,private afs: AngularFirestore,private ref: ChangeDetectorRef) {
    this.projectID = this.service.projectID
    this.getSetting()
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    // this.dataSource.data = files;
    this.getData()

  }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.upload.getS3();
    // console.log('test Data - ', this.testData);
    // console.log(this.service.projectsetting['S3']);
    setTimeout(() => {
      
      this.showValue();
    }, 1000);
  }

  testData1() {
    console.log('work');

  }

  loadData(){
    this.upload.getS3()
  }

  getSetting(){
    this.afs.doc('projects'+ '/' + 'projects'+ '/' + this.projectID + '/' + this.projectID + '/projectsetting/projectsetting').get().subscribe((items: any)=>{
      // console.log(items.data())
      let item = items.data()
      let s3 = new S3({
             accessKeyId: item.s3setting.s3accessid,
             secretAccessKey: item.s3setting.s3accesskey,
             region: item.s3setting.s3region
      })
      this.projectSetting = item.s3setting
      this.s3 = s3
    })
  }

  showFile(filename,dir, event = ''){
    this.showImageTitle = false
    if(event != ''){
      var selected = document.getElementsByClassName("matSelected");
      while (selected.length)
      {
        selected[0].classList.remove("matSelected");
      }
      let e: any = event
      e.srcElement.parentElement.classList.add('matSelected')
    }
    this.showLoading = true
    this.imgURL = ""
    let data = this.dataSource.data[0].children
    // console.log(data);
    let img = dir + '/' + filename
    setTimeout(() => {            
      this.imgURL = this.upload.getImageUrl(img);
      this.showLoading = false
      this.displayImageName = filename
      this.showImageTitle = true
    },500)
  }

  openUploadDialog(){
    let dialogRef = this.dialog.open(DialogUploadComponent, {
      height: '500px',
      width: '1200px',
      disableClose: true,
      data: {
        towerName : this.towerName + "___" + this.siteId,
        siteID: this.siteId,
        year: this.year,
        projectID: this.projectID
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.showValue();
      }, 1000);
    });
  }

   reload(){
    this.roll = "an_infi"
    setTimeout(() => {
      
      this.showValue();
      this.roll = "anfini"
    }, 1000);
  }

  showValue(){
    this.showTree = true;
    console.log(this.showTree);
    
    this.upload.buildData()

    let treeD = this.upload.generateObj();
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);

    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = treeD;
    console.log(treeD);
    
    // console.log(this.upload.generateObj());
    
       
  }

  openDialog() {

    if (this.uploading) {

      let dialogRef = this.dialog.open(DialogUploadComponent);
      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if (result == "true") {
          console.log('abort upload');
          this.request.abort()
          // this.s3.abortMultipartUpload('telstra-testing', )
          // this.s3.abortMultipartUpload()
          // this.tempUploadList.length = 0;
          // this.uploadList.length = 0;
          console.log(this.tempUploadList.length - this.currUploadNumber);


          this.uploading = false;

        }
      })
    }

  }


  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number): FlatTreeNode {
    return {
      name: node.name,
      type: node.type,
      level,
      dir : node.dir,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode): number {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }


  uploadFolders(files) {
    // console.log(files);
    this.uploading = true;
    if (files != null) {
      this.uploadList = files;
      this.currUploadNumber = 0;
      this.percentUpload = 0;
    }
    this.uploadVis = 'hidden';
    this.uploadedHeadVis = 'visible';
    // console.log('this.uploadVis - ', this.uploadVis)
    // console.log('this.uploadList - ', this.uploadList)
    this.totalNumberOfFiles = this.uploadList.length

    //change this later
    this.tempUploadList = [];

    for (let i = 0; i < this.uploadList.length; i++) {
      let path = this.uploadList[i].webkitRelativePath;
      // console.log(this.uploadList[i]);

      this.tempUploadList.push({ filename: this.uploadList[i].name, progress: 0 });
      this.uploadS3(path, this.uploadList[i])
    }
    // console.log(this.uploadList[0]);
    
    this.upload.pilotDirectory(this.towerName, this.siteId,this.year,this.projectID, this.uploadList)
    this.uploadList = []


  }

  uploadS3(path, file) {
    let path1 = path.substring(path.indexOf('/'), path.length)
    this.upload.buildS3Structure(path1)
    
    this.tempUploadList1.push(path1)
    path = path.split('/').pop()
    let nm_nw = (this.towerName);
    
    this.request = this.s3.putObject({
      Bucket: "telstra-testing",
      Key: nm_nw + path1,
      Body: file,
    });
    let d;
    this.request.on('httpUploadProgress', function (progress) {
      // console.log(progress.loaded + " of " + progress.total + " bytes");
      // console.log(Math.round(progress.loaded/progress.total*100)+ '% done');
      d = Math.round(progress.loaded / progress.total * 100)
      push_data(d, path)
      // console.log(d);
    });
    let push_data = (data, p) => {
      let index = this.tempUploadList.map((o) => {
        return o.filename;
      }).indexOf(p)


      this.tempUploadList[index].progress = data

    }

    this.request.send((res) => {
      // null if successful
      // console.log('res - ', res);
      // RequestTimeTooSkewed: The difference between the request time and the current time is too large.
      // IF THIS ERROR, SHOW MESSAGE
            
      if (res == null) {

        this.tempUploadList[this.currUploadNumber].progress = 100;

        this.currUploadNumber = this.currUploadNumber + 1;
        this.percentUpload = Math.round(((this.currUploadNumber / this.totalNumberOfFiles) * 100) * 10) / 10
        this.snackBar.open('Uploaded - ' + this.percentUpload + '% OR ' + this.currUploadNumber + '/' + this.totalNumberOfFiles + ' files', "close", { duration: 5000 });

        if (this.currUploadNumber == this.totalNumberOfFiles) {
          this.snackBar.open('Redirecting to upload page in a moment', '', { duration: 5000 })
          this.uploading = false;
          this.loadData()
        }
      }
      else {
        this.uploadVis = 'visible';
        this.uploadedHeadVis = 'hidden';
        this.snackBar.open('Error - ' + res, "close", { duration: 5000 });
        console.log('ERROR - ', res);

      }

    });
  }

  getEventMessage = (event: HttpEvent<any>, formData) => {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event)
    }
  }

  fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    console.log(percentDone);

    return { status: 'progress', message: percentDone }
  }


  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.getFilesDataTransferItems(event.dataTransfer.items)
    }
  }
  getFilesDataTransferItems(items) {
    for (let item of items) {
      let temp = item.webkitGetAsEntry();
      console.log(temp);

      this.findFile(temp, temp.fullPath)
    }
  }

  findFile(item, dir) {
    if (item.isFile) {
      item.file(file => {
        file.filepath = dir;
        console.log(file)
        this.uploadS3(dir.replace("/", ""), file);
      });
    }
    if (item.isDirectory) {
      let dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (let entry of entries) {
          this.findFile(entry, entry.fullPath);
        }
      });
    }
  }

  getData() {
    this.route.params.subscribe(params => {
      console.log(params);
      this.projectID = params.projectID
      let tw =params.tower.split('___');      
      this.towerName = tw[0]
      this.siteId= tw[1];
      this.year = params.year;
      console.log(this.year);
      this.upload.towerName = params.tower;
    });
  }

}
