<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>
<div class="digitaltwin">
  <div class="digitaltwinTab">
    <ng-container *ngIf="Link == null">
      <div style="float: right; float: right; z-index: 5; position: absolute; right: 15px; top: 15px;">
      <div style="height: 120px;width: 400px;border: limegreen solid 2px; border-radius: 10px;background-color: rgb(68, 69, 70);">
        <div style="display:flex; justify-content: space-between;">
          <div class="compass-container">
            <div class="compass"> 
              <div class="arrow" #compass_arrow></div>
              <div class="disc" id="compassDiscImg"></div>
            </div>
          </div>
          <div class="control-btn">
            <div class="toggle_radio">
              <input type="radio" class="toggle_option" id="first_toggle" name="toggle_option" checked (change)="onItemChange($event.target.value)" value="Rotate">
              <input type="radio" class="toggle_option" id="second_toggle" name="toggle_option"(change)="onItemChange($event.target.value)" value="Pan">
              <label for="first_toggle">Rotate</label>
              <label for="second_toggle">Pan</label>
              <div class="toggle_option_slider"></div>
            </div>
            <div class="btns">
              <button (click)="zoomOutMouseDwn($event)" class="btn1">-</button>
              <button (click)="zoomInMouseDwn($event)" class="btn2">+</button>
            </div>
          </div>
          <div class="arrows" style="padding-top: 16px;margin-right: -19px;margin-left: 10px;">
            <div class="arrow-up" (click)="upBtnClick($event)" ></div>
            <div class="arrow-down" (click)="downBtnClick($event)"></div>
            <div class="arrow-left" (click)="leftBtnClick($event)" ></div>
            <div class="arrow-right" (click)="rightBtnClick($event)"></div>
            <!-- <div class="select-box" (click)="recenterBtnClick($event)"></div> -->
            <div class="select-box" (click)="recenterBtnClick()"></div>
          </div>
        </div>
      </div>
    </div>
      <div class="canvasContainer" #container (click)="clickEvent($event)">
        <div style="position:relative;" [ngClass]="glbLoaderPrg">
          <p style="color: white;z-index: 6;position:absolute;right:48%;top:36vh">{{ percentageLoaded.prtLd }} %</p>
          <mat-progress-bar mode="determinate" style="z-index: 5;height:20px;border-radius:5px;position:absolute;top:34vh;left:150px;width:82vw;background-color: #274545;" [value]="percentageLoaded.prtLd"></mat-progress-bar>
        </div>
        <div style="position:relative;" [ngClass]="glbLoaderPrgHigh">
          <div style="position:absolute;top:75vh;width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;z-index: 6;">
            <p style="color: white;z-index: 6;font-size: 16px">Asset details available in high resolution model</p>
            <mat-progress-bar mode="determinate" style="z-index: 5;height:10px;border-radius:5px;width:50vw;background-color: #274545;" [value]="percentageLoaded.prtLdHigh"></mat-progress-bar>
            <p style="color: white;z-index: 6;font-size: 16px;margin-top:10px">Loading high resolution version   {{ percentageLoaded.prtLdHigh }} %</p>
          </div>
        </div>
      </div>
      <div *ngIf="!Isdigi" style="position: absolute;width:100%;text-align:center;top: 34vh;left: 0vw;font-size: 35px;color: #000000">
        <p>{{IsDigiError}}</p></div>

      <!--Features-->
      <mat-button-toggle-group [(value)]="selectedFeature" class="features mat-elevation-z8" name="features">
        <mat-button-toggle [value]="1">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 1}">map</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 1}">Site Data <br></p>
        </mat-button-toggle>
        <mat-button-toggle *ngIf="outDoorEquipments.length > 0" [value]="2">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 2}">input</mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 2}">OutDoor <br>Equipment</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="3">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 3}">settings_suggest
          </mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 3}">Equipment <br>List</p>
        </mat-button-toggle>
        <mat-button-toggle [value]="4" *ngIf="tower === 'ESPERANCERT'">
          <mat-icon class="featuresIcon" [ngClass]="{'selectedFeatureIcon' : selectedFeature == 4}">edit
          </mat-icon>
          <p class="featuresText" [ngClass]="{'selectedFeature' : selectedFeature == 4}">Edit Mode</p>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <!--SiteData-->
      <mat-card class="siteData equip mat-elevation-z8" *ngIf="selectedFeature == 1 && siteData">
        <mat-card-header class="siteDataHeader">
          <mat-card-title>
            Site Data
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="siteDataContent">
          <table class="siteDataTable">
            <tr class="sRow">
              <td>Site Name :</td>
              <td>{{siteData[sumArray[0][0]].Details[sumArray[0][1]].Value || 'N/A'}}</td>
            </tr>
            <tr class="sRow">
              <td>Site ID :</td>
              <td>{{siteData[sumArray[1][0]].Details[sumArray[1][1]].Value || 'N/A'}}</td>
            </tr>
            <tr class="sRow">
              <td>Structure Type :</td>
              <td>{{siteData[sumArray[7][0]].Details[sumArray[7][1]].Value || 'N/A'}}</td>
            </tr>
            <tr class="sRow">
              <td>Structure Height :</td>
              <td>{{siteData[sumArray[8][0]].Details[sumArray[8][1]].Value || 'N/A'}}</td>
            </tr>
            <tr class="sRow">
              <td>Latitude :</td>
              <td>{{siteData[sumArray[5][0]].Details[sumArray[5][1]].Value.split(" ").length > 1 ? siteData[sumArray[5][0]].Details[sumArray[5][1]].Value?.split(" ")[0] : siteData[sumArray[5][0]].Details[sumArray[5][1]].Value}}</td>
            </tr>
            <tr class="sRow">
              <td>Longitude :</td>
              <td>{{siteData[sumArray[5][0]].Details[sumArray[5][1]].Value.split(" ").length > 1 ? (siteData[sumArray[5][0]].Details[sumArray[5][1]].Value?.split(" ")[1]) : siteData[sumArray[6][0]].Details[sumArray[6][1]].Value}}</td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>


      <!--Out-Door Equipments-->
      <mat-card class="siteData equip mat-elevation-z8" *ngIf="selectedFeature == 2">
        <mat-card-header class="outDoorHeader">
          <mat-card-title>
            Out-Door Equipments
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="siteDataContent">
          <mat-button-toggle-group [(value)]="selectedShelter" class="shelter mat-elevation-z8" name="shelter">
            <mat-button-toggle [value]="i" class="oRow" *ngFor="let item of outDoorEquipments;let i = index;"
              (click)="openModal(item.Link)">
              <p [ngClass]="{'selectedFeature' : selectedShelter == i}">{{i+1}}</p>
              <p [ngClass]="{'selectedFeature' : selectedShelter == i}">{{item.Name}}</p>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-card-content>
      </mat-card>

      <!--Equipments List-->
      <mat-card class="siteData equip mat-elevation-z8" *ngIf="selectedFeature == 3">
        <mat-card-header class="equipmentListBoxHeader">
          <mat-card-title>
            Equipment List
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="equipmentListBoxContent">
          <mat-button-toggle-group *ngIf="auditData" [(value)]="selectedEquipment" class="shelter mat-elevation-z8"
            name="shelter">
            <mat-button-toggle *ngFor="let item of auditData.Equipment;let i = index;"
              (click)="toggleEquipments(item.Name,i)" [value]="i" class="eRow">
              <div class="td1" [ngClass]="{'selectedFeature' : selectedEquipment == i}">{{i+1}}</div>
              <div class="td2" [ngClass]="{'selectedFeature' : selectedEquipment == i}">{{item.Name}}</div>
              <div class="td3 equipmentsIcon" [ngClass]="{'selectedFeature' : selectedEquipment == i}"
                (click)="toggleAsset($event,item.Name)">
                <mat-icon>visibility</mat-icon>
                <mat-icon style="display: none;">visibility_off</mat-icon>
              </div>
              <div class="td4 equipmentsIcon">
                <mat-icon style="color:grey;" (click)="toggleWaves($event,item.Name)">sensors</mat-icon>
                <!-- <mat-icon style="color:grey;" *ngIf="item.Name.startsWith('RRU') == 1;">sensors_off</mat-icon> -->
              </div>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </mat-card-content>
      </mat-card>

      <!--Equipment Box-->
      <mat-card class="equipment equip right mat-elevation-z8"
        *ngIf="selectedFeature == 3 && selectedEquipment != null && isOpen === true">
        <mat-card-header class="equipmentHeader">
          <mat-card-title>
            {{assetData.Name}}
          </mat-card-title>
          <i (click)="closeBox()">
            <mat-icon class="closeIcon">close</mat-icon>
          </i>
        </mat-card-header>
        <mat-card-content class="equipmentContent">
          <div class="imgDiv" *ngIf="assetData.Details[detailImageIndex].Image.length > 0">
            <span class="arrowLeft" *ngIf="assetData.Details[detailImageIndex].Image.length > 1" (click)="nextImg()">
              <button class="iconBtn" mat-mini-fab>
                <mat-icon class="iconLeft">keyboard_arrow_left</mat-icon>
              </button>
            </span>
            <span class="arrowRight" *ngIf="assetData.Details[detailImageIndex].Image.length > 1" (click)="prevImg()">
              <button class="iconBtn" mat-mini-fab>
                <mat-icon class="iconRight">keyboard_arrow_right</mat-icon>
              </button>
            </span>
            <img class="img-fluid" [src]="assetData.Details[detailImageIndex].Image[crntImg]">
          </div>
          <div class="detailsDiv">
            <table mat-table [dataSource]="assetData.Details" class="equipmentTable mat-elevation-z8">

              <!-- Name Column -->
              <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">
                  <div *ngFor="let item of element.Name.split(' ');let i = index"><span class="padd">{{item}}</span>
                  </div>
                </td>
              </ng-container>
              <ng-container
                *ngIf="role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin'"
                matColumnDef="NameEdit">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let element;let i = index">
                  <mat-icon class="editIcon" (click)="editData('Name',element.Name,'Equipment',i,assetDataIndex)"> edit
                  </mat-icon>
                </td>
              </ng-container>

              <!-- Value Column -->
              <ng-container matColumnDef="Value">
                <th mat-header-cell *matHeaderCellDef> Value </th>
                <td mat-cell *matCellDef="let element">
                  <p class="roundedBoxD"
                    [ngClass]="{'monitor' : element.Option == 1,'planned' : element.Option == 2,'urgent' : element.Option == 3,'emergency' : element.Option == 4}">
                    {{element.Value}}
                  </p>
                </td>
              </ng-container>
              <ng-container
                *ngIf="role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin'"
                matColumnDef="ValueEdit">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let element;let i = index">
                  <mat-icon class="editIcon"
                    (click)="editData('Value',element.Value,'Equipment',i,assetDataIndex,false)"> edit</mat-icon>
                </td>
              </ng-container>

              <!-- Image Column -->
              <ng-container matColumnDef="Image">
                <th mat-header-cell *matHeaderCellDef> Image </th>
                <td mat-cell *matCellDef="let element;let i = index">
                  <mat-icon *ngIf="element.Image.length" class="imageIcon" (click)="setImage(i)">description</mat-icon>
                </td>
              </ng-container>
              <ng-container
                *ngIf="role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin'"
                matColumnDef="ImageEdit">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let element;let i = index">
                  <mat-icon class="editIcon"
                    (click)="editData('Image',element.Image,'Equipment',i,assetDataIndex,false)"> edit</mat-icon>
                </td>
              </ng-container>


              <tr mat-header-row
                *matHeaderRowDef="(role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin')  ? displayedColumns: displayedColumnsUser">
              </tr>
              <tr mat-row
                *matRowDef="let row; columns: (role === 'aero-processing' || role === 'telstra-processing' || role === 'super-admin')  ? displayedColumns: displayedColumnsUser">
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>


      <!--Edit Mode-->
      <mat-card class="editMode equip mat-elevation-z8" *ngIf="selectedFeature == 4">
        <mat-card-header class="siteDataHeader">
          <mat-card-title>
            Edit Mode
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="siteDataContent">
          <!-- <div class="editEquipmentdiv">
            <mat-form-field class="editEquipmentSelect" appearance="fill">
              <mat-label>Select Mode</mat-label>
              <mat-select [(ngModel)]="editMode">
                <mat-option [value]="'Add'">Add Equipment</mat-option>
                <mat-option [value]="'Edit'">Edit Equipment</mat-option>
                <mat-option [value]="'Delete'">Delete Equipment</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-divider></mat-divider> -->
          <div class="editModediv" *ngIf="editMode == 'Add'">
            <mat-form-field class="editEquipmentSelect">
              <mat-label>Equipment</mat-label>
              <mat-select [(ngModel)]="addEquipment">
                <mat-option [value]="'Antenna1'">Antenna</mat-option>
                <mat-option [value]="'Dish1'">Dish 1</mat-option>
                <mat-option [value]="'Dish2'">Dish 2</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="addEquipments()">
              <mat-icon>add</mat-icon>Add
            </button>
          </div>
          <div style="display:flex;flex-direction : column;"><!-- *ngIf="editMode == 'Edit'" -->
            <mat-form-field style="padding: 0 25px;">
              <mat-label>Radius</mat-label>
              <input matInput>
            </mat-form-field>
            <mat-form-field  style="padding: 0 25px;">
              <mat-label>Width</mat-label>
              <input matInput>
            </mat-form-field>
            <mat-form-field  style="padding: 0 25px;">
              <mat-label>Height</mat-label>
              <input matInput>
            </mat-form-field>
            <br>
            <div style="width: 100%;display: flex;justify-content: space-evenly;padding: 15px 0;">
              <button mat-raised-button color="primary" (click)="scale()">
                Scale
              </button>
              <button mat-raised-button color="accent" (click)="translate()">
                Translate
              </button>
              <button mat-raised-button color="warn" (click)="rotate()">
                Rotate
              </button>
            </div>

            <button mat-stroked-button color="warn" (click)="removeControls()">
              Remove Controls
            </button>
          </div>
          <div class="editModediv" *ngIf="editMode == 'Delete'">

          </div>
        </mat-card-content>
      </mat-card>



      <!-- <mat-card class="visibilitySlider">
        <span class="sliderText">PC</span>
        <mat-slider color="primary" class="slider" thumbLabel [displayWith]="" tickInterval="1000" step="0.01" value='0'
          min="-1" max="1" (change)="visibilitySlider($event)" (input)="visibilitySlider($event)"></mat-slider>
        <span class="sliderText">CAD</span>
      </mat-card> -->


    </ng-container>


    <!--   <ng-container class="viewContainer" *ngIf="Link == null && auditData.Equipment.length == 0">
      <div class="else">
        {{ loadMsg() }}
        <h1 *ngIf="loaded == true">No Data Found</h1>
      </div>
    </ng-container> -->

    <ng-container *ngIf="Link != null">
      <iframe style="width: 100%;height: 100%;" [src]="urlSafe"></iframe>
    </ng-container>


  </div>
</div>
