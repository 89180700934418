<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>


<div class="orthomap" >
  <div class="orthomapTab">
    <agm-map *ngIf="isLoaded" [minZoom]="minZoom" [latitude]="latLong1.lat" [longitude]="latLong1.lng" #mapContainer [maxZoom]="maxZoom" [zoom]="zoom" (mapReady)="onMapReady($event)" (mapClick)="addMarker($event)" [mapTypeControl]="mapTypeControl" [streetViewControl]="false" [disableDefaultUI]="disableUI">

      <agm-marker *ngFor="let marker of markers;" [latitude]="marker.lat" [longitude]="marker.lng" [iconUrl]="$any(icon)" (markerClick)="removeMarker($event)"></agm-marker>
      <agm-polyline #polyline [visible]="true" [strokeWeight]="2" [strokeColor]="'#000000'">
        <agm-polyline-point *ngFor="let marker of markers; let i=index" [latitude]="marker.lat" [longitude]="marker.lng"></agm-polyline-point>
      </agm-polyline>
       <agm-info-window *ngFor="let window of windows;" [isOpen]="true" [latitude]="window.lat" [longitude]="window.lng" [closeWhenOthersOpen]="false">
          {{ window.distance }} m
      </agm-info-window>
    </agm-map>
      <div class="buttons editOrtho" *ngIf="role == 'aero-processing'">
        <button mat-raised-button color="primary" (click)="editOrtho()">Edit</button>
      </div>
      <div class="buttons clearAnnot" *ngIf="!onDraw">
        <button mat-raised-button color="accent" (click)="clear()">Clear</button>
      </div>
    <mat-card class="result">
      <mat-label #result>
        <span *ngIf="mode == 1"> Distance : {{ calc.value }} m </span>
        <span *ngIf="mode == 2"> Area : {{ calc.value }} m <sup>2</sup></span>
      </mat-label>
    </mat-card>

    <div *ngIf="onDraw">
      <div role="menubar" style="margin: 5px; z-index: 10; position: absolute; right: 0px; top: 0px;">
        <div style="float: left; line-height: 0;" [ngStyle]="{'border': drawingMode == 'stop' ? '1px solid #0c9fe2' : '0px' }"><button  matTooltip="Stop Draw"
          matTooltipPosition="below"
          matTooltipHideDelay="100" style="background: none padding-box rgb(255, 255, 255); display: block; border: 1px; margin: 0px; padding: 4px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; direction: ltr; overflow: hidden; text-align: left; color: rgb(0, 0, 0); font-family: Roboto, Arial, sans-serif; font-size: 11px; border-bottom-left-radius: 2px; border-top-left-radius: 2px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; font-weight: 500;" (click)="changeDrawingMode('stop')"><img style="width:25px;height:25px;" src="assets/images/icons/hand.png"/></button></div>
        <div style="float: left; line-height: 0;" [ngStyle]="{'border': drawingMode == 'polygon' ? '1px solid #0c9fe2' : '0px' }"><button  matTooltip="Draw polygon to find area"
          matTooltipPosition="below"
          matTooltipHideDelay="100" style="background: none padding-box rgb(255, 255, 255); display: block; border: 0px; margin: 0px; padding: 4px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; direction: ltr; overflow: hidden; text-align: left; color: rgb(0, 0, 0); font-family: Roboto, Arial, sans-serif; font-size: 11px; border-bottom-left-radius: 2px; border-top-left-radius: 2px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; font-weight: 500;" (click)="changeDrawingMode('polygon')"><img style="width:25px;height:25px;" src="assets/images/icons/polygon.png"/></button></div>
        <div style="float: left; line-height: 0;" [ngStyle]="{'border': drawingMode == 'polyline' ? '1px solid #0c9fe2' : '0px' }"><button  matTooltip="Draw polyline to find length"
          matTooltipPosition="below"
          matTooltipHideDelay="100" style="background: none padding-box rgb(255, 255, 255); display: block; border: 0px; margin: 0px; padding: 4px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; direction: ltr; overflow: hidden; text-align: left; color: rgb(0, 0, 0); font-family: Roboto, Arial, sans-serif; font-size: 11px; border-bottom-left-radius: 2px; border-top-left-radius: 2px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; font-weight: 500;" (click)="changeDrawingMode('polyline')"><img style="width:25px;height:25px;" src="assets/images/icons/polyline.png"/></button></div>
        <div style="float: left; line-height: 0;" [ngStyle]="{'border': drawingMode == 'line' ? '1px solid #0c9fe2' : '0px' }"><button  matTooltip="Draw line to find distance"
          matTooltipPosition="below"
          matTooltipHideDelay="100" style="background: none padding-box rgb(255, 255, 255); display: block; border: 0px; margin: 0px; padding: 4px; text-transform: none; appearance: none; position: relative; cursor: pointer; user-select: none; direction: ltr; overflow: hidden; text-align: left; color: rgb(0, 0, 0); font-family: Roboto, Arial, sans-serif; font-size: 11px; border-bottom-left-radius: 2px; border-top-left-radius: 2px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; font-weight: 500;" (click)="changeDrawingMode('line')"><img style="width:25px;height:25px;" src="assets/images/icons/line.png"/></button></div>
      </div>
    </div>
  </div>
  </div>



