<mat-chip-list aria-label="Issues selection">
  <mat-chip class="chipOne" value="All" (click)="select($event)">All</mat-chip>
  <mat-chip class="chipTwo" value="Monitor" (click)="select($event)">Normal</mat-chip>
  <mat-chip class="chipThree" value="Planned" (click)="select($event)">Planned</mat-chip>
  <mat-chip class="chipFour" value="Urgent" (click)="select($event)">Urgent</mat-chip>
  <mat-chip class="chipFive" value="Emergency" (click)="select($event)">Emergency</mat-chip>
  <ng-container *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">
    <mat-chip class="addIcon" (click)="addSection()"><mat-icon>add</mat-icon></mat-chip>
    <mat-chip class="deleteIcon" (click)="deleteSection()"><mat-icon>delete</mat-icon></mat-chip>
  </ng-container>
</mat-chip-list>
<div *ngIf="data" class="content">
  <div *ngIf="selected === 'All'">
    <div *ngIf="data.General" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf=" i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('Equipment')">
        <p>No</p>
        <p>Equipment</p>
        <p>Details</p>
      </div>
      <div *ngIf="data.Equipment.filter(leg1).length > 0" class="contentSubHeading">Leg 1</div>
      <div *ngFor="let items of data.Equipment.filter(leg1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg2).length > 0" class="contentSubHeading" >Leg 2</div>
      <div *ngFor="let items of data.Equipment.filter(leg2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg3).length > 0" class="contentSubHeading">Leg 3</div>
      <div *ngFor="let items of data.Equipment.filter(leg3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg4).length > 0" class="contentSubHeading">Leg 4</div>
      <div *ngFor="let items of data.Equipment.filter(leg4);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg5).length > 0" class="contentSubHeading">Leg 5</div>
      <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(leg6).length > 0" class="contentSubHeading">Leg 6</div>
      <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceA).length > 0" class="contentSubHeading">Face A</div>
      <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceB).length > 0" class="contentSubHeading" >Face B</div>
      <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceC).length > 0" class="contentSubHeading">Face C</div>
      <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceD).length > 0" class="contentSubHeading">Face D</div>
      <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceE).length > 0" class="contentSubHeading">Face E</div>
      <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceF).length > 0" class="contentSubHeading">Face F</div>
      <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceAB).length > 0" class="contentSubHeading">Face AB</div>
      <div *ngFor="let items of data.Equipment.filter(faceAB);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceBC).length > 0" class="contentSubHeading">Face BC</div>
      <div *ngFor="let items of data.Equipment.filter(faceBC);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceCD).length > 0" class="contentSubHeading">Face CD</div>
      <div *ngFor="let items of data.Equipment.filter(faceCD);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceCA).length > 0" class="contentSubHeading">Face CA</div>
      <div *ngFor="let items of data.Equipment.filter(faceCA);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div *ngIf="data.Equipment.filter(faceDA).length > 0" class="contentSubHeading">Face DA</div>
      <div *ngFor="let items of data.Equipment.filter(faceDA);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Monitor'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf="items.Details.filter(monitor).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>
        <div *ngIf="hasLeg(leg1,monitor)" class="contentSubHeading">Leg 1</div>
        <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
          <div *ngIf="items.Details.filter(monitor).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
            <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
              <p>{{i+1}}</p>
              <p>{{ items.Name }}</p>
              <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
              <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
              <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
              <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
              <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
            </div>
          </div>
        </div>
        <div *ngIf="hasLeg(leg2,monitor)" class="contentSubHeading">Leg 2</div>
        <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg3,monitor)" class="contentSubHeading">Leg 3</div>
      <div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
      <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg4,monitor)" class="contentSubHeading">Leg 4</div>
      <div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
      <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg5,monitor)" class="contentSubHeading">Leg 5</div>
      <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasLeg(leg6,monitor)" class="contentSubHeading">Leg 6</div>
      <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceA,monitor)" class="contentSubHeading">Face A</div>
      <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceB,monitor)" class="contentSubHeading">Face B</div>
      <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceC,monitor)" class="contentSubHeading">Face C</div>
      <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceD,monitor)" class="contentSubHeading">Face D</div>
      <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceE,monitor)" class="contentSubHeading">Face E</div>
      <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceF,monitor)" class="contentSubHeading">Face F</div>
      <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="selected === 'Planned'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf=" items.Details.filter(planned).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading"(click)="getDetails('Equipment')">
          <p>No</p>
        <p>Equipment</p>
        <p>Details</p>
      </div>
      <div *ngIf="hasLeg(leg1,planned)" class="contentSubHeading">Leg 1</div>
      <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>


      <div *ngIf="hasLeg(leg2,planned)" class="contentSubHeading">Leg 2</div>
      <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
</div>

<div *ngIf="hasLeg(leg3,planned)" class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
</div>


<div *ngIf="hasLeg(leg4,planned)" class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div *ngIf="hasLeg(leg5,planned)" class="contentSubHeading">Leg 5</div>
      <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasLeg(leg6,planned)" class="contentSubHeading">Leg 6</div>
      <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceA,planned)" class="contentSubHeading">Face A</div>
      <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceB,planned)" class="contentSubHeading">Face B</div>
      <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceC,planned)" class="contentSubHeading">Face C</div>
      <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceD,planned)" class="contentSubHeading">Face D</div>
      <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceE,planned)" class="contentSubHeading">Face E</div>
      <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>
      <div *ngIf="hasFace(faceF,planned)" class="contentSubHeading">Face F</div>
      <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
        <div *ngIf="items.Details.filter(planned).length > 0"
          [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="selected === 'Urgent'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow"  *ngIf=" items.Details.filter(urgent).length > 0 " (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>

        <div *ngIf="hasLeg(leg1,urgent)" class="contentSubHeading">Leg 1</div>
      <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
      <div *ngIf="items.Details.filter(urgent).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>


      <div *ngIf="hasLeg(leg2,urgent)" class="contentSubHeading">Leg 2</div>
      <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
      <div *ngIf="items.Details.filter(urgent).length > 0"
      [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
      <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
        <p>{{i+1}}</p>
        <p>{{ items.Name }}</p>
        <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
        <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
        <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
        <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
        <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
      </div>
    </div>
    </div>


    <div *ngIf="hasLeg(leg3,urgent)" class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
    <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
    <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
      <p>{{i+1}}</p>
      <p>{{ items.Name }}</p>
      <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
      <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
      <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
      <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
      <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
    </div>
  </div>
  </div>


  <div *ngIf="hasLeg(leg4,urgent)" class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
  [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
</div>
</div>
<div *ngIf="hasLeg(leg5,urgent)" class="contentSubHeading">Leg 5</div>
<div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasLeg(leg6,urgent)" class="contentSubHeading">Leg 6</div>
<div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceA,urgent)" class="contentSubHeading">Face A</div>
<div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceB,urgent)" class="contentSubHeading">Face B</div>
<div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceC,urgent)" class="contentSubHeading">Face C</div>
<div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceD,urgent)" class="contentSubHeading">Face D</div>
<div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceE,urgent)" class="contentSubHeading">Face E</div>
<div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
<div *ngIf="hasFace(faceF,urgent)" class="contentSubHeading">Face F</div>
<div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
  </div>
</div>
    </div>
  </div>
  <div *ngIf="selected === 'Emergency'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf=" items.Details.filter(emergency).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>


        <div *ngIf="hasLeg(leg1,emergency)" class="contentSubHeading">Leg 1</div>
<div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div *ngIf="hasLeg(leg2,emergency)" class="contentSubHeading">Leg 2</div>
<div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div *ngIf="hasLeg(leg3,emergency)" class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div *ngIf="hasLeg(leg4,emergency)" class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"  [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
        </div>
        <div *ngIf="hasLeg(leg5,emergency)" class="contentSubHeading">Leg 5</div>
        <div *ngFor="let items of data.Equipment.filter(leg5);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasLeg(leg6,emergency)" class="contentSubHeading">Leg 6</div>
        <div *ngFor="let items of data.Equipment.filter(leg6);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceA,emergency)" class="contentSubHeading">Face A</div>
        <div *ngFor="let items of data.Equipment.filter(faceA);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceB,emergency)" class="contentSubHeading">Face B</div>
        <div *ngFor="let items of data.Equipment.filter(faceB);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceC,emergency)" class="contentSubHeading">Face C</div>
        <div *ngFor="let items of data.Equipment.filter(faceC);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceD,emergency)" class="contentSubHeading">Face D</div>
        <div *ngFor="let items of data.Equipment.filter(faceD);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceE,emergency)" class="contentSubHeading">Face E</div>
        <div *ngFor="let items of data.Equipment.filter(faceE);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>
        <div *ngIf="hasFace(faceF,emergency)" class="contentSubHeading">Face F</div>
        <div *ngFor="let items of data.Equipment.filter(faceF);let i = index;">
          <div *ngIf="items.Details.filter(emergency).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
          </div>
        </div>

    </div>
  </div>
</div>

