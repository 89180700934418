<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet" />
<div class="navbar" *ngIf="!main">
  <table class="navTable">
    <tr>
      <td>
        <a [routerLink]="'/'+role+'/map'"> <img [ngStyle]="logoStyles" [src]="logourl"></a>
      </td>
      <td>
        <!-- <p class="navbarSite">Site Name : <b *ngIf="result.length">{{ result[1]['Details'][0].Value || 'N/A' }}</b></p> -->
        <p class="navbarSite">Site Name : <b>{{ towerName }}</b></p>
      </td>
      <td>
        <p class="navbarSite">Site Code : <b>{{siteCode}}</b></p>
      </td>
      <td>
        <p class="navbarSite">Site Inspected On : <b>{{siteInspected}}</b></p>
      </td>
      <td>
        <a *ngIf="role == 'pilot'" [routerLink]="'/' + role+'/'+year +'/'+towerName+'___'+siteId+'/upload'" class="navbarItems">
          <img src="assets/images/icons/upload.svg" class="icons"><span class="iconText"> Upload </span>
        </a>
        <a *ngIf="role == 'super-admin'" [routerLink]="'/'+role+'/users'" class="navbarItems">
          <img src="assets/images/icons/users.svg" class="icons"><span class="iconText"> Users </span>
        </a>
        <a *ngIf="role != 'super-admin' && role != 'pilot'" (click)="search($event)" class="navbarItems">
          <img src="assets/images/icons/search.svg" class="icons"><span class="iconText"> Search </span>
        </a>
      </td>
      <td>
        <a [routerLink]="'/' + role+'/map'" class="navbarItems">
          <img src="assets/images/icons/map.svg" class="icons"><span class="iconText"> Map View </span>
        </a>
      </td>
      <td>
        <a [routerLink]="'/'+role+'/table'" class="navbarItems">
          <img src="assets/images/icons/table.svg" class="icons"><span class="iconText"> Table View </span>
        </a>
      </td>
      <td class="borderLeft" *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/summary'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('summary') }">
          <img src="assets/images/icons/summary.svg" class="icons"><span class="iconText"> Summary </span>
        </a>
      </td>
      <!-- <td *ngIf="role != 'super-admin' && role != 'pilot'" > -->
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/orthomap'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('orthomap') }">
          <img src="assets/images/icons/orthomap.svg" class="icons"><span class="iconText"> Ortho Map </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot' && !isUS && !isHor">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/digitaltwin'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('digitaltwin') }">
          <img src="assets/images/icons/digitaltwin.svg" class="icons"><span class="iconText"> Digital Twin </span>
        </a>
      </td>
      <td *ngIf = "isAIS">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/3dview'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('3dview') }">
          <img src="assets/images/icons/3dview.svg" class="icons" ><span class="iconText"> 360<span>&#176;</span> view </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/view/towerview'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('towerview') }">
          <img src="assets/images/icons/towerview.svg" class="icons"><span class="iconText"> Spin View </span>
        </a>
      </td>
      <!-- <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/' + projectID + '/'+role+'/'+year +'/'+towerName+'___'+siteId+'/cableview'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('cableview') }">
          <img src="assets/images/icons/towerview.svg" class="icons"><span class="iconText"> 360 Cable View </span>
        </a>
      </td> -->
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/view/los'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('los') }">
          <img src="assets/images/icons/los.svg" class="icons"><span class="iconText"> Line of Sights </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/view/topdown'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('topdown') }">
          <img src="assets/images/icons/topdown.svg" class="icons"><span class="iconText"> Top to Down </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot' && !isAIS">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/audit'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('audit') }">
          <img src="assets/images/icons/audit.svg" class="icons"><span class="iconText"> Audit </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot' && isAIS">
        <div>
          <mat-form-filed class="navBarAuditDropDownFormField" [ngClass]="{'selected' : router.url.endsWith('audit') }">
            <mat-label>
              <a class="navbarItems">
                <img src="assets/images/icons/audit.svg" class="icons"><span class="iconText">Audit</span>
              </a>
            </mat-label>
            <mat-select class="navBarAuditDropDown">
              <mat-option>
                <a class="navbarDropdownItems" [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/audit'">
                  <img src="assets/images/icons/UAVaudit.svg" class="icons"><span class="iconText"> UAV </span>
                </a>
              </mat-option>
              <mat-option>
                <a class="navbarDropdownItems" [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/riggeraudit'">
                  <img src="assets/images/icons/audit.svg" class="icons"><span class="iconText"> Rigger </span>
                </a>
              </mat-option>
            </mat-select>
          </mat-form-filed>
        </div>
      </td>
      <td class="borderLeft" *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/docs'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('docs')}">
          <img src="assets/images/icons/docs.svg" class="icons"><span class="iconText"> Docs & Report </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'___'+siteId+'/gallery'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('gallery')}">
          <img src="assets/images/icons/gallery.svg" class="icons"><span class="iconText"> Gallery </span>
        </a>
      </td>
      <td>
        <a (click)="logout()" class="navbarItems">
          <img src="assets/images/icons/logout.svg" class="icons"><span class="iconText"> Logout </span>
        </a>
      </td>
    </tr>
  </table>
</div>
<div class="navbar" *ngIf="main">
  <div class="navbarLeft">
    <a [routerLink]="'/'+role+'/map'">
      <img [ngStyle]="logoStyles" [src]="logourl">
    </a>
    <p class="navbarMapSite">{{tag}}</p>
  </div>
  <div class="navbarRight">
     <ng-container *ngIf="currentRoute != 'users'">
       <div  class="search">
        <mat-form-field >
        <mat-icon matSuffix>search</mat-icon>
          <mat-label>Inspected</mat-label>
        <input matInput list="inspectTowers" (keypress)="stopEvent($event)" (change)="inspectedTower($event)">
        </mat-form-field>
       </div>
       <div  class="search" *ngIf="role == 'pilot'">
        <mat-form-field >
        <mat-icon matSuffix>search</mat-icon>
          <mat-label>Uninspected</mat-label>
        <input matInput list="uninspectedTowers" (keypress)="stopEvent($event)" (change)="uninspectedTower($event)">
        </mat-form-field>
       </div>
       <datalist  id="inspectTowers">
        <ng-container *ngIf="data.length">
          <ng-container *ngFor="let item of data[crntYear]['Towers']">
            <option *ngIf="item.Status === 'Inspected'" [value]="item.TowerName+' - '+item.SiteID"></option>
          </ng-container>
        </ng-container>
      </datalist>
      <datalist  *ngIf="role == 'pilot'" id="uninspectedTowers">
        <ng-container *ngIf="data.length">
          <ng-container *ngFor="let item of data[crntYear]['Towers']">
            <!-- <option *ngIf="item.Status === 'Uninspected'" [value]="item.Tower">item.Tower</option> -->
            <option *ngIf="item.Status === 'Uninspected'" [value]="item.TowerName+' - '+item.SiteID"></option>
          </ng-container>
        </ng-container>
      </datalist>
      <div class="specialColor">
        <span>|</span>
      </div>
    </ng-container>
    <ng-container *ngIf="isIHS">
    <ng-container *ngIf="currentRoute == 'map' || currentRoute == 'table'">
    <a [routerLink]="'/'+role+'/analysis'" class="navbarItems">
      <img src="assets/images/icons/analysis.svg" class="icons"><span class="iconText"> Analysis </span>
    </a>
    <div class="specialColor">
      <span>|</span>
    </div>
    </ng-container>
  </ng-container>
    <ng-container *ngIf="currentRoute == 'table' || currentRoute == 'analyse'">
      <a [routerLink]="'/'+role+'/map'" class="navbarItems">
        <img src="assets/images/icons/map.svg" class="icons"><span class="iconText"> Map View </span>
      </a>
      <div class="specialColor">
        <span>|</span>
      </div>
    </ng-container>
    <ng-container *ngIf="currentRoute == 'map' || currentRoute == 'analyse'">
      <a [routerLink]="'/'+role+'/table'" class="navbarItems">
        <img src="assets/images/icons/table.svg" class="icons"><span class="iconText"> Table View </span>
      </a>
      <div class="specialColor">
        <span>|</span>
      </div>
    </ng-container>
    <ng-container *ngIf="currentRoute == 'users'">
      <a [routerLink]="'/'+role+'/map'" class="navbarItems">
        <img src="assets/images/icons/map.svg" class="icons"><span class="iconText"> Map View </span>
      </a>
      <a [routerLink]="'/'+role+'/table'" class="navbarItems">
        <img src="assets/images/icons/table.svg" class="icons"><span class="iconText"> Table View </span>
      </a>
    </ng-container>
    <a (click)="logout()" class="navbarItems">
      <img src="assets/images/icons/logout.svg" class="icons"><span class="iconText"> Logout </span>
    </a>
  </div>


</div>
