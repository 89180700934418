import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService } from '../orthofilelisting/service/upload.service'
import {Location} from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ortho-processing',
  templateUrl: './ortho-processing.component.html',
  styleUrls: ['./ortho-processing.component.css']
})
export class OrthoProcessingComponent implements OnInit {

  title = 'Raster Upload';
  file: any = {}
  fileUpload: string = 'block'
  dis: string = 'hidden';
  prg: string = 'none'
  xCoodinate: any;
  yCoodinate: any;
  myResImage: any;
  processdis: string = 'none'
  xWidth: any;
  yHeight: any;
  rWidth: any;
  rHeight: any;
  radius: any = 15;
  towerName: any;
  siteID: any;
  year: any;
  prs: any;
  progress = {
    currentStep: 0,
    totalStep: 5,
    progress: 0
  }
  projectID: any;
  

  constructor(private snackBar: MatSnackBar,private router: Router,private apiService: UploadService,private http: HttpClient,private route: ActivatedRoute,private _location: Location) { 
    this.projectID = this.apiService.projectID
    this.getData()
  }

  ngOnInit(): void {
    console.log(this.route.snapshot.paramMap.get('path'))
    this.myResImage = "https://api1.phicode.co/static/"+ this.route.snapshot.paramMap.get('path')
  }

  getCordinate(event: any, img: any){
    console.log(event)  
    console.log(event.offsetX) 
    console.log(event.offsetY)
    console.log(img.clientWidth)
    console.log(img.clientHeight)
    this.xWidth = img.clientWidth
    this.yHeight = img.clientHeight
    this.xCoodinate = Math.round(event.offsetX * ( this.rWidth / this.xWidth))
    this.yCoodinate = Math.round(event.offsetY * ( this.rHeight / this.yHeight))
  }

  async OnUpload()
  {
    this.prs = 'none'
    console.log("File selected")
    this.processdis = 'block'
    const formData = new FormData();
    formData.append('imagepath',this.myResImage.split("/").at(-1))
    formData.append('xc',this.xCoodinate)
    formData.append('yc',this.yCoodinate)
    formData.append('cxc',this.xWidth)
    formData.append('cyc',this.yHeight)
    formData.append('rad',this.radius)
    formData.append('filename', this.towerName + '___' + this.siteID)
    formData.append('tiffmode', 'Admin')
    return this.http.post('https://api1.phicode.co/upload', formData, {responseType: 'json'}).subscribe((res: any) => {
      console.log(res)
      this.processdis = 'none'
      this.dis = 'visible'
      this.prs = 'block'
      this.router.navigate(['aero-processing' + '/' + this.year + '/' + this.towerName + '___' + this.siteID + '/orthomap'])
    });
  }

  OnSUpload()
  {
    this.prs = 'none'
    this.prg = 'block'  
    const formData = new FormData();
    formData.append('imagepath', this.myResImage.split("/").at(-1))
    formData.append('xc', this.xCoodinate)
    formData.append('yc', this.yCoodinate)
    formData.append('cxc', this.xWidth)
    formData.append('cyc', this.yHeight)
    formData.append('filename', this.towerName + '___' + this.siteID)
    formData.append('rad', this.radius)
    formData.append('tiffmode', 'Admin')
    formData.append('projectID', this.projectID)
    formData.append('yr', this.year)
    this.http.post('https://api1.phicode.co/tifProcessing', formData, {responseType: 'json'}).subscribe((res: any) => {
      console.log(res)
      if(res.status == 'success'){
          this.router.navigate(['aero-processing' + '/' + this.year + '/' + this.towerName + '___' + this.siteID + '/orthomap'])
      }
      else if(res.status == "error"){
       this.snackBar.open('Image Processing failed,Please Try again', 'close', {
         duration: 2000,
       });
       this.prg = 'none'
       this.prs = 'block'
      }
    })
  }

  getData(){
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.rWidth = params.rWidth
      this.rHeight = params.rHeight
    });
    this.route.params.subscribe(params => {
      console.log(params);
      let tw = params.tower.split('___');
      this.towerName = tw[0]
      this.siteID = tw[1]
      this.year = params.year;
    });
  }

  goBack(){
    this._location.back();
  }

}
