import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.css']
})
export class DocumentDialogComponent implements OnInit {

  totalPrg = {
    currentfilenum : 0,
    totalfilenum: 0,
    percentProcessed: 0,
  }
  event: any;
  towerName: any;
  stopProcessing = false;
  result: any = [];
  projectID;

  constructor(public dialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,private snackBar: MatSnackBar,private storage : AngularFireStorage) {
           this.event = this.data.event;
           this.projectID = this.data.projectID
           this.towerName = this.data.towerName
           this.getProcessedStatuss()
     }

  ngOnInit(): void {
  }

  
  async getProcessedStatuss(){
    this.totalPrg.totalfilenum = this.event.file.length
    for(let i = 0; i < this.event.file.length; i++){
      if(!this.stopProcessing)
      {
      this.totalPrg.currentfilenum = i + 1
      let name = this.event.file[i].name;
      const task = this.storage.upload(this.projectID + '/Docs/'+this.towerName+'/'+name, this.event.file[i]);
      const ref = this.storage.ref(this.projectID + '/Docs/'+this.towerName+'/'+name);
      let uploadPercent = task.percentageChanges();
      uploadPercent.subscribe((val) => {
        console.log('Percentage :' + val)
        this.totalPrg.percentProcessed = Math.ceil(val)
      })
      await task;
      console.log('Image uploaded!');
      let uploadURL = await ref.getDownloadURL().toPromise();
      this.result.push({ File : name,Link : uploadURL })
    }
  }
    this.dialogRef.close(this.result)
    }

  closeDialog(){
    this.stopProcessing = true
    this.snackBar.open("Update cancelled", "close", { duration: 2000 });
    this.dialogRef.close()
  }
}
