import { Component, OnInit, Input, ViewChild, SimpleChange, Renderer2  } from '@angular/core';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css']
})
export class MapViewComponent implements OnInit {

  @Input() payload;
  @Input() crntDir;
  @Input() index;
  @Input() view;
  @Input() loading;
  @ViewChild("circle") circle;
  @ViewChild("angleText") angleText;
  @ViewChild("altitude") altitude;
  angle;
  height;

  constructor(private renderer : Renderer2)  { }

  ngOnInit(): void {
    this.angle = this.payload[this.crntDir].Payload[this.index].angle;
    this.height = this.payload[this.crntDir].Payload[this.index].altitude;
    this.angleChange();
  }

  ngAfterViewInit (){
    this.angleChange();
  }

  ngOnChanges(changes : SimpleChange) {
    let indexChange = changes["crntDir"] || changes["index"] || changes["payload"];
    if(indexChange){
      if(indexChange.previousValue !== indexChange.currentValue){
       this.angleChange();
      }
    }
}

angleChange(){
  if(this.circle) {
    this.renderer.setAttribute(this.circle.nativeElement,'style','transform:rotate('+(parseFloat(this.payload[this.crntDir].Payload[this.index].angle)+30)+'deg)')
 this.angle = this.payload[this.crntDir].Payload[this.index].angle;
  }
if(this.altitude){
this.height = this.payload[this.crntDir].Payload[this.index].altitude;
}
}

}
