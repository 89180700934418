import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  hide = true;
  projectID: any;
  constructor(private service : AuthService,private route: ActivatedRoute) { 
   this.projectID = this.service.projectID
  }

  ngOnInit(): void {
    console.log('sign-in called')
  }

  signin(email,password){
    this.service.SignIn(email,password,this.projectID);
  }
 
}
