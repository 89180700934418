<h1 mat-dialog-title>Search</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Inspected Towers</mat-label>
    <input  matInput type="search" list="towers" [(ngModel)]="value">
    <datalist id="towers">
            <option  *ngFor="let item of towersList.Towers" [value]="item.TowerName+' - '+item.SiteID"></option>
    </datalist>
    </mat-form-field>
 </div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="searchFn()" cdkFocusInitial class="submit">Ok</button>
</div>
