import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.css']
})
export class GalleryImageComponent implements OnInit {
  isLoaded = false;
  @Input() imageData = [];
  @Input() crntDir = 0;
  @Input() index = 0;
  @Input()  role;
  @Input() tower;
  @Input() view;
  @ViewChild("image") image;
  @ViewChild("container") container;
  @ViewChild("imageContainer") imageContainer;
  @ViewChild("range") range;

  sltDir;
  count = 0;
  dragX = 0;
  dragY = 0;
  panX = 0;
  panY = 0;
  crntPanX = 0;
  crntPanY = 0;
  zoomX = 0;
  zoomY = 0;
  size = { w: 1070, h: 720 }
  pos = { x: 0, y: 0 }
  zoom_target = { x: 0, y: 0 }
  zoom_point = { x: 0, y: 0 }
  scale = 1
  deleteIndex;
  mode = null;
  @Output() imageViewEvent = new EventEmitter<any>();

  @Output() imageSliderEvent = new EventEmitter<any>();

  @Output() updateDirEvent = new EventEmitter<any>();

  @Output() deleteEvent = new EventEmitter<any>()

  @Output() editDirEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  imageLoad(event){
    this.isLoaded = true;
  }

  ngAfterViewInit() {
    setTimeout(()=>{
    },3000)
  }

  ngOnChanges(){
    if(this.image){
      this.size.w = this.image.nativeElement.clientWidth;
      this.size.h = this.image.nativeElement.clientHeight;

    }
  }

  crntDrty(value) {
    this.isLoaded = false;
    this.crntDir = value;
    this.index = 0;
    this.imageViewEvent.emit({ crntDir: this.crntDir, index: this.index });
  }

  dragImage(event) {
    // if (event.shiftKey === false) {
    //   this.count = this.count + 1;
    //   if (this.count % 5 !== 0) {   // 15 or 25
    //     return;
    //   }
    //   if (this.view != "topdown") {
    //     if (event.pageX > this.dragX && Math.abs(event.pageX - this.dragX) > Math.abs(event.pageY - this.dragY)) {  //down
    //       this.rightIncrement();
    //     }
    //     else if (event.pageX < this.dragX && Math.abs(event.pageX - this.dragX) > Math.abs(event.pageY - this.dragY)) { //top
    //       this.leftIncrement();
    //     }
    //   } else {
    //     if (event.pageY > this.dragY && Math.abs(event.pageY - this.dragY) > Math.abs(event.pageX - this.dragX)) {  //right
    //       this.rightIncrement();
    //     }
    //     else if (event.pageY < this.dragY && Math.abs(event.pageY - this.dragY) > Math.abs(event.pageX - this.dragX)) { //left
    //       this.leftIncrement();
    //     }
    //   }
    //   this.dragX = event.pageX;
    //   this.dragY = event.pageY;
    // }
    // if (event.shiftKey === true) {
      //panning
      let imgElt = this.image.nativeElement;
      if ((event.clientX - this.panX) >= 10) {
        this.crntPanX = this.crntPanX + 10;
        imgElt.style.marginLeft = this.crntPanX + "px";
        this.panX = event.clientX;
        // console.log('R', this.actual_left);

      }
      else if ((event.clientX - this.panX) <= -10) {
        this.crntPanX = this.crntPanX - 10;
        imgElt.style.marginLeft = this.crntPanX + "px";
        this.panX = event.clientX;
        // console.log('L', this.actual_left);
      }
      else if ((event.clientY - this.panY) >= 10) {
        this.crntPanY = this.crntPanY + 10;
        imgElt.style.marginTop = this.crntPanY + "px";
        this.panY = event.clientY;
        // console.log('U', this.actual_up);

      }
      else if ((event.clientY - this.panY) <= -10) {
        this.crntPanY = this.crntPanY - 10;
        imgElt.style.marginTop = this.crntPanY + "px";
        this.panY = event.clientY;
        // console.log('D', this.actual_up);
      }
 //   }
  }




  leftIncrement() {
    this.isLoaded = false;
    if (this.index == 0) {
      this.setIndex(this.imageData[this.crntDir].Payload.length - 1,false);//Payload Array Length
      //
    } else {
      this.setIndex(this.index - 1,false);
    }
  }

  rightIncrement() {
    this.isLoaded = false;
    if (this.index == this.imageData[this.crntDir].Payload.length - 1) {
      this.setIndex(0,false);
    } else {
      this.setIndex(this.index + 1,false);
    }
  }

  endDrag(event) {
    let imgElt = this.image.nativeElement;
    if(this.pos.x + this.size.w * this.scale === this.size.w && this.pos.y + this.size.h * this.scale === this.size.h){
      this.crntPanX = 0;
      this.crntPanY = 0;
      imgElt.style.marginLeft = this.crntPanX + "px";
      imgElt.style.marginTop = this.crntPanY + "px";
    }
    this.imageSliderEvent.emit({ index: this.index, sliderIndex: true });
  }


  wheelZoom(event) {
    var offset = this.container.nativeElement.getBoundingClientRect();
    this.zoom_point.x = event.pageX - offset.left
    this.zoom_point.y = event.pageY - offset.top

    event.preventDefault();
    var delta = event.wheelDelta;
    if (delta === undefined) {
      //we are on firefox
      delta = event.detail;
    }
    delta = Math.max(-1, Math.min(1, delta)) // cap the delta to [-1,1] for cross browser consistency
    // determine the point on where the slide is zoomed in
    this.zoom_target.x = (this.zoom_point.x - this.pos.x) / this.scale
    this.zoom_target.y = (this.zoom_point.y - this.pos.y) / this.scale

    // apply zoom
    this.scale += delta * 0.2 * this.scale
    this.scale = Math.max(1, Math.min(4, this.scale))

    // calculate x and y based on zoom
    this.pos.x = -this.zoom_target.x * this.scale + this.zoom_point.x
    this.pos.y = -this.zoom_target.y * this.scale + this.zoom_point.y


    // Make sure the slide stays in its container area when zooming out
    if (this.pos.x > 0){
      this.pos.x = 0;
    }
    if (this.pos.x + this.size.w * this.scale < this.size.w){
      this.pos.x = -this.size.w * (this.scale - 1);
    }
    if (this.pos.y > 0){
      this.pos.y = 0;
    }
    if (this.pos.y + this.size.h * this.scale < this.size.h){
      this.pos.y = -this.size.h * (this.scale - 1);
    }

    this.update(event)

  }

  setIndex(event,check) {
    this.index = parseInt(event);
    if(check){
      this.imageSliderEvent.emit({ index: this.index, sliderIndex: check });
    }
  }

  changeImage(event){
    this.index = parseInt(event);
      this.imageViewEvent.emit({ crntDir: this.crntDir, index: this.index });
  }

  update(event) {
    if(this.image){
      this.zoomX = this.pos.x + this.size.w * (this.scale - 1) / 2;
      this.zoomY = this.pos.y + this.size.h * (this.scale - 1) / 2;
      this.image.nativeElement.setAttribute("style", 'transform : translate(' + this.zoomX + 'px,' + this.zoomY + 'px) scale(' + this.scale + ',' + this.scale + ');')
    }
    }


  addDir(event){
this.updateDirEvent.emit();
  }

  deleteItems(event,name,index){
    if(event.target.checked){
      this.deleteIndex.push({name,index});
    }else{
    this.deleteIndex = this.deleteIndex.filter(elt =>{
      return (elt.name != name && elt.index != index);//
    });
    }
  }


  deleteSection(){
    if(this.mode == 'Delete'){
      if(this.deleteIndex.length > 0){
     this.deleteEvent.emit(this.deleteIndex);
      }
      this.deleteIndex = [];
    this.mode = null;
  }else{
    this.mode = 'Delete';
    this.deleteIndex = [];
  }
  }


  editDir(event,index,value){
    this.editDirEvent.emit({index,value});
      }

}
